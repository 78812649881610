<template>
  <qtm-dialog-card
    title="Resolve Problem"
    :model-value="true"
    width="600px"
    @updaet:model-value="$emit('update:model-value', $event)"
  >
    <v-row>
      <v-col class="text-mid-grey">
        {{ problem.message }}
      </v-col>
    </v-row>

    <div v-if="problem.problem_type === 'external'">
      <v-row>
        <v-col class="text-mid-grey">
          Please make this change in <span class="text-capitalize">{{ integrationType }}</span>
          then click Resolve.
        </v-col>
      </v-row>
    </div>

    <div v-if="hasResolutionChoices">
      <v-row>
        <v-col class="text-mid-grey">
          Please select a <span class="text-capitalize">{{ integrationType }}</span>
          {{ subjectType }} below and click Resolve to correct the mapping.
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <qtm-text-field
            v-model="search"
            class="mb-2"
            clearable
            hide-details
            :loading="loadingChoices"
            placeholder=""
            prepend-inner-icon="mdi-magnify"
          />

          <div
            v-if="!resolutionChoices.length"
            class="text-center"
          >
            No results
          </div>
          <qtm-radio-group
            v-else
            v-model="selection"
            class="pt-0 white"
            hide-details
            item-label="name"
            item-value="id"
            :items="resolutionChoices"
          >
            <qtm-radio
              v-for="choice in resolutionChoices"
              :key="choice.id"
              class="qtm-border pa-3 stacked-radio"
              :value="choice.id"
            >
              <template v-slot:label>
                <div class="text-secondary">
                  <div class="font-weight-bold" v-text="choice.name" />
                  <div v-if="choice.address" v-text="choice.address" />
                </div>
              </template>
            </qtm-radio>
          </qtm-radio-group>
          <qtm-pagination
            v-model="page"
            class="mt-4"
            :count="totalChoices"
            :per-page="pageSize"
          />
        </v-col>
      </v-row>
    </div>

    <template v-slot:actions>
      <v-spacer />
      <qtm-btn tertiary size="x-large" @click="$emit('update:model-value', false)">
        Cancel
      </qtm-btn>
      <qtm-btn
        primary
        size="large"
        :loading="loading"
        :disabled="hasResolutionChoices && !selection"
        @click="resolve"
      >
        Resolve
      </qtm-btn>
    </template>
  </qtm-dialog-card>
</template>

<script setup lang="ts">
import { useStore } from 'vuex'
import debounce from 'lodash.debounce'

const props = defineProps<{ problem: any }>()
const emit = defineEmits(['resolved', 'update:model-value'])

const loading = ref(false)
const loadingChoices = ref(false)
const resolutionChoices = ref<any[]>([])
const totalChoices = ref(0)
const pageSize = ref(10)
const page = ref(1)
const selection = ref(undefined)
const search = ref('')

const store = useStore()
const user = store.state.auth.user
const integrationType = user.company.accounting_integration?.type

const subjectType = computed(() => {
  switch (props.problem.subject_type) {
    case 'jobsite':
      return 'project'
    case 'vendor':
      return 'supplier'
    default:
      return props.problem.subject_type
  }
})

const isExternalProblem = computed(() => !props.problem || props.problem.problem_type === 'external')
const hasResolutionChoices = ref(!isExternalProblem.value)

let firstFetch = true
const { $api, $error } = useNuxtApp()
const fetchResolutionChoices = debounce(async () => {
  loadingChoices.value = true
  try {
    const response = await $api.v1.accountingConfigProblems.listResources(props.problem.id, {
      search: search.value,
      pagination: {
        limit: pageSize.value,
        offset: (page.value - 1) * pageSize.value,
      },
    } as any)
    resolutionChoices.value = response.data

    if (firstFetch) {
      firstFetch = false
      hasResolutionChoices.value = resolutionChoices.value.length > 0
    }

    totalChoices.value = response.count
  }
  catch (error) {
    $error.report(error)
  }
  loadingChoices.value = false
}, 350)

watch(page, () => {
  selection.value = undefined
  fetchResolutionChoices()
})

watch(search, () => {
  page.value = 1
  selection.value = undefined
  fetchResolutionChoices()
})

onMounted(() => {
  if (!isExternalProblem.value) {
    fetchResolutionChoices()
  }
})

const resolve = async () => {
  loading.value = true
  try {
    let data
    if (selection.value) {
      data = { accounting_id: selection.value }
    }

    await $api.v1.accountingConfigProblems.resolve(props.problem.id, data)
    emit('update:model-value', false)
    emit('resolved', props.problem)
  }
  catch (error) {
    $error.report(error)
  }
  loading.value = false
}
</script>

<style lang="scss" scoped>
.stacked-radio {
  margin-bottom: -1px !important;
}
</style>
