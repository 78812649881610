<template>
  <div>
    <v-table class="vendor-table">
      <thead>
        <th>VENDOR</th>
        <th>CITY</th>
        <th>CONTACT</th>
        <th>PHONE</th>
        <th>RESPONDED</th>
        <th>PRICE</th>
        <th />
      </thead>
      <tbody>
        <tr v-for="quote in order.quotes" :key="quote.id">
          <td class="font-weight-bold">
            {{ quote.vendor.name }}
          </td>
          <td>{{ quote.vendor.city }}</td>
          <td>
            {{ salespersonName(quote.salesperson) }}
            <br>
            <span class="vendor-contact-email">
              {{ salespersonEmail(quote.salesperson) }}
            </span>
          </td>
          <td>{{ salespersonPhoneNumber(quote.salesperson) }}</td>
          <td class="text-center">
            <quote-status-icon :quote="quote" />
          </td>
          <td>{{ currency(quote.total_price) }}</td>
          <td>
            <qtm-btn tertiary @click="editQuote(quote)">
              Edit
            </qtm-btn>
          </td>
        </tr>
      </tbody>
    </v-table>
    <enter-quote-dialog
      v-model="showQuote"
      :quote="activeQuote"
      :order="order"
      @update:model-value="activeQuote = undefined"
    />
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep'
import { currency } from '~/models/filters'
import EnterQuoteDialog from '@/components/quotes/enter-quote-dialog.vue'
import QuoteStatusIcon from '@/components/admin/quote-status-icon.vue'

export default {
  name: 'enter-vendor-quotes-table',
  components: { EnterQuoteDialog, QuoteStatusIcon },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      activeQuote: undefined,
      openPanels: [0],
      showQuote: false,
    }
  },
  methods: {
    currency(value) {
      return currency(value)
    },
    editQuote(quote) {
      // This object is cached in the user's browser, and won't be re-fetched from the backend unless they reload.
      // Clone so the user can cancel and throw away any changes without having to refresh the page or object.
      this.activeQuote = cloneDeep(quote)

      // Modifying the attachments is a separate event from modifying the quote and cannot be reversed.
      // Setting them here reflects that in the cached data.
      this.activeQuote.attachments = quote.attachments
      this.showQuote = true
    },

    salespersonEmail(salesperson) {
      return salesperson ? salesperson.email : ''
    },

    salespersonName(salesperson) {
      return salesperson ? `${salesperson.first_name} ${salesperson.last_name}` : '—'
    },

    salespersonPhoneNumber(salesperson) {
      const phone = salesperson?.phone_number || ''

      if (salesperson?.phone_extension) {
        return `${phone} ext. ${salesperson.phone_extension}`
      }

      return phone
    }
  }
}
</script>

<style lang="scss" scoped>
.vendor-table thead {
  background-color: rgb(var(--v-theme-background));
  text-align: left;
}

.vendor-table th {
  padding: 0.25rem 16px;
  font-weight: normal;
}

.vendor-contact-email {
  color: rgba(83, 83, 83, 0.6);
}
</style>
