<template>
  <order-summary-card
    v-bind="$attrs"
    :contact="contact"
    :delivery-price="po.latest_change_order.delivery_charge"
    force-total-spacer
    :freight-cost-code="po.latest_change_order.freight_cost_code"
    :freight-tax="po.latest_change_order.freight_tax"
    :items="po.poskus"
    :ordered-by="order.owner"
    :pickup-price="po.latest_change_order.pickup_charge"
    :project="order.jobsite"
    readonly
    :reference-name="order.reference_name"
    :summary-items="summaryItems"
    :supplier="supplier"
    :title="`PO ${po.po_number}`"
    :total="po.total_price"
    :total-tax="po.total_tax"
    :with-received="partiallyReceived"
  >
    <template v-slot:title-append>
      <status v-if="order.date_cancelled" class="ml-3" error error-text="Cancelled" />
      <recevied-status
        v-else
        class="ml-3"
        :partially-received="partiallyReceived"
        :received-in-full="receivedInFull"
      />
    </template>
    <template v-slot:summary-item-fulfillment-date>
      <span class="text-dark-grey">
        <span v-if="po.delivery_date" :class="{ 'text-primary': !receivedInFull }">
          <v-icon v-if="!receivedInFull" class="mt-n1" color="primary" size="20">
            mdi-alert-circle-outline
          </v-icon>
          {{ deliveryDate }}
        </span>
        <span v-else>
          No Date Set
        </span>
      </span>
    </template>
    <template v-slot:summary-costs-append="{ borderClass, padSpan, showSubtotal, spacerClass }">
      <tbody v-if="showSubtotal">
        <tr v-if="showSubtotal" :class="spacerClass">
          <td colspan="5" />
        </tr>
      </tbody>
      <tbody :class="showSubtotal ? borderClass : ''">
        <tr>
          <td :colspan="padSpan" />
          <td />
          <td class="text-mid-grey">
            Total <span v-if="!po.total_tax">(excl. tax)</span>
          </td>
          <td>
            {{ currency(total) }}
          </td>
        </tr>
        <tr v-for="invoice in sortedInvoices" :key="invoice.id">
          <td :colspan="padSpan" />
          <td class="py-0">
            <invoice-status v-if="!invoice.date_approved" class="mr-2" :invoice="invoice" />
          </td>
          <td class="text-mid-grey">
            <nuxt-link
              class="text-decoration-none white-space-nowrap"
              :to="{ ...$route, query: { invoice: invoice.id } }"
            >
              Invoice {{ invoice.invoice_number }}
            </nuxt-link>
          </td>
          <td v-if="invoice.date_cancelled">
            —
          </td>
          <td v-else>
            {{ currency(-(invoice.total_price + (invoice.total_tax || 0))) }}
          </td>
        </tr>
      </tbody>
    </template>
    <template v-slot:total-cells>
      <td>
        Balance
      </td>
      <td>
        {{ currency(balance) }}
      </td>
    </template>
  </order-summary-card>
</template>

<script>
import { currency, dateYear } from '~/models/filters'
import InvoiceStatus from '@/components/invoices/invoice-status.vue'
import OrderSummaryCard from '@/components/orders/order-summary-card.vue'
import ReceviedStatus from '@/components/purchase-orders/received-status.vue'
import Status from '@/components/status.vue'

export default {
  name: 'purchase-order-card',
  components: { InvoiceStatus, OrderSummaryCard, ReceviedStatus, Status },
  props: {
    invoices: {
      type: Array,
      default: () => [],
    },
    order: {
      type: Object,
      required: true,
    },
    po: {
      type: Object,
      required: true,
    },
  },
  computed: {
    balance() {
      return this.total - this.invoices
        .filter(invoice => !invoice.date_cancelled)
        .reduce((sum, invoice) => sum + invoice.total_price + (invoice.total_tax || 0), 0)
    },
    contact() {
      const fields = ['email', 'first_name', 'last_name', 'phone', 'phopne_cell', 'phone_extension']
      const contact = {
        id: this.po.supplier_contact,
      }

      fields.forEach(field => {
        contact[field] = this.po[`supplier_contact_${field}`]
      })

      return contact
    },
    deliveryDate() {
      return dateYear(this.po.delivery_date)
    },
    partiallyReceived() {
      return !!this.po?.partiallyReceived
    },
    receivedInFull() {
      return this.po.poskus.every(item => Number(item.received_quantity) === Number(item.quantity))
    },
    sortedInvoices() {
      // Copy array to avoid mutating prop
      const invoices = [...this.invoices]

      invoices.sort((a, b) => a.date_created - b.date_created)
      return invoices
    },
    summaryItems() {
      return [
        {
          label: 'Order Date',
          value: dateYear(this.po.date_ordered),
        },
        {
          label: 'Fulfillment Date',
          value: dateYear(this.po.delivery_date),
        },
        {
          label: 'Payment Terms',
          value: this.po.payment_terms,
        },
      ]
    },
    supplier() {
      const fields = ['address', 'city', 'country', 'logo', 'name', 'postal_code', 'province']
      const supplier = {
        id: this.po.supplier,
      }

      fields.forEach(field => {
        supplier[field] = this.po[`supplier_${field}`]
      })

      return supplier
    },
    total() {
      return this.po.total_price + (this.po.total_tax || 0)
    },
  },
  methods: {
    currency(value) {
      return currency(value)
    },
  }
}
</script>
