<template>
  <div class="overflow-x-auto">
    <table class="purchase-order-items-table text-center">
      <thead>
        <tr class="text-secondary text-no-wrap">
          <th v-for="(header, i) in headers" :key="i" class="py-3">
            {{ header }}
          </th>
        </tr>
      </thead>
      <tbody>
        <purchase-order-item-row
          v-for="item in sortedItems"
          :key="item.id"
          :boms="boms"
          class="alternating-rows"
          :item="item"
          :with-received="withReceived"
        />
        <tr class="alternating-rows">
          <td />
          <td class="text-left">
            Delivery Charge
          </td>
          <td v-for="i in freightPadding" :key="i">
            -
          </td>
          <td class="qtm-currency text-no-wrap">
            {{ decimal(deliveryCharge) }}
          </td>
          <td class="qtm-currency text-no-wrap">
            {{ decimal(deliveryCharge) }}
          </td>
          <td v-text="freightCostCode" />
        </tr>
        <tr class="alternating-rows">
          <td />
          <td class="text-left">
            Pickup Charge
          </td>
          <td v-for="i in freightPadding" :key="i">
            -
          </td>
          <td class="qtm-currency text-no-wrap">
            {{ decimal(pickupCharge) }}
          </td>
          <td class="qtm-currency text-no-wrap">
            {{ decimal(pickupCharge) }}
          </td>
          <td v-text="freightCostCode" />
        </tr>
        <tr>
          <th class="py-3 text-right" :colspan="headers.length">
            <div class="d-flex justify-end">
              <table>
                <tr>
                  <td>
                    TOTAL (excl. tax)
                  </td>
                  <td class="qtm-currency">
                    {{ decimal(totalPrice, 2) }}
                  </td>
                </tr>
                <tr v-if="tax" class="qtm-body">
                  <td class="text-left">
                    Tax
                  </td>
                  <td class="qtm-currency">
                    {{ decimal(tax) }}
                  </td>
                </tr>
              </table>
            </div>
          </th>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { decimal } from '~/models/filters'
import PurchaseOrderItemRow from '@/components/purchase-orders/purchase-order-item-row.vue'

export default {
  name: 'purchase-order-items-table',
  components: { PurchaseOrderItemRow, },
  props: {
    boms: {
      type: Array,
      default: () => []
    },
    deliveryCharge: {
      type: [Number, String],
      default: 0
    },
    freightCostCode: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      required: true
    },
    pickupCharge: {
      type: [Number, String],
      default: 0
    },
    totalPrice: {
      type: Number,
      default: 0
    },
    tax: {
      type: Number,
      default: 0
    },
    withReceived: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    headers() {
      const headers = [
        'L#',
        'DESCRIPTION',
        'DURATION',
        'ORDERED',
      ]

      if (this.withReceived) {
        headers.push('RECEIVED')
      }

      return headers.concat([
        'UNIT PRICE',
        'SUBTOTAL',
        'COST CODE',
      ])
    },
    freightPadding() {
      // There are 5 cells used in the freight rows
      return this.headers.length - 5
    },
    sortedItems() {
      const items = this.items

      items.sort((a, b) => a.item_number - b.item_number)
      return items
    },
  },
  methods: {
    decimal(value, decimalPlaces = undefined) {
      return decimal(value, { maximumFractionDigits: decimalPlaces })
    }
  }
}
</script>

<style lang="scss">
$border: 1px solid rgb(var(--v-theme-light-grey));

.purchase-order-items-table {
  border-collapse: collapse;
  width: 100%;
}

.purchase-order-items-table td,
.purchase-order-items-table th {
  border: $border;
  padding: 0.25rem 0.5rem;
  vertical-align: top;
}

.purchase-order-items-table th {
  background-color: rgb(var(--v-theme-light-grey));
}

.purchase-order-items-table th:nth-child(2) {
  text-align: left;
}

.purchase-order-items-table .alternating-rows:nth-child(even) {
  background-color: rgb(var(--v-theme-background));
}
</style>
