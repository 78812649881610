<template>
  <qtm-content-block :title="title">
    <v-row align="end" no-gutters>
      <v-col cols="12" sm="8" lg="6">
        <qtm-input-label label="Quote Status">
          <qtm-select v-bind="$attrs" hide-details item-value="value" :items="RESPONSE_OPTIONS" />
        </qtm-input-label>
      </v-col>
      <v-col cols="12" sm="8" lg="6">
        <slot />
      </v-col>
    </v-row>
  </qtm-content-block>
</template>

<script setup lang="ts">
export interface Props {
  align?: string
  title?: string
}

withDefaults(defineProps<Props>(), {
  align: undefined,
  title: 'Quote Status',
})

const RESPONSE_OPTIONS = [
  { value: 'awaiting price', text: 'Awaiting Price' },
  { value: 'declined', text: 'Declined' },
  { value: 'price given', text: 'Price Given' },
  { value: 'qa', text: 'QA Required' },
]
</script>
