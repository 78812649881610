<template>
  <qtm-table :headers="headers">
    <draggable
      :list="items"
      animation="150"
      class="qtm-body"
      ghost-class="ghost"
      handle=".handle"
      item-key="id"
      tag="tbody"
      @start="onDragStart"
      @end="onDragEnd"
      @change="$emit('sort:items', $event)"
    >
      <template v-slot:item="{ element: item }">
        <cart-items-row
          ref="cartItemsRow"
          :cost-codes="costCodes"
          :force-cost-code-select="forceCostCodeSelect"
          :include-units-column-in-rentals="includeUnitsColumnInRentals"
          :item="item"
          :item-number-field="itemNumberField"
          :no-drag-and-drop="noDragAndDrop || items.length === 1"
          :rental="rental"
          :require-cost-codes="requireCostCodes"
          :taxes="taxes"
          :units="units"
          :validate-units="validateUnits"
          :with-cost-code="withCostCodes"
          :with-prices="withPrices"
          @cost-code-changed="$emit('cost-code-changed', $event)"
          @tax-changed="$emit('tax-changed', $event)"
          @remove="$emit('remove', $event)"
        />
      </template>
    </draggable>
    <template v-slot:empty>
      <div>
        <!-- Remove empty state -->
      </div>
    </template>
  </qtm-table>
</template>

<script>
import draggable from 'vuedraggable'
import CartItemsRow from '@/components/orders/cart-items-row.vue'
import QtmTable from '@/components/qtm-table.vue'

export default {
  name: 'cart-items-table',
  components: { CartItemsRow, draggable, QtmTable },
  props: {
    costCodes: {
      type: Array,
      default: () => []
    },
    forceCostCodeSelect: {
      type: Boolean,
      default: false
    },
    itemNumberField: {
      type: String,
      default: 'index'
    },
    items: {
      type: Array,
      required: true,
    },
    noDragAndDrop: {
      type: Boolean,
      default: false
    },
    rental: {
      type: Boolean,
      default: false,
    },
    requireCostCodes: {
      type: Boolean,
      default: false
    },
    taxes: {
      type: Array,
      default: () => []
    },
    units: {
      type: Array,
      default: () => []
    },
    validateUnits: {
      type: Boolean,
      default: false
    },
    withCostCodes: {
      type: Boolean,
      default: false
    },
    withPrices: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['cost-code-changed', 'remove', 'sort:items', 'tax-changed'],
  computed: {
    headers() {
      let headers = [
        { title: '#' },
        { title: this.rental ? 'Rental items' : 'Purchase items' },
        { title: 'Qty' },
        this.rental ? { title: 'Duration', colspan: 2 } : { title: 'U/M' },
      ]

      if (this.rental && this.includeUnitsColumnInRentals) {
        headers.push({ title: 'U/M' })
      }

      if (this.withPrices) {
        if (this.rental) {
          headers = headers.concat(['Day rate', 'Week rate', 'Month rate'].map(title => ({ title })))
        }
        else {
          headers.push({ title: 'Unit price' })
        }

        headers.push({ title: 'Total' })
      }

      if (this.withCostCodes) {
        headers.push({ title: 'Cost code' })
      }

      if (this.taxes.length) {
        headers.push({ title: 'Tax' })
      }

      headers.push({ title: 'Comment' })
      headers.push({ title: 'Actions' })

      return headers.map(header => ({ ...header, value: header.title }))
    },
    includeUnitsColumnInRentals() {
      const hasItemsWithNoUnits = this.items.filter(item => !item.unit).length > 0
      return this.rental && this.validateUnits && this.units.length && hasItemsWithNoUnits
    },
  },
  methods: {
    focusLastItem() {
      this.$refs.cartItemsRow.focusDescription()
    },
    isValid() {
      // An empty cart is considered valid
      return !this.$refs.cartItemsRow
        || !this.$refs.cartItemsRow.length
        || this.$refs.cartItemsRow.every(row => row.isValid())
    },
    onDragStart(event) {
      const parent = event.item.closest('.overflow-x-auto')
      parent?.classList.remove('overflow-x-auto')
      parent?.classList.add('overflow-x-hidden')
      parent?.classList.add('no-handle')
    },
    onDragEnd(event) {
      const parent = event.item.closest('.overflow-x-hidden')
      parent?.classList.add('overflow-x-auto')
      parent?.classList.remove('overflow-x-hidden')
      parent?.classList.remove('no-handle')
    }
  }
}
</script>
