<template>
  <qtm-content-block collapsible title="Supplier">
    <v-row no-gutters>
      <v-col cols="12" sm="8" lg="6">
        <qtm-input-label class="mb-4" label="Supplier Name" required>
          <supplier-search
            :append-items="appendVendors"
            clearable
            :country="country"
            :error-messages="vendorError"
            menu-icon=""
            :model-value="supplier"
            :user="user"
            @update:model-value="selectVendor"
          >
            <template v-slot:append-item="{ search }">
              <v-list-item v-if="search" class="qtm-label text-interactive" @click="createVendor = true">
                <v-icon class="mt-n1 mr-2">
                  mdi-plus
                </v-icon>
                Add New
              </v-list-item>
            </template>
          </supplier-search>
        </qtm-input-label>
      </v-col>
      <v-col v-if="supplier" cols="12" sm="4" lg="6">
        <qtm-input-label class="mb-4 mb-sm-0 ml-sm-4" label="Address">
          <div v-text="supplier.address" />
          <div>
            <qtm-address :city="supplier.city" :postal-code="supplier.postal_code" :province="supplier.province" />
          </div>
        </qtm-input-label>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12" sm="8" lg="6">
        <qtm-input-label label="Supplier Contact" required>
          <purchase-order-contact-select
            :allow-internal="allowInternal"
            :contact="contact"
            :contacts="allContacts"
            :error-messages="contactError"
            :internal-distribution="internalDistribution"
            :loading="loading"
            @update:contact="$emit('update:contact', $event)"
            @update:internal-distribution="$emit('update:internal-distribution', $event)"
          >
            <template v-slot:append-item>
              <v-list-item class="qtm-label text-interactive" @click="createContact = true">
                <v-icon class="mt-n1 mr-2">
                  mdi-plus
                </v-icon>
                Add New
              </v-list-item>
            </template>
          </purchase-order-contact-select>
        </qtm-input-label>
      </v-col>
      <v-col cols="12" sm="4" lg="6">
        <slot />
      </v-col>
    </v-row>
    <create-vendor-dialog
      v-model="createVendor"
      :country="country"
      vendor-details-required
      @vendor-created="vendorCreated"
    />
    <create-contact-dialog v-model="createContact" email-required emit-only @new-contact="addContact" />
  </qtm-content-block>
</template>

<script>
import { required, requiredIf } from '@vuelidate/validators'
import useValidation from '@/composables/validation'
import CreateContactDialog from '@/components/vendors/create-contact-dialog.vue'
import CreateVendorDialog from '@/components/vendors/create-vendor-dialog.vue'
import PurchaseOrderContactSelect from '@/components/purchase-orders/purchase-order-contact-select.vue'
import SupplierSearch from '@/components/search/supplier-search.vue'

export default {
  name: 'order-supplier',
  components: {
    CreateContactDialog,
    CreateVendorDialog,
    PurchaseOrderContactSelect,
    SupplierSearch,
  },
  props: {
    allowInternal: {
      type: Boolean,
      default: false,
    },
    contact: {
      type: Object,
      default: undefined,
    },
    country: {
      type: String,
      default: undefined
    },
    internalDistribution: {
      type: Boolean,
      default: false,
    },
    supplier: {
      type: Object,
      default: undefined,
    },
    user: {
      type: Number,
      default: undefined
    },
  },
  emits: ['supplier-created', 'update:contact', 'update:internal-distribution', 'update:supplier'],
  setup() {
    const { errors, v$ } = useValidation()

    return { errors, v$ }
  },
  validations: {
    contact: {
      required: requiredIf(function () {
        return !this.internalDistribution
      }),
    },
    supplier: { required },
  },
  data() {
    return {
      contacts: [],
      createContact: false,
      createVendor: false,
      appendVendors: [],
      loading: false,
      newContacts: [],
    }
  },
  computed: {
    allContacts() {
      return this.contacts.concat(this.newContacts)
    },
    contactError() {
      return typeof this.errors.contact === 'string' ? this.errors.contact : undefined
    },
    vendorError() {
      if (!this.supplier && this.v$.supplier.$dirty && this.v$.supplier.$invalid) {
        return 'A supplier is required'
      }

      return undefined
    },
  },
  mounted() {
    if (this.supplier) {
      this.appendVendors = [{ ...this.supplier }]
      this.fetchContacts()
    }
  },
  methods: {
    addContact(contact) {
      this.newContacts.push(contact)
      this.$emit('update:contact', contact)
    },
    clearVendor() {
      this.$emit('update:supplier', null)
      this.contacts = []
      this.$emit('update:contact', null)
    },
    async fetchContacts() {
      this.loading = true
      try {
        const vendor = await this.$api.v1.vendors.get(this.supplier.id)

        this.contacts = vendor.contacts.filter(contact => contact.email)
      }
      catch (error) {
        this.$error.report(error)
      }
      this.loading = false
    },
    isValid() {
      this.v$.$touch()
      return !this.v$.$invalid
    },
    selectVendor(vendor) {
      if (!vendor) {
        this.clearVendor()
        return
      }

      this.$emit('update:supplier', vendor)
      this.contacts = vendor.contacts || []

      if (!this.contacts.some(contact => contact.id === this.contact?.id)) {
        this.$emit('update:contact', null)

        this.$nextTick(() => this.fetchContacts())
      }
    },
    vendorCreated(vendor) {
      this.appendVendors.push(vendor)
      this.selectVendor(vendor)
      this.$emit('supplier-created', vendor)
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
