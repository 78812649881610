<template>
  <tbody class="attachments-row">
    <tr>
      <td class="pt-1 qtm-label text-uppercase white-space-nowrap" :rowspan="labelRowSpan">
        <slot name="label">
          {{ label }}
        </slot>
      </td>
    </tr>
    <tr v-for="attachment in visibleAttachments" :key="attachment.id">
      <td class="py-1">
        <a
          class="attachment-link text-decoration-none"
          :download="attachment.name"
          :href="attachment.url"
          target="_blank"
          v-text="attachment.name"
        />
      </td>
      <td class="py-1 text-no-wrap">
        {{ dateUploaded(attachment) }}
      </td>
    </tr>
    <tr v-if="expandable">
      <td>
        <span class="attachment-link expand-btn text-interactive" @click="expanded = ! expanded">
          {{ expandText }}
          <v-icon color="interactive">
            {{ expandIcon }}
          </v-icon>
        </span>
      </td>
      <td />
    </tr>
    <tr>
      <td>
        <slot />
      </td>
      <td />
    </tr>
    <tr v-if="bottomDivider">
      <td colspan="3">
        <v-divider class="my-1" />
      </td>
    </tr>
  </tbody>
</template>

<script setup lang="ts">
import { dateYear } from '~/models/filters'

export interface Props {
  attachments?: any[]
  bottomDivider?: boolean
  label?: string
  visibleCutoff?: number | string
}

const props = withDefaults(defineProps<Props>(), {
  attachments: () => [],
  bottomDivider: false,
  label: '',
  visibleCutoff: 2
})

const expanded = ref(false)

const expandable = computed(() => props.attachments.length > (props.visibleCutoff as number) + 1)
const expandIcon = computed(() => (expanded.value ? 'mdi-chevron-up' : 'mdi-chevron-down'))
const expandText = computed(() => `Show ${expanded.value ? 'Less' : 'More'}`)

const sortedAttachments = computed(() => {
  const localAttachments = props.attachments

  localAttachments.sort((a: any, b: any) => b.date_uploaded - a.date_uploaded)
  return localAttachments
})
const visibleAttachments = computed(() => {
  if (expandable.value && !expanded.value) {
    return sortedAttachments.value.slice(0, props.visibleCutoff as number)
  }
  return sortedAttachments.value
})
const labelRowSpan = computed(() => {
  let additionalSpan = 2

  if (expandable.value) {
    additionalSpan += 1
  }

  return visibleAttachments.value.length + additionalSpan
})

const dateUploaded = (a: any) => dateYear(a.date_uploaded)
</script>

<style lang="scss" scoped>
.attachment-link {
  font-weight: 500;
}

.attachments-row {
  vertical-align: top;
}

.expand-btn {
  cursor: pointer;
  vertical-align: middle;
}
</style>
