<template>
  <div class="bg-white container qtm-body">
    <div class="bg-light-teal px-3 py-4 d-flex justify-space-between qtm-h1">
      <div>
        Total
      </div>
      <div>
        {{ currency(estimatedTotal) }}
      </div>
    </div>
    <div class="px-3 py-2 qtm-border">
      <div v-for="section in sections" :key="section.label" class="d-flex justify-space-between py-2 total-section">
        <div v-text="section.label" />
        <div v-text="currency(section.value)" />
      </div>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep'
import debounce from 'lodash.debounce'
import isEqual from 'lodash.isequal'
import { currency } from '~/models/filters'

export default {
  name: 'purchase-order-total-price',
  props: {
    deliveryCharge: {
      type: [Number, String],
      default: 0
    },
    deliveryRequired: {
      type: Boolean,
      default: false
    },
    freightTax: {
      type: Number,
      default: undefined
    },
    pickupCharge: {
      type: [Number, String],
      default: 0
    },
    skus: {
      type: Array,
      default: () => []
    },
    modelValue: {
      type: Number,
      default: 0
    },
  },
  emits: ['change', 'update:model-value'],
  data() {
    return {
      deliveryTotal: null,
      estimatedTotal: this.modelValue,
      previousData: null,
      itemSubtotal: null,
      taxTotal: null,
    }
  },
  computed: {
    sections() {
      const sections = [
        { label: 'Items subtotal', value: this.itemSubtotal },
        { label: 'Delivery', value: this.deliveryTotal },
      ]

      if (this.taxTotal) {
        sections.push({ label: 'Taxes', value: this.taxTotal })
      }

      return sections
    },
  },
  watch: {
    deliveryCharge() {
      this.debouncedEstimate()
    },
    deliveryRequired() {
      this.debouncedEstimate()
    },
    freightTax() {
      this.debouncedEstimate()
    },
    pickupCharge() {
      this.debouncedEstimate()
    },
    skus: {
      deep: true,
      handler() {
        return this.debouncedEstimate()
      }
    },
  },
  mounted() {
    this.estimate()
  },
  created() {
    this.debouncedEstimate = debounce(this.estimate.bind(this), 350)
  },
  methods: {
    currency(value) {
      return currency(value)
    },
    async estimate() {
      const deliveryCharge = this.deliveryRequired ? this.deliveryCharge : null
      const pickupCharge = this.deliveryRequired ? this.pickupCharge : null
      const estimateData = {
        delivery_charge: deliveryCharge || null,
        freight_tax: this.freightTax,
        pickup_charge: pickupCharge || null,
        poskus: this.skus.map((sku) => ({
          day_rate: sku.day_rate || null,
          month_rate: sku.month_rate || null,
          quantity: sku.quantity || null,
          rental_duration: sku.rental_duration || null,
          rental_duration_unit: sku.rental_duration_unit || null,
          tax: sku.tax || null,
          unit_price: sku.unit_price || null,
          week_rate: sku.week_rate || null,
        })),
      }

      if (isEqual(estimateData, this.previousData)) {
        return
      }

      try {
        const result = await this.$api.v1.purchaseOrders.estimate(estimateData)

        this.taxLineItems = result.tax_line_items
        this.taxTotal = result.tax_line_items.reduce((acc, item) => acc + Number(item.total_price), 0) || null
        this.estimatedTotal = Number(result.estimate) + Number(this.taxTotal)
        this.deliveryTotal = (Number(deliveryCharge) + Number(pickupCharge)) || null
        this.itemSubtotal = (Number(result.estimate) - Number(this.deliveryTotal)) || null
        this.$emit('change', result.estimate)
        this.$emit('update:model-value', result.estimate)
        this.previousData = cloneDeep(estimateData)
      }
      catch (error) {
        this.$error.report(error, false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: inline-block;
  width: 358px;
  text-align: left;
}

.total-section:not(:last-child) {
  border-bottom:1px solid rgb(var(--v-theme-light-grey));
}
</style>
