<template>
  <table class="items-table text-center">
    <thead>
      <tr>
        <th class="text-left text-no-wrap">
          DESCRIPTION
        </th>
        <th v-for="(header, i) in headers" :key="i" class="text-no-wrap">
          {{ header }}
        </th>
      </tr>
    </thead>
    <tbody>
      <item-row
        v-for="item in items"
        :key="item.id"
        class="alternating-rows"
        :item="item"
        :with-cost-code="withCostCode"
      />
      <tr class="alternating-rows">
        <td class="text-left">
          Delivery Charge
        </td>
        <td />
        <td />
        <td />
        <td />
        <td class="text-right">
          {{ currency(deliveryCharge) }}
        </td>
        <td v-if="withCostCode" v-text="freightCostCode" />
      </tr>
      <tr class="alternating-rows">
        <td class="text-left">
          Pickup Charge
        </td>
        <td />
        <td />
        <td />
        <td />
        <td class="text-right">
          {{ currency(pickupCharge) }}
        </td>
        <td v-if="withCostCode" v-text="freightCostCode" />
      </tr>
    </tbody>
    <tbody>
      <tr>
        <th class="text-left" colspan="4">
          SUPPLIER COMMENT
        </th>
        <td />
        <td />
        <td v-if="withCostCode" />
      </tr>
      <tr>
        <td class="text-left" colspan="4">
          <span v-if="comments">
            {{ comments }}
          </span>
          <span v-else style="opacity: 0.5">
            N/A
          </span>
        </td>
        <td />
        <td />
        <td v-if="withCostCode" />
      </tr>
      <tr>
        <th class="text-left" colspan="4">
          SCOPE OF WORK
        </th>
        <td />
        <td />
        <td v-if="withCostCode" />
      </tr>
      <tr>
        <td class="text-left" colspan="4">
          <span v-if="scopeOfWork">
            {{ scopeOfWork }}
          </span>
          <span v-else style="opacity: 0.5">
            N/A
          </span>
        </td>
        <td />
        <td />
        <td v-if="withCostCode" />
      </tr>
      <tr class="total-price-line">
        <td colspan="4" />
        <th>
          Total
        </th>
        <th class="text-no-wrap text-right">
          {{ currency(totalPrice) }}
        </th>
        <th v-if="withCostCode" />
      </tr>
    </tbody>
  </table>
</template>

<script>
import { currency } from '~/models/filters'
import ItemRow from '@/components/orders/item-row.vue'

export default {
  name: 'items-table',
  components: { ItemRow, },
  props: {
    comments: {
      type: String,
      default: null
    },
    deliveryCharge: {
      type: [Number, String],
      default: 0
    },
    freightCostCode: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      required: true
    },
    pickupCharge: {
      type: [Number, String],
      default: 0
    },
    scopeOfWork: {
      type: String,
      default: null
    },
    totalPrice: {
      type: Number,
      default: 0
    },
    withCostCode: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    headers() {
      const headers = [
        'DURATION',
        'QTY',
        'U/M',
        'UNIT PRICE',
        'SUBTOTAL',
      ]

      if (this.withCostCode) {
        headers.push('COST CODE')
      }

      return headers
    },
  },
  methods: {
    currency(value) {
      return currency(value)
    },
  }
}
</script>

<style lang="scss">
$border: 1px solid rgb(var(--v-theme-background));
.items-table {
  border-collapse: collapse;
  width: 100%;
}

.items-table tbody {
  border-bottom: $border !important;
}

.items-table td,
.items-table th {
  border-left: $border;
  border-right: $border;
  padding: 0.25rem;
  vertical-align: top;
}

.items-table th {
  background-color: rgb(var(--v-theme-background));
}

.items-table thead {
  border-top: $border;
}

.items-table tr td:first-child,
.items-table tr th:first-child {
  border-left: none;
}

.items-table tr td:last-child,
.items-table tr th:last-child {
  border-right: none;
}

.items-table .alternating-rows:nth-child(even) {
  background-color: rgb(var(--v-theme-background));
}

.total-price-line {
  font-size: 1.25rem;
}
</style>
