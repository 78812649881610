<template>
  <v-table class="change-order-table text-no-wrap" density="compact">
    <tbody>
      <tr v-if="purchaseItems.length" class="header-row">
        <th class="item-number-column text-center">
          Item
        </th>
        <th class="operation-column">
          Operation
        </th>
        <th class="description-column">
          Description
        </th>
        <th class="quantity-column" colspan="2">
          Quantity
        </th>
        <th class="unit-price-column" colspan="3">
          Unit Price
        </th>
        <th v-if="showTax">
          Tax
        </th>
        <th class="cost-code-column">
          Cost Code
        </th>
      </tr>

      <template v-for="item in purchaseItems" :key="item.item_number">
        <tr>
          <td class="text-center" :rowspan="item.comment ? 2 : undefined">
            {{ item.item_number }}
          </td>
          <td class="operation-column">
            {{ item.operation }}
          </td>
          <td>
            <change-order-diff-value :value="item.description" />
          </td>
          <td colspan="2">
            <change-order-diff-value :value="quantityString(item)" />
          </td>
          <td colspan="3">
            <change-order-diff-value currency :value="item.unit_price" />
          </td>
          <td v-if="showTax">
            <change-order-diff-value :value="item.displayable_tax" />
          </td>
          <td>
            <change-order-diff-value :value="item.cost_code" />
          </td>
        </tr>

        <tr v-if="item.comment">
          <td :colspan="showTax ? 9 : 8">
            <div class="overflow-force-visible pb-1">
              <i>Comments:</i>
              &nbsp;
              <change-order-diff-value :value="item.comment" />
            </div>
          </td>
        </tr>
      </template>

      <tr v-if="rentalItems.length" class="header-row">
        <th class="item-number-column text-center">
          Item
        </th>
        <th class="operation-column">
          Operation
        </th>
        <th>Description</th>
        <th class="rental-quantity-column">
          Quantity
        </th>
        <th class="rental-duration-column">
          Rental Duration
        </th>
        <th class="rental-rate-column">
          Day Rate
        </th>
        <th class="rental-rate-column">
          Week Rate
        </th>
        <th class="rental-rate-column">
          Month Rate
        </th>
        <th v-if="showTax">
          Tax
        </th>
        <th class="cost-code-column">
          Cost Code
        </th>
      </tr>

      <template v-for="item in rentalItems" :key="item.item_number">
        <tr>
          <td class="text-center" :rowspan="item.comment ? 2 : undefined">
            {{ item.item_number }}
          </td>
          <td>{{ item.operation }}</td>
          <td>
            <change-order-diff-value :value="item.description" />
          </td>
          <td>
            <change-order-diff-value :value="quantityString(item)" />
          </td>
          <td>
            <change-order-diff-value :value="rentalDurationString(item)" />
          </td>
          <td>
            <change-order-diff-value currency :value="item.day_rate" />
          </td>
          <td>
            <change-order-diff-value currency :value="item.week_rate" />
          </td>
          <td>
            <change-order-diff-value currency :value="item.month_rate" />
          </td>
          <td v-if="showTax">
            <change-order-diff-value :value="item.displayable_tax" />
          </td>
          <td>
            <change-order-diff-value :value="item.cost_code" />
          </td>
        </tr>

        <tr v-if="item.comment">
          <td :colspan="showTax ? 9 : 8">
            <div class="overflow-force-visible pb-1">
              <i>Comments:</i>
              &nbsp;
              <change-order-diff-value :value="item.comment" />
            </div>
          </td>
        </tr>
      </template>

      <tr v-if="!purchaseItems.length && !rentalItems.length" class="header-row">
        <th class="freight-leading-spacer" colspan="5" />
        <th class="unit-price-column" colspan="3">
          Charges
        </th>
        <th v-if="showTax">
          Tax
        </th>
        <th class="cost-code-column">
          Cost Code
        </th>
      </tr>

      <tr v-if="changeOrder.delivery_charge !== '' || changeOrder.freight_cost_code !== ''">
        <th class="text-right" colspan="5">
          Freight / Delivery
        </th>
        <td colspan="3">
          <change-order-diff-value currency :value="changeOrder.delivery_charge" />
        </td>
        <td v-if="showTax">
          <change-order-diff-value :value="changeOrder.displayable_freight_tax" />
        </td>
        <td>
          <change-order-diff-value :value="changeOrder.freight_cost_code" />
        </td>
      </tr>

      <tr v-if="changeOrder.pickup_charge !== '' || changeOrder.freight_cost_code !== ''">
        <th class="text-right" colspan="5">
          Pickup
        </th>
        <td colspan="3">
          <change-order-diff-value currency :value="changeOrder.pickup_charge" />
        </td>
        <td v-if="showTax">
          <change-order-diff-value :value="changeOrder.displayable_freight_tax" />
        </td>
        <td>
          <change-order-diff-value :value="changeOrder.freight_cost_code" />
        </td>
      </tr>
    </tbody>
  </v-table>
</template>

<script>
import ChangeOrderDiffValue from '@/components/purchase-orders/change-order-diff-value.vue'

export default {
  name: 'change-order-table',
  components: { ChangeOrderDiffValue },
  props: {
    changeOrder: {
      type: Object,
      required: true,
    },
    showTax: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    purchaseItems() {
      return this.changeOrder.items.filter(item => !item.rental_duration)
    },

    rentalItems() {
      return this.changeOrder.items.filter(item => item.rental_duration)
    }
  },
  methods: {
    combinedValue(item, field1, field2) {
      if (typeof item[field1] === 'object' || typeof item[field2] === 'object') {
        const oldField1 = (typeof item[field1].old !== 'undefined') ? item[field1].old : item[field1]
        const oldField2 = (typeof item[field2].old !== 'undefined') ? item[field2].old : item[field2]

        const newField1 = (typeof item[field1].new !== 'undefined') ? item[field1].new : item[field1]
        const newField2 = (typeof item[field2].new !== 'undefined') ? item[field2].new : item[field2]

        return {
          old: `${oldField1} ${oldField2}`,
          new: `${newField1} ${newField2}`
        }
      }
      return `${item[field1]} ${item[field2] || ''}`
    },

    quantityString(item) {
      return this.combinedValue(item, 'quantity', 'unit')
    },

    rentalDurationString(item) {
      return this.combinedValue(item, 'rental_duration', 'rental_duration_unit')
    }
  }
}
</script>

<style scoped lang="scss">
$item-number-width: 4rem;
$operation-width: 6rem;
$description-width: 13rem;
$quantity-width: 16rem;
$rental-rate-width: 8rem;
$unit-price-width: 3 * $rental-rate-width;

.change-order-table {
  table-layout: fixed;
  border: 1px solid rgb(var(--v-theme-light-grey));
}

.header-row {
  background-color: rgb(var(--v-theme-light-grey));
}

.item-number-column {
  width: $item-number-width;
}

.operation-column {
  width: $operation-width;
}

.description-column {
  width: $description-width;
}

.cost-code-column {
  width: 7rem;
}

.rental-rate-column {
  width: 8rem;
}

.unit-price-column {
  width: $unit-price-width;
}

.quantity-column {
  width: $quantity-width;
}

.rental-duration-column {
  width: $rental-rate-width;
}

.rental-quantity-column {
  width: 8rem;
}

.freight-leading-spacer {
  width: $item-number-width + $operation-width + $description-width + $quantity-width;
}
</style>
