<template>
  <tr>
    <td class="text-left">
      <div>
        {{ item.substitutions || item.description }}
      </div>
      <div style="opacity: 0.5">
        {{ item.comment }}
      </div>
    </td>
    <td v-if="rental">
      {{ item.rental_duration }} {{ item.rental_duration_unit }}
    </td>
    <td v-else>
      &mdash;
    </td>
    <td>
      {{ Number(item.quantity) }}
    </td>
    <td>
      {{ item.unit }}
    </td>
    <td class="text-right">
      <span :title="hasRentalPrice ? `per ${item.rental_price.per}` : undefined">
        {{ currency(unitPrice) }}
      </span>
    </td>
    <td class="text-right">
      {{ currency(subTotal) }}
    </td>
    <td v-if="withCostCode" v-text="item.cost_code" />
  </tr>
</template>

<script>
import { currency } from '~/models/filters'

export default {
  name: 'item-row',
  props: {
    item: {
      type: Object,
      required: true
    },
    withCostCode: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    rental() {
      return !!this.item.rental_duration
    },
    hasRentalPrice() {
      return this.rental && this.item.rental_price
    },
    subTotal() {
      const price = (this.hasRentalPrice ? this.item.rental_price.price : this.item.unit_price) || 0
      return this.item.quantity * price
    },
    unitPrice() {
      return this.hasRentalPrice ? this.item.rental_price.rate : this.item.unit_price
    },
  },
  methods: {
    currency(value) {
      return currency(value)
    },
  }
}
</script>
