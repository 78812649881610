<template>
  <div>
    <div v-if="po" class="view-purchase-order">
      <approval-activity :order="order" :po="po" />

      <purchase-order-detail :order="order" :po="po">
        <template v-slot:action>
          <qtm-btn
            v-if="showCOButton"
            class="mr-8"
            secondary
            :to="{ name: 'purchase-orders-id-change-order', params: { id: po.id } }"
          >
            Change Order
          </qtm-btn>
        </template>
      </purchase-order-detail>

      <purchase-order-attachments :order="order" :invoices="order.invoices" :po="po" />

      <change-orders-list :po="po" />

      <order-info-summary v-if="!order.isDirectToPO" collapsed :order="order" />

      <order-summary v-if="order.scope_of_work" collapsed :scope-of-work="order.scope_of_work" />

      <quotes-review v-if="!order.isDirectToPO" collapsed :order="order" />
    </div>
    <v-alert v-else max-width="45rem" type="error">
      This order has no purchase order, please visit the admin.
    </v-alert>
    <invoice-dialog
      v-if="po"
      :invoices="order.invoices"
      :order="order"
      :po="po"
      @approved="$emit('refresh-order', $event)"
    />
  </div>
</template>

<script>
import ApprovalActivity from '@/components/orders/approval-activity.vue'
import ChangeOrdersList from '@/components/purchase-orders/change-orders-list.vue'
import InvoiceDialog from '@/components/invoices/invoice-dialog.vue'
import OrderInfoSummary from '@/components/orders/order-info-summary.vue'
import OrderSummary from '@/components/orders/order-summary.vue'
import PurchaseOrderDetail from '@/components/purchase-orders/purchase-order-detail.vue'
import PurchaseOrderAttachments from '@/components/purchase-orders/purchase-order-attachments.vue'
import QuotesReview from '@/components/quotes/quotes-review.vue'

export default {
  name: 'view-purchase-order',
  components: {
    ApprovalActivity,
    ChangeOrdersList,
    InvoiceDialog,
    OrderInfoSummary,
    OrderSummary,
    PurchaseOrderDetail,
    PurchaseOrderAttachments,
    QuotesReview,
  },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  emits: ['refresh-order'],
  computed: {
    po() {
      return this.order.hasPurchaseOrder ? this.order.pos[0] : undefined
    },
    showCOButton() {
      if (!this.po) {
        return false
      }

      if (this.po.jobsite?.accounting_id && !this.po.latest_change_order?.accounting_success) {
        return false
      }

      return this.po.canIssueChangeOrder
    },
  },
}
</script>

<style lang="scss" scoped>
.view-purchase-order > * {
  margin-bottom: 1rem;
}
</style>
