<template>
  <span>
    <component
      :is="component"
      ref="input"
      v-bind="$attrs"
      data-test="cost-code-select"
      :hide-details="hideDetails"
      item-value="value"
      :items="costCodeItems"
      maxlength="20"
      :menu-props="{}"
      placeholder="Select cost code"
      :return-object="false"
      @update:model-value="$emit('update:model-value', $event || '')"
    >
      <template v-for="(_, name) in $slots" v-slot:[name]="slotData" :key="name">
        <slot v-bind="slotData" :key="name" :name="name" />
      </template>
    </component>
  </span>
</template>

<script setup lang="ts">
import { useStore } from 'vuex'

export interface Props {
  costCodes?: any
  forceSelect?: boolean
  hideDetails?: boolean | string
}

const props = withDefaults(defineProps<Props>(), {
  costCodes: () => [],
  forceSelect: false,
  hideDetails: true
})
defineEmits(['update:model-value'])

const store = useStore()
const allowCustomCostCodes = computed(() => store.getters['auth/allowCustomCostCodes'])

const component = computed(() => {
  return !props.forceSelect && allowCustomCostCodes.value ? 'qtm-combobox' : 'qtm-autocomplete'
})

const costCodeItems = computed(() => {
  return props.costCodes.map((c: any) => ({ text: `${c.cost_code} - ${c.description}`, value: c.cost_code }))
})

const input = ref<any>(null)
const focus = () => {
  input.value?.focus()
}
defineExpose({ focus })
</script>
