import { Order, OrderState } from '@quotetome/materials-api'
// eslint-disable-next-line import/no-cycle
import { api } from '~/plugins/materials-api'
import { errorHandler } from '~/plugins/error-handler'

const ORDER_REFRESH_INTERVAL = 60 * 1000 // 1 minute
// request Orders modifed within the last 2 minutes
const MODIFIED_MIN_AGO = 2

let orderRefreshInterval

const actions = {
  async initializeQBoard({ commit, state }) {
    const orderRefresh = () => {
      const FETCH_LIMIT = 30
      const fetchCount = state.orderFetchCount

      if (FETCH_LIMIT && fetchCount >= FETCH_LIMIT) {
        commit('setOrderFetchCount', 0)
        api.v1.rfqs.list({
          excludeStates: OrderState.adminExcludeStates(),
          output: 'qboard',
        }).then(orders => commit('setOrders', orders))
          .catch(errorHandler.report)
      }
      else {
        api.v1.rfqs.list({
          modifiedMinAgo: MODIFIED_MIN_AGO,
          output: 'qboard',
        }).then(orders => {
          commit('updateChangedOrders', orders)
          commit('setOrderFetchCount', fetchCount + 1)
        }).catch(errorHandler.report)
      }
    }

    if (!state.ordersFetched && !state.fetchingOrders) {
      commit('setFetchingOrders', true)
      commit('setOrders', [])

      const pagination = { limit: 30, offset: 0 }
      let moreOrders = true

      try {
        while (moreOrders) {
          // Allow awaiting in this loop to throttle connections to the API
          // eslint-disable-next-line no-await-in-loop
          const orders = await api.v1.rfqs.list({
            excludeStates: OrderState.adminExcludeStates(),
            ordering: 'state__internal_sort_order,delivery_date,date_created',
            output: 'qboard',
            pagination,
          }).then(response => response.data.map(order => new Order(order, { completeData: false })))

          commit('pushOrders', orders)
          pagination.offset += orders.length

          moreOrders = orders.length >= pagination.limit
        }

        commit('markOrdersFetched')
        commit('setFetchingOrders', false)

        if (orderRefreshInterval) {
          clearInterval(orderRefreshInterval)
        }

        orderRefreshInterval = setInterval(orderRefresh, ORDER_REFRESH_INTERVAL)
      }
      catch (error) {
        errorHandler.report(error)
      }
    }
  }
}

export default actions
