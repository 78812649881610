<template>
  <qtm-content-block
    :collapsible="showTitle"
    :flat="!showTitle"
    :padding="showTitle ? undefined : 0"
    :title="showTitle ? title : undefined"
  >
    <template v-slot:top-right>
      <slot name="top-right" />
    </template>
    <file-dropper
      v-if="allowUpload"
      v-model="v$.file.$model"
      :accept="accept"
      :color="color"
      :error-messages="fileError"
      :loading="loading"
      :progress="progress"
      @change="attachFile"
    >
      <template v-slot:label="labelProps">
        <slot v-bind="labelProps" name="label" />
      </template>
    </file-dropper>
    <div v-if="!hideAttachments" class="separated">
      <div v-for="attachment in uploadingFiles" :key="attachment.id" class="py-2">
        <attachment-preview :attachment="attachment" :dense="dense">
          <template v-slot:action>
            <v-progress-circular class="mr-1" color="interactive" indeterminate :size="24" :width="3" />
          </template>
        </attachment-preview>
      </div>

      <slot name="preview-prepend" />
      <div v-for="(attachment, i) in modelValue" :key="attachment?.id ?? `index-${i}`" class="py-2">
        <attachment-preview
          v-model="localSelectedAttachments"
          :allow-preview="allowPreview"
          :allow-select="allowSelect"
          :attachment="attachment"
          :dense="dense"
          :with-delete="allowDelete"
          @delete="removeFile(attachment, i)"
          @preview="$emit('preview', attachment)"
        >
          <template v-slot:action="{ attachment: attachmentToPreview }">
            <slot name="action" :attachment="attachmentToPreview" />
          </template>
        </attachment-preview>
      </div>
    </div>

    <slot />

    <confirmation-dialog
      v-model="deleteAttachmentConfirmation"
      cancel-button-text="Cancel"
      :loading="loading"
      ok-button-text="Delete attachment"
      title="Are you sure you want to delete?"
      @close="closeConfirmaitonDialog"
      @confirm="confirmDelete"
    >
      <span v-if="confirmDeleteModalText">
        {{ confirmDeleteModalText }}
      </span>
      <span v-else>
        {{ deleteAttachment ? deleteAttachment.name : "this file" }}
      </span>
    </confirmation-dialog>
  </qtm-content-block>
</template>

<script>
import AttachmentPreview from '@/components/attachments/attachment-preview.vue'
import ConfirmationDialog from '@/components/confirmation-dialog.vue'
import FileDropper from '@/components/file-dropper/file-dropper.vue'
import useValidation from '@/composables/validation'

const ACCEPTED_FILE_EXTENSIONS = [
  '.csv',
  '.doc',
  '.docx',
  '.heic',
  '.pdf',
  '.txt',
  '.xls',
  '.xlsx',
]
const ACCEPTED_FILE_TYPES = ['image/*']
const ACCEPT = ACCEPTED_FILE_TYPES.concat(ACCEPTED_FILE_EXTENSIONS).join(',')

// 50MB maximum file size
const MAX_FILE_SIZE_MB = 50
const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1024 * 1024

const validFileType = (file) => !file
  || ACCEPTED_FILE_TYPES.some(type => file.type.startsWith(type.replace('*', '')))
  || ACCEPTED_FILE_EXTENSIONS.some(ext => file.name.toLowerCase().endsWith(ext))

export default {
  name: 'order-attachments',
  components: { AttachmentPreview, ConfirmationDialog, FileDropper },
  props: {
    accept: {
      type: String,
      default: ACCEPT
    },
    analyze: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: Array,
      default: () => []
    },
    color: {
      type: String,
      default: undefined
    },
    contentType: {
      type: String,
      default: null
    },
    confirmDeleteModalText: {
      type: String,
      default: null
    },
    dense: {
      type: Boolean,
      default: false
    },
    objectId: {
      type: Number,
      default: null
    },
    poReceipt: {
      type: Boolean,
      default: false
    },
    removeTitle: {
      type: Boolean,
      default: false
    },
    noUpload: {
      type: Boolean,
      default: false
    },
    noDelete: {
      type: Boolean,
      default: false
    },
    hideAttachments: {
      type: Boolean,
      default: false
    },
    allowPreview: {
      type: Boolean,
      default: false
    },
    allowSelect: {
      type: Boolean,
      default: false
    },
    selectedAttachments: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: 'Attachments'
    },
    uploadingFiles: {
      type: Array,
      default: () => []
    }
  },
  emits: ['attachment-added', 'preview', 'update:loading', 'update:selected-attachments'],
  setup() {
    const { errors, isValid, v$ } = useValidation()

    return { errors, isValid, v$ }
  },
  validations: {
    file: {
      size: (file) => !file || file.size <= MAX_FILE_SIZE,
      type: validFileType,
    },
  },
  data() {
    return {
      file: null,
      progress: 0,
      loading: false,
      deleteAttachmentConfirmation: false,
      deleteAttachment: null,
      deleteIndex: null,
    }
  },
  computed: {
    fileError() {
      if (this.v$.file.type.$invalid) {
        return `Accepted file types are: ${this.accept}`
      }

      if (this.v$.file.size.$invalid) {
        return `File size can be at most ${MAX_FILE_SIZE_MB}MB`
      }

      return undefined
    },
    localSelectedAttachments: {
      get() {
        return this.selectedAttachments
      },
      set(value) {
        this.$emit('update:selected-attachments', value)
      }
    },
    showTitle() {
      return !this.removeTitle
    },
    allowUpload() {
      return !this.noUpload
    },
    allowDelete() {
      return !this.noDelete
    },
  },
  methods: {
    async attachFile(file) {
      if (!file) {
        return
      }

      if (!this.isValid()) {
        this.$toast.error(this.fileError)
        return
      }

      this.progress = 0
      this.loading = true
      this.$emit('update:loading', true)
      try {
        const formData = new FormData()
        formData.append('analyze', this.analyze)
        formData.append('file', file)
        const uploadMethod = this.poReceipt ? this.uploadPOReceipt : this.uploadFile
        const attachment = await uploadMethod(formData, this.uploadProgress)
        this.modelValue.push(attachment)
        this.$emit('attachment-added', attachment)
      }
      catch (error) {
        this.$error.report(error)
      }
      this.file = null
      this.loading = false
      this.$emit('update:loading', false)
    },

    uploadPOReceipt(formData, uploadProgress) {
      return this.$api.v1.purchaseOrders.addReceipt(this.objectId, formData, uploadProgress)
    },

    uploadFile(formData, uploadProgress) {
      if (this.contentType && this.objectId) {
        formData.append('content_type', this.contentType)
        formData.append('object_id', this.objectId)
      }
      return this.$api.v1.attachments.create(formData, uploadProgress)
    },

    uploadProgress(progressEvent) {
      this.progress = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100), 10)
    },

    removeFile(attachment, index) {
      this.deleteAttachment = attachment
      this.deleteIndex = index
      this.deleteAttachmentConfirmation = true
    },

    closeConfirmaitonDialog() {
      this.deleteAttachmentConfirmation = false
      this.deleteAttachment = null
      this.deleteIndex = null
    },

    async confirmDelete() {
      try {
        this.modelValue.splice(this.deleteIndex, 1)
        await this.$api.v1.attachments.delete(this.deleteAttachment.id)
        this.closeConfirmaitonDialog()
      }
      catch (error) {
        this.modelValue.splice(this.deleteIndex, 0, this.deleteAttachment)
        this.$error.report(error)
      }
    },
  }
}
</script>

<style lang="scss">
.separated > *:not(:last-child) {
  border-bottom: 1px solid rgb(var(--v-theme-light-grey));
}
</style>
