<template>
  <div ref="container">
    <div class="d-flex justify-space-between align-center mb-6">
      <slot name="label">
        <div class="qtm-label mt-4 text-uppercase text-dark-grey">
          Comments
        </div>
      </slot>
      <v-btn v-if="comments.length" class="mr-n3 mt-2" color="interactive" variant="text" @click="toggleSort">
        <v-icon size="small">
          {{ sort.icon }}
        </v-icon>
        {{ sort.label }} First
      </v-btn>
    </div>

    <div class="separated">
      <new-comment-box
        v-if="sort.key === Sort.Newest.key"
        :content-id="props.contentId"
        :content-type="props.contentType"
        :mentionable-users="mentionableUsers"
        @add-comment="addComment"
        @preview="$emit('preview', $event)"
      />

      <comment-row
        v-for="comment in sortedComments"
        :key="comment.id"
        class="py-3"
        :comment="comment"
        :download-only="downloadOnly"
        @preview="$emit('preview', $event)"
      />

      <new-comment-box
        v-if="sort.key === Sort.Oldest.key"
        class="mt-3"
        :content-id="props.contentId"
        :content-type="props.contentType"
        :mentionable-users="props.mentionableUsers"
        @add-comment="addComment"
        @preview="$emit('preview', $event)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import CommentRow from '@/components/comments/comment-row.vue'
import NewCommentBox from '@/components/comments/new-comment-box.vue'

const { $api, $error } = useNuxtApp()

defineEmits(['preview'])

export interface Props {
  contentId: number
  contentType: string
  downloadOnly?: boolean
  mentionableUsers: any[]
}
const props = defineProps<Props>()

const comments = ref<any[]>([])

const Sort = {
  Newest: { key: 0, label: 'Newest', icon: 'mdi-arrow-up-bold' },
  Oldest: { key: 1, label: 'Oldest', icon: 'mdi-arrow-down-bold' },
} as const
const sort = ref<{ key: number; label: string; icon: string }>({ ...Sort.Newest })
const sortKey = localStorage.getItem('comments.sort')

if (sortKey) {
  const sortValue = Object.values(Sort).find((s) => s.key === Number(sortKey))

  if (sortValue) {
    sort.value = { ...sortValue }
  }
}

const toggleSort = () => {
  sort.value = { ...(sort.value.key === Sort.Newest.key ? Sort.Oldest : Sort.Newest) }
  localStorage.setItem('comments.sort', sort.value.key.toString())
}

const sortedComments = computed(() => {
  return sort.value.key === Sort.Newest.key
    ? comments.value.sort((a, b) => new Date(b.date_created).getTime() - new Date(a.date_created).getTime())
    : comments.value.sort((a, b) => new Date(a.date_created).getTime() - new Date(b.date_created).getTime())
})

const container = ref<any>(null)
const route = useRoute()

onMounted(async () => {
  if (route.query.comment) {
    container.value?.scrollIntoView({ behavior: 'smooth' })
  }

  try {
    comments.value = await $api.v1.comments.list(props.contentId, props.contentType)
  }
  catch (error) {
    $error.report(error)
  }
})

const addComment = (createdComment: any) => {
  comments.value.push(createdComment)
}
</script>

<style lang="scss" scoped>
.separated > *:not(:last-child) {
  border-bottom: 1px solid rgb(var(--v-theme-light-grey));
}
</style>
