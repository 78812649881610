<template>
  <div class="qtm-border quote-card" :class="{ 'winning-quote': quote.is_winning_quote }">
    <div class="background text-secondary quote-card-title">
      {{ quote.vendor.name }}
    </div>
    <div v-if="priceGiven" class="pa-3 quote-summary text-secondary">
      <div class="d-flex justify-space-between">
        <div class="text-mid-grey">
          Total
        </div>
        <quote-awarded-chip v-if="quote.is_winning_quote && !order.requiresAdjustments" :quote="quote" />
      </div>
      <div class="pb-3 total-price">
        {{ totalPrice }}
      </div>
      <quote-attachments-link :quote="quote" />
      <quote-delivery-status :quote="quote" :order="order" />
      <v-btn
        block
        class="mt-5"
        :class="{ 'view-winning-quote-btn': quote.is_winning_quote, 'view-quote-btn': !quote.is_winning_quote, }"
        data-test="view-quote"
        variant="outlined"
        @click="$emit('click', quote)"
      >
        {{ buttonText }}
      </v-btn>
    </div>
    <quote-response
      v-else
      class="quote-summary"
      :comments="quote.comments"
      compact
      :response="quote.response_received"
    />
  </div>
</template>

<script>
import { currency } from '~/models/filters'
import QuoteAttachmentsLink from '@/components/quotes/quote-attachments-link.vue'
import QuoteAwardedChip from '@/components/quotes/quote-awarded-chip.vue'
import QuoteDeliveryStatus from '@/components/quotes/quote-delivery-status.vue'
import QuoteResponse from '@/components/quotes/quote-response.vue'

export default {
  name: 'quote-summary-card',
  components: { QuoteAttachmentsLink, QuoteAwardedChip, QuoteDeliveryStatus, QuoteResponse },
  props: {
    quote: {
      type: Object,
      required: true
    },
    order: {
      type: Object,
      required: true
    },
  },
  emits: ['click'],
  computed: {
    buttonText() {
      return this.quote.is_winning_quote ? 'View Recommended Quote' : 'View Quote'
    },
    priceGiven() {
      return this.quote.response_received === 'price given'
    },
    totalPrice() {
      return currency(this.quote.total_price)
    },
  },
}
</script>

<style lang="scss" scoped>
.winning-quote {
  border-color: rgb(var(--v-theme-sand));
}

.quote-card-title {
  font-weight: bold;
  overflow: hidden;
  padding: 0.5rem;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: uppercase;
}

.winning-quote .quote-card-title {
  background-color: rgb(var(--v-theme-sand)) !important;
}

.quote-summary {
  min-height: 190px;
}

.total-price {
  font-size: 1.5rem;
  font-weight: bold;
}

.view-quote-btn {
  background-color: rgb(var(--v-theme-background));
  border: 1px solid rgba(0, 0, 0, 0.3);
  font-weight: normal;
  font-size: 1rem;
}

.view-winning-quote-btn {
  background-color: rgb(var(--v-theme-primary));
  color: #FFF !important;
}
</style>
