import { v4 as uuidv4 } from 'uuid'
import { QUANTITY_DECIMAL_PLACES, SKU_NAME_LENGTH, UNIT_PRICE_DECIMAL_PLACES } from '~/constants'

interface Item {
  id: string
  boundingBox: any
  confidence: any
  cost_code?: string
  description: string
  has_rental_duration: boolean
  quantity: number
  rental_duration: number | null
  rental_duration_unit: string | null
  total_price: number
  unit: string
  unit_price: number | null
}

export default function parseItemAnalysis(results: any, defaultUnit: string, defaultCostCode?: string): any[] {
  const items: any[] = []

  results.ExpenseDocuments?.forEach((doc: any) => {
    doc.LineItemGroups?.forEach((group: any) => {
      group.LineItems?.forEach((item: any) => {
        const newItem: Item = {
          id: uuidv4(),
          boundingBox: {},
          confidence: {},
          cost_code: defaultCostCode,
          description: '',
          has_rental_duration: false,
          quantity: 1,
          rental_duration: null,
          rental_duration_unit: null,
          total_price: 0,
          unit: defaultUnit,
          unit_price: null,
        }

        item.LineItemExpenseFields?.forEach((field: any) => {
          if (!field.ValueDetection?.Text || !field.Type.Text) {
            return
          }

          const confidence = field.ValueDetection?.Confidence?.toFixed(1)
          const boundingBox = field.ValueDetection?.Geometry?.BoundingBox

          if (field.Type.Text === 'ITEM') {
            newItem.description = field.ValueDetection.Text.slice(0, SKU_NAME_LENGTH)
            newItem.confidence.description = confidence
            newItem.boundingBox.description = boundingBox
          }
          else if (field.Type.Text === 'QUANTITY') {
            const quantity = field.ValueDetection.Text.replace(/[^0-9.]/g, '')
            const floatQuantity = Number.parseFloat(quantity)

            if (!Number.isNaN(floatQuantity)) {
              const intQuantity = Number.parseInt(quantity, 10)
              const decimals = intQuantity === floatQuantity ? 0 : QUANTITY_DECIMAL_PLACES

              newItem.quantity = Number(floatQuantity.toFixed(decimals))
              newItem.confidence.quantity = confidence
              newItem.boundingBox.quantity = boundingBox
            }
          }
          else if (field.Type.Text === 'PRICE') {
            newItem.total_price = Number(field.ValueDetection.Text.replace(/[^0-9.]/g, ''))
            newItem.confidence.total_price = confidence
            newItem.boundingBox.total_price = boundingBox
          }

          if (newItem.quantity && newItem.total_price) {
            const unitPrice = newItem.total_price / newItem.quantity

            if (Number.isFinite(unitPrice)) {
              newItem.unit_price = Number(unitPrice.toFixed(UNIT_PRICE_DECIMAL_PLACES))
            }
          }
        })

        if (newItem.description) {
          items.push(newItem)
        }
      })
    })
  })

  return items
}
