<template>
  <qtm-content-block v-if="po || draftQuote" title="Purchase order">
    <template v-slot:title>
      <div class="d-flex flex-wrap pl-4">
        <div class="mr-2 qtm-h1 text-secondary">
          PURCHASE ORDER
        </div>
        <ref-number
          v-if="po && po.po_number"
          :cancelled="order.cancelled"
          color="light-grey"
          :number="po.po_number"
          text-color="secondary"
        />
      </div>
    </template>
    <template v-slot:top-right>
      <sync-status v-if="showSync" class="mr-3" :order="order" :po="po" />
      <slot name="action" />
    </template>
    <purchase-order-detail-info :contact="contact" :order="order" :po="po" :supplier="supplier" />
    <div class="d-flex my-3 qtm-h3 text-secondary">
      ORDER ITEMS
      <div v-if="!order.cancelled" class="pl-2 pt-1 qtm-body">
        <span v-if="partiallyReceived">
          <pie-chart-icon />
          Partially Received
        </span>
        <span v-else-if="receivedInFull">
          <v-icon color="interactive">
            mdi-check-circle
          </v-icon>
          Order received in full
        </span>
      </div>
    </div>
    <purchase-order-items-table
      :boms="boms"
      :delivery-charge="deliveryCharge"
      :freight-cost-code="freightCostCode"
      :pickup-charge="pickupCharge"
      :items="items"
      :tax="tax"
      :total-price="totalPrice"
      :with-received="partiallyReceived || receivedInFull"
    />
    <div v-if="po && po.comments" class="mt-6">
      <div class="qtm-label text-secondary">
        General Comments
      </div>
      <div v-text="po.comments" />
    </div>
  </qtm-content-block>
</template>

<script setup lang="ts">
import type { Order, PurchaseOrder } from '@quotetome/materials-api'
import PieChartIcon from '@/components/pie-chart-icon.vue'
import PurchaseOrderDetailInfo from '@/components/purchase-orders/purchase-order-detail-info.vue'
import PurchaseOrderItemsTable from '@/components/purchase-orders/purchase-order-items-table.vue'
import RefNumber from '@/components/ref-number.vue'
import SyncStatus from '@/components/purchase-orders/sync-status.vue'

export interface Props {
  boms?: any[]
  order: Order
  po?: PurchaseOrder
}

const props = withDefaults(defineProps<Props>(), {
  boms: () => [],
  po: undefined,
})

const partiallyReceived = computed(() => {
  return !!props.po?.partiallyReceived
})
const receivedInFull = computed(() => {
  return props.po?.poskus.every(item => Number(item.received_quantity) === Number(item.quantity))
})
const showSync = computed(() => !!props.po?.jobsite?.accounting_id && props.order.showAccountingSync)

const draftQuote = computed(() => (props.order.isDraft ? props.order.quotes[0] : undefined))
const contact = computed(() => {
  if (props.po) {
    return {
      email: props.po.supplier_contact_email,
      first_name: props.po.supplier_contact_first_name,
      last_name: props.po.supplier_contact_last_name,
      phone_number: props.po.supplier_contact_phone,
      phone_extension: props.po.supplier_contact_phone_extension,
    }
  }

  return draftQuote.value?.salesperson
})
const deliveryCharge = computed(() => {
  return props.po?.latest_change_order?.delivery_charge ?? draftQuote.value?.delivery_charge
})
const freightCostCode = computed(() => {
  return props.po?.latest_change_order?.freight_cost_code ?? props.order.freight_cost_code
})
const items = computed<any[]>(() => props.po?.poskus ?? draftQuote.value?.skus?.map(item => ({
  ...item,
  cost_code: item.rfqsku?.cost_code,
})) ?? [])
const pickupCharge = computed(() => {
  return props.po?.latest_change_order?.pickup_charge ?? draftQuote.value?.pickup_charge
})
const supplier = computed(() => {
  if (props.po) {
    return {
      address: props.po.supplier_address,
      city: props.po.supplier_city,
      name: props.po.supplier_name,
      province: props.po.supplier_province,
    }
  }

  return draftQuote.value?.vendor
})
const tax = computed(() => props.po?.total_tax)
const totalPrice = computed(() => props.po?.total_price ?? draftQuote.value?.total_price)
</script>
