import moment from 'moment'
import useValidate from '@vuelidate/core'
import type { ValidationArgs } from '@vuelidate/core'

/*
  Walks the vuelidate `v$` object to find fields that are in an error state.

  The generated `errors` object mirrors the values in the `v$` object.
  For example given:

  validations: {
    object: {
      childObject: {
        childField: {...},
      },
      objectField: {...},
    },
    field: {...},
  }

  Will result in the following errors object:

  {
    object: {
      childObject: {
        childField: error?,
      },
      objectField: error?,
    },
    field: error?,
  }

  The `errorsByKey` object must mirror the `v$` object.
  For example, to set the error message for `childField` only, implement the following:

  errorsByKey: {
    object: {
      childObject: {
        childField: 'Some error message'
      }
    }
  }
*/

export interface ValidationConfig {
  defaultError?: string
  errorsByKey?: Record<string, any>
  rules?: ValidationArgs | Ref<ValidationArgs>
  state?: ValidationArgs | Ref<ValidationArgs>
}

export default function useValidation(config: ValidationConfig = {}) {
  const defaultError = config.defaultError || 'This field is required'
  const errorsByKey = config.errorsByKey || {}

  let v$ = useValidate()

  if (config.rules && config.state) {
    v$ = useValidate(config.rules, config.state)
  }

  function getObjectErrorByKeys(keys: string[]) {
    let localErrorsByKey = unref(errorsByKey)

    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i]

      if (localErrorsByKey[key]) {
        localErrorsByKey = localErrorsByKey[key]
      }
      else if (typeof localErrorsByKey === 'string') {
        return localErrorsByKey
      }
      else {
        return defaultError
      }
    }

    return localErrorsByKey || defaultError
  }

  function getObjectErrors(obj: any, keyHistory: string[] = []) {
    const errors: any = {}
    const keys = keyHistory

    Object.keys(obj)
      .filter(key => key[0] !== '$')
      .forEach(key => {
        keys.push(key)
        const objKey = unref(obj[key])

        // Field errors
        if (!(typeof objKey.$model === 'object') || objKey.$model === null) {
          if (objKey.$dirty && objKey.$invalid) {
            errors[key] = getObjectErrorByKeys(keys)
          }
        }
        // Array errors
        else if (objKey.$each) {
          errors[key] = []
          const iter = objKey.$each.$iter

          Object.keys(iter)
            .filter(i => iter[i].$dirty && iter[i].$invalid)
            .forEach(i => {
              errors[key][i] = getObjectErrorByKeys(keys)
            })
        }
        // Date errors
        else if (moment.isMoment(objKey.$model)) {
          if (objKey.$dirty && objKey.$invalid) {
            errors[key] = getObjectErrorByKeys(keys)
          }
        }
        // Object errors
        else {
          errors[key] = getObjectErrors(objKey, keys)
        }
        keys.pop()
      })

    return errors
  }

  const errors = computed(() => getObjectErrors(unref(v$)))
  const isValid = () => {
    unref(v$).$touch()

    return !(unref(v$).$invalid)
  }

  return {
    errors,
    isValid,
    v$,
  }
}
