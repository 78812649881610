<template>
  <document-search
    v-bind="$attrs"
    data-test="supplier-search-input"
    document="supplier"
    :filters="{ country, user }"
    :item-title="itemText"
    :placeholder="placeholder"
  >
    <template v-for="(_, name) in $slots" v-slot:[name]="slotData" :key="name">
      <slot v-bind="slotData" :key="name" :name="name" />
    </template>
  </document-search>
</template>

<script>
import DocumentSearch from '@/components/search/document-search.vue'

export default {
  name: 'supplier-search',
  components: { DocumentSearch },
  props: {
    country: {
      type: String,
      default: undefined
    },
    placeholder: {
      type: String,
      default: 'Start typing supplier name...'
    },
    user: {
      type: Number,
      default: undefined
    },
  },
  methods: {
    itemText(item) {
      if (item?.name) {
        return `${item.name} - ${item.city}`
      }
      return ''
    },
  }
}
</script>
