<template>
  <qtm-content-block v-bind="$attrs" collapsible title="Order Info">
    <v-row>
      <v-col class="pr-16" cols="6">
        <order-delivery-info :address="address" label="Expected" :order="order" />
        <div class="mt-3" v-text="order.comment" />
      </v-col>

      <v-divider class="my-3" vertical />

      <v-col class="pl-16" cols="6">
        <div class="mb-3 qtm-h3">
          <span class="text-mid-grey">
            Quote Due
          </span>
          {{ conciseDate(order.date_closes) }}
        </div>
        <div>
          <span class="qtm-h3">
            <span class="text-mid-grey">
              Order Owner
            </span>
            {{ order.owner.first_name }} {{ order.owner.last_name }}
          </span>
        </div>
      </v-col>
    </v-row>
  </qtm-content-block>
</template>

<script>
import { conciseDate } from '~/models/filters'
import OrderDeliveryInfo from '@/components/orders/order-delivery-info.vue'

export default {
  name: 'order-infor-summary',
  components: { OrderDeliveryInfo },
  props: {
    order: {
      type: Object,
      required: true,
    }
  },
  computed: {
    address() {
      const fields = ['address', 'city', 'province', 'postal_code']

      return fields.map(field => this.order[field]).join(' ')
    }
  },
  methods: {
    conciseDate(value) {
      return conciseDate(value)
    }
  }
}
</script>
