<template>
  <div class="align-center attachment-preview d-flex justify-space-between">
    <div class="d-flex">
      <div v-if="allowSelect" class="mr-3 text-center">
        <qtm-checkbox v-model="localValue" />
      </div>
      <div v-if="!dense" class="mr-3 text-center">
        <v-img
          v-if="isImage"
          covers
          class="preview-image"
          :alt="attachment.name"
          :src="attachment.url"
        />
        <v-icon v-else size="x-large" class="preview-image" color="secondary">
          mdi-file-document
        </v-icon>
      </div>
      <div class="text-left">
        <div>
          <a
            class="qtm-h4 text-decoration-none"
            :download="attachment.name"
            :href="attachment.url"
            target="_blank"
            v-text="attachment.name"
          />
          <slot name="name-append" />
        </div>
        <div v-if="!dense" class="qtm-body text-mid-dark-grey">
          {{ dateUploaded }}
        </div>
      </div>
    </div>
    <div class="text-no-wrap">
      <slot name="action" :attachment="attachment" />
      <qtm-icon-btn
        v-if="allowPreview"
        icon="mdi-eye"
        title="Preview"
        @click.prevent="$emit('preview', attachment)"
      />
      <delete-btn v-if="withDelete" @click="$emit('delete', attachment)" />
    </div>
  </div>
</template>

<script>
import { isoDate } from '~/models/filters'
import DeleteBtn from '@/components/delete-btn.vue'

export default {
  name: 'attachment-preview',
  components: { DeleteBtn },
  props: {
    allowPreview: {
      type: Boolean,
      default: false
    },
    allowSelect: {
      type: Boolean,
      default: false
    },
    attachment: {
      type: Object,
      required: true
    },
    dense: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
    withDelete: {
      type: Boolean,
      default: false
    },
  },
  emits: ['delete', 'preview', 'update:model-value'],
  computed: {
    dateUploaded() {
      return isoDate(this.attachment.date_uploaded)
    },
    isImage() {
      return /\.(jpeg|jpg|gif|png)$/.test(this.attachment.url)
    },
    localValue: {
      get() {
        return this.modelValue.includes(this.attachment.id)
      },
      set(value) {
        if (value) {
          const attachments = this.modelValue

          attachments.push(this.attachment.id)
          this.$emit('update:model-value', attachments)
        }
        else {
          this.$emit('update:model-value', this.modelValue.filter(attachmentId => attachmentId !== this.attachment.id))
        }
      }
    }
  },
}
</script>

<style lang="scss" scoped>
$height: 60px;
$width: 60px;

.attachment-preview {
  width: 100%;
}

.preview-image {
  display: inline-block;
  height: $height;
  width: $width;
}
</style>
