<template>
  <div class="bg-white">
    <v-alert v-if="loading" color="white" height="58" variant="outlined" />
    <v-alert v-else-if="projectAuthorities" type="success" variant="outlined">
      <div class="text-dark-grey">
        <b>Project Authority:</b> {{ projectAuthorities }}
      </div>
    </v-alert>
    <v-alert v-else type="error" variant="outlined">
      <div class="text-dark-grey">
        <b>No project authority:</b> Go to
        <nuxt-link :to="{ name: 'projects-id', params: { id: project.id } }">
          project page
        </nuxt-link>
        to set a project authority
      </div>
    </v-alert>
  </div>
</template>

<script>
export default {
  name: 'project-authority',
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      team: [],
    }
  },
  computed: {
    projectAuthorities() {
      return this.team.filter(entry => entry.role === 3)
        .map(entry => `${entry.user.first_name} ${entry.user.last_name}`).join(', ')
    },
  },
  mounted() {
    this.fetchTeam()
  },
  methods: {
    async fetchTeam() {
      this.loading = true
      try {
        this.team = await this.$api.v1.jobsites.team(this.project.id)
      }
      catch (error) {
        this.$error.report(error)
      }
      this.loading = false
    },
  },
}
</script>
