import { sentry } from '@/plugins/sentry'

const mutations = {
  setUser(state, user) {
    state.user = user
    sentry.setUser(user)
  },

  updateUser(state, changes) {
    Object.assign(state.user, changes)
    sentry.setUser(state.user)
  },

  setAuthToken(state, token) {
    if (token) {
      localStorage.setItem('authToken', token)
    }
    else {
      localStorage.removeItem('authToken')
    }
    state.authToken = token
  }
}

export default mutations
