<template>
  <v-row>
    <v-col cols="12" :lg="lgCols">
      <table class="order-freight-charges">
        <thead class="bg-background qtm-h4 text-secondary text-no-wrap">
          <tr>
            <th v-for="header in headers" :key="header" class="pa-2" v-text="header" />
          </tr>
        </thead>
        <tbody>
          <tr>
            <editable-cell
              v-if="!hideCharges"
              component="qtm-number-field"
              density="compact"
              :error="!!errors.deliveryCharge"
              hide-details
              :maxlength="PRICE_MAX_LENGTH"
              :model-value="v$.deliveryCharge.$model"
              prepend-inner-icon="mdi-currency-usd"
              @update:model-value="$emit('update:delivery-charge', $event)"
            />
            <editable-cell
              v-if="!hideCharges"
              component="qtm-number-field"
              density="compact"
              :error="!!errors.pickupCharge"
              hide-details
              :maxlength="PRICE_MAX_LENGTH"
              :model-value="v$.pickupCharge.$model"
              prepend-inner-icon="mdi-currency-usd"
              @update:model-value="$emit('update:pickup-charge', $event)"
            />
            <editable-cell
              v-if="showTax"
              :component="TaxSelect"
              density="compact"
              :model-value="tax"
              :taxes="taxes"
              @update:model-value="$emit('update:tax', $event)"
            />
            <editable-cell
              v-if="withCostCode"
              :component="CostCodeSelect"
              :cost-codes="costCodes"
              density="compact"
              :error="!!errors.costCode"
              :force-select="forceCostCodeSelect"
              :model-value="costCode"
              @update:model-value="$emit('update:cost-code', $event)"
            >
              <template v-slot:selection="{ item: costCodeItem }">
                <div v-if="costCodeItem" v-text="costCodeItem.value" />
              </template>
            </editable-cell>
          </tr>
        </tbody>
      </table>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { decimal, minValue, requiredIf } from '@vuelidate/validators'
import CostCodeSelect from '@/components/cost-codes/cost-code-select.vue'
import EditableCell from '@/components/editable-cell.vue'
import TaxSelect from '@/components/orders/tax-select.vue'
import { PRICE_MAX_LENGTH } from '@/constants'
import { decimalPlaces } from '@/validators'
import useValidation from '@/composables/validation'

export interface Props {
  costCode?: string
  costCodeRequired?: boolean
  costCodes?: any[]
  deliveryCharge?: number | string
  forceCostCodeSelect?: boolean
  hideCharges?: boolean
  pickupCharge?: number | string
  tax?: number
  taxes?: any[]
  withCostCode?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  costCode: '',
  costCodeRequired: false,
  costCodes: () => [],
  deliveryCharge: undefined,
  forceCostCodeSelect: false,
  hideCharges: false,
  pickupCharge: undefined,
  tax: undefined,
  taxes: () => [],
  withCostCode: false,
})
defineEmits(['update:cost-code', 'update:delivery-charge', 'update:pickup-charge', 'update:tax'])

const { errors, isValid, v$ } = useValidation({
  rules: {
    costCode: {
      required: requiredIf(() => props.costCodeRequired),
      validChoice: function (costCode: any): boolean {
        return !costCode || !props.forceCostCodeSelect || props.costCodes.some((code) => code.cost_code === costCode)
      },
    },
    deliveryCharge: {
      decimal,
      decimalPlaces: decimalPlaces(2),
      minValue: minValue(0),
    },
    pickupCharge: {
      decimal,
      decimalPlaces: decimalPlaces(2),
      minValue: minValue(0),
    },
  },
  state: toRef(props),
})

const showTax = computed(() => props.taxes.length && props.withCostCode)
const headers = computed(() => [
  { label: 'Delivery Charge', show: !props.hideCharges },
  { label: 'Pickup Charge', show: !props.hideCharges },
  { label: 'Tax', show: showTax.value },
  { label: 'Freight Cost Code', show: props.withCostCode },
].filter(header => header.show).map(header => header.label))

const lgCols = computed(() => (headers.value.length < 3 ? '8' : '12'))

defineExpose({ isValid })
</script>

<style lang="scss">
.order-freight-charges {
  border-collapse: separate;
  border-spacing: 0;
  text-align: left;
  width: 100%;

  td, th {
    width: 25%;
  }

  td {
    border: 1px solid rgb(var(--v-theme-mid-light-grey));
  }

  th {
    border: 1px solid rgb(var(--v-theme-light-grey));
  }
}
</style>
