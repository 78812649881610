<template>
  <div class="d-flex flex-column align-center justify-space-evenly">
    <v-spacer />
    <div class="qtm-h1" :class="color" v-text="status" />
    <v-spacer />
    <div
      v-if="comments"
      class="text-caption w-100"
      :class="{'compact-supplier-comment':compact, 'large-supplier-comment': !compact}"
    >
      Supplier comment: {{ comments }}
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  comments: {
    type: String,
    default: null
  },
  compact: {
    type: Boolean,
    default: false
  },
  response: {
    type: String,
    default: ''
  }
})

const color = computed(() => {
  if (props.response === 'declined') {
    return 'text-error'
  }
  return 'text-primary'
})

const status = computed(() => {
  if (props.response === 'declined') {
    return 'DECLINED TO QUOTE'
  }
  if (props.response === 'price_given') {
    return 'QUOTE RECEIVED'
  }
  return 'WAITING FOR QUOTE'
})
</script>

<style scoped>
.compact-supplier-comment {
  display: -webkit-box;
  padding-left: 4px;
  padding-right: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.large-supplier-comment {
  padding: 12px;
  text-align: left;
}
</style>
