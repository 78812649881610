<template>
  <v-btn :color="status.color" variant="text" @click="showDialog = true">
    <v-icon>
      {{ status.icon }}
    </v-icon>
    Accounting Sync:
    {{ status.message }}
    <sync-dialog
      v-model:accounting-success="po.latest_change_order.accounting_success"
      v-model:show-dialog="showDialog"
      :accounting-message="po.latest_change_order.accounting_message"
      :order-owner="orderOwner"
      :order-reference-name="order.reference_name"
      :po-id="po.id"
      :po-number="po.po_number"
      :revision-id="po.latest_change_order.id"
      :revision-number="po.change_orders.length"
    />
  </v-btn>
</template>

<script>
import SyncDialog from '@/components/purchase-orders/sync-dialog.vue'

export default {
  name: 'sync-status',
  components: { SyncDialog },
  props: {
    order: {
      type: Object,
      required: true
    },
    po: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      showDialog: false,
    }
  },
  computed: {
    orderOwner() {
      return `${this.order.owner?.first_name} ${this.order.owner?.last_name}`
    },
    status() {
      if (this.success === null) {
        return {
          color: 'primary',
          icon: 'mdi-update',
          message: 'Pending',
        }
      }
      if (this.success) {
        return {
          color: 'interactive',
          icon: 'mdi-sync',
          message: 'Successful',
        }
      }
      return {
        color: 'error',
        icon: 'mdi-sync-alert',
        message: 'Error',
      }
    },
    success() {
      return this.po.latest_change_order.accounting_success
    },
  }
}
</script>
