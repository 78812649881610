<template>
  <tr>
    <td v-text="item.item_number" />
    <td class="text-left">
      <div v-text="item.description" />
      <div style="opacity: 0.5" v-text="item.comment" />
      <div v-if="bom">
        <nuxt-link
          class="text-decoration-none"
          :to="{ name: 'projects-id-boms-bomId', params: { id: bom.project, bomId: bom.bill_of_materials } }"
        >
          <v-icon icon="mdi-link" />
          {{ bom.bill_of_materials_title }}
        </nuxt-link>
      </div>
      <div v-if="withReceived && partialReceipt" class="text-primary">
        Incomplete
      </div>
    </td>
    <td v-if="rental">
      {{ item.rental_duration }} {{ item.rental_duration_unit }}
    </td>
    <td v-else>
      -
    </td>
    <td>
      <div class="ml-3 text-left">
        <div v-text="Number(item.quantity)" />
        <div class="text-mid-light-grey text-uppercase" v-text="item.unit" />
      </div>
    </td>
    <td v-if="withReceived">
      <div class="ml-3 text-left">
        <div :class="{ 'text-primary': partialReceipt }" v-text="Number(item.received_quantity)" />
        <div class="text-mid-light-grey text-uppercase" v-text="item.unit" />
      </div>
    </td>
    <td class="qtm-currency text-no-wrap">
      <span :title="hasRentalPrice ? `per ${item.rental_price.per}` : undefined">
        {{ decimal(unitPrice) }}
      </span>
    </td>
    <td class="qtm-currency text-no-wrap">
      {{ decimal(subTotal) }}
    </td>
    <td v-text="item.cost_code" />
  </tr>
</template>

<script>
import { decimal } from '~/models/filters'

export default {
  name: 'purchase-order-item-row',
  props: {
    boms: {
      type: Array,
      default: () => []
    },
    item: {
      type: Object,
      required: true
    },
    withReceived: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    bom() {
      return this.boms.find(bom => (
        bom.purchase_order_item?.id === this.item.id || bom.order_reference_identifier === this.item.internal_identifier
      ))
    },
    hasRentalPrice() {
      return this.rental && this.item.rental_price
    },
    partialReceipt() {
      return Number(this.item.quantity) !== Number(this.item.received_quantity)
    },
    rental() {
      return !!this.item.rental_duration
    },
    subTotal() {
      const price = (this.hasRentalPrice ? this.item.rental_price.price : this.item.unit_price) || 0

      return this.item.quantity * price
    },
    unitPrice() {
      return this.hasRentalPrice ? this.item.rental_price.rate : this.item.unit_price
    },
  },
  methods: {
    decimal(value) {
      return decimal(value)
    },
  }
}
</script>
