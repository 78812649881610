<template>
  <confirmation-dialog
    v-bind="$attrs"
    :loading="loading"
    ok-button-text="Yes"
    title="Manually clear sync error?"
    @confirm="clear"
    @update:model-value="$emit('update:model-value', $event)"
  >
    Clear this error without pushing to accounting?
  </confirmation-dialog>
</template>

<script setup lang="ts">
import ConfirmationDialog from '@/components/confirmation-dialog.vue'

export interface Props {
  endpoint?: 'expenses' | 'purchaseOrders' | 'receipts'
  objectId?: number
}

const props = withDefaults(defineProps<Props>(), {
  endpoint: 'purchaseOrders',
  objectId: undefined
})

const emit = defineEmits(['cleared', 'update:model-value'])
const loading = ref(false)
const nuxtApp = useNuxtApp()
const clear = async () => {
  loading.value = true
  try {
    await nuxtApp.$api.v1[props.endpoint].clearAccountingError(props.objectId)
    emit('cleared')
    emit('update:model-value', false)
  }
  catch (error) {
    nuxtApp.$error.report(error)
  }
  loading.value = false
}
</script>
