export default {
  setBomOrder(state, bomOrder) {
    state.bomOrder = bomOrder
  },
  setCsdOrder(state, csdOrder) {
    state.csdOrder = csdOrder
  },
  setRequisition(state, requisition) {
    state.requisition = requisition
  },
}
