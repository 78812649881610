<template>
  <qtm-content-block collapsible title="Change Orders">
    <div v-if="!po.change_orders || po.change_orders.length < 2">
      No change orders have been issued for this PO.
    </div>
    <div v-else class="change-order-section">
      <div v-for="(changeOrder, index) in po.change_orders" :key="index">
        <div class="qtm-body-small">
          <span v-if="index === 0">Initial Purchase Order (Purchase Order Revision 0)</span>
          <span v-else>Change Order {{ index }} (Purchase Order Revision {{ index }})</span>
          -
          {{ dateTime(changeOrder.timestamp) }}
        </div>

        <change-order-table :change-order="changeOrder" :show-tax="anyTax" />
      </div>
    </div>
  </qtm-content-block>
</template>

<script>
import { dateTime } from '~/models/filters'
import ChangeOrderTable from '@/components/purchase-orders/change-order-table.vue'

export default {
  name: 'change-orders-list',
  components: { ChangeOrderTable },
  props: {
    po: {
      type: Object,
      required: true,
    },
  },
  computed: {
    anyTax() {
      return this.po.change_orders.some(
        changeOrder => changeOrder.displayable_freight_tax || changeOrder.items.some(item => item.displayable_tax)
      )
    },
  },
  methods: {
    dateTime(value) {
      return dateTime(value)
    },
  }
}
</script>

<style lang="scss" scoped>
.change-order-section > *:not(:first-child) {
  margin-top: 1.5rem;
}
</style>
