<template>
  <v-container class="quote-form">
    <qtm-content-block collapsible>
      <quote-attachments :quote="quote" :order="order">
        <template v-slot:action="{ attachment }">
          <attachment-analyzer
            v-model:items="quote.skus"
            v-model:supplier-reference="quote.supplier_reference"
            :attachment="attachment"
            :company="orderCompany"
            :units="units"
            @analyzing="$emit('preview', $event)"
          />
          <qtm-icon-btn
            icon="mdi-eye"
            title="Preview"
            @click.stop.prevent="$emit('preview', attachment)"
          />
        </template>
      </quote-attachments>
    </qtm-content-block>
    <quote-vendor-info
      ref="quoteVendorInfo"
      v-model:contact="quote.salesperson"
      :order="order"
      :quote="quote"
      :vendor="quote.vendor"
    />
    <quote-order-info
      v-model:supplier-reference="quote.supplier_reference"
      v-model:valid-until="quote.valid_until"
      :order="order"
      :supplier-id="quote.vendor.id"
    />
    <quote-delivery ref="quoteDelivery" :quote="quote" :order="order" />
    <quote-scope-of-work :quote="quote" :order="order" />
    <quote-product-information
      ref="quoteProductInformation"
      :quote="quote"
      :order="order"
      :taxes="taxes"
      :units="units"
    />
    <quote-comment :quote="quote" :order="order" />
    <quote-status v-model="quote.response_received" />
    <slot name="submit-btn" />
  </v-container>
</template>

<script>
import AttachmentAnalyzer from '@/components/attachments/attachment-analyzer.vue'
import QuoteAttachments from '@/components/quotes/quote-attachments.vue'
import QuoteComment from '@/components/quotes/quote-comment.vue'
import QuoteDelivery from '@/components/quotes/quote-delivery.vue'
import QuoteProductInformation from '@/components/quotes/quote-product-information.vue'
import QuoteOrderInfo from '@/components/quotes/quote-order-info.vue'
import QuoteScopeOfWork from '@/components/quotes/quote-scope-of-work.vue'
import QuoteStatus from '@/components/quotes/quote-status.vue'
import QuoteVendorInfo from '@/components/quotes/quote-vendor-info.vue'
import useTaxes from '@/composables/taxes'
import useUnits from '@/composables/units'

export default {
  name: 'quote-form',
  components: {
    AttachmentAnalyzer,
    QuoteAttachments,
    QuoteComment,
    QuoteDelivery,
    QuoteProductInformation,
    QuoteOrderInfo,
    QuoteScopeOfWork,
    QuoteStatus,
    QuoteVendorInfo,
  },
  props: {
    quote: {
      type: Object,
      required: true
    },
    order: {
      type: Object,
      required: true
    },
  },
  emits: ['preview'],
  setup(props) {
    const { taxes } = useTaxes(props.order.jobsite?.company)
    const { units } = useUnits(props.order.jobsite?.company)

    return { taxes, units }
  },
  computed: {
    orderCompany() {
      return this.order.owner?.company
    },
  },
  methods: {
    clear() {
      this.$refs.quoteProductInformation.clear()
    },
    isValid() {
      const components = [
        this.$refs.quoteVendorInfo,
        this.$refs.quoteDelivery,
        this.$refs.quoteProductInformation,
      ]
      const validations = []
      let hasScrolled = false

      components.forEach(c => {
        const isValid = c.isValid()

        validations.push(isValid)
        if (!hasScrolled && !isValid) {
          c.$el.scrollIntoView({ behavior: 'smooth' })
          hasScrolled = true
        }
      })

      return validations.every(x => x)
    }
  }
}
</script>

<style lang="scss" scoped>
.quote-form {
  border: 1px solid rgb(var(--v-theme-light-grey));
  border-radius: 5px;
  margin-top: 1rem;
}

.quote-form > * {
  margin-bottom: 1rem;
}
</style>
