<template>
  <div v-if="itemsSet" class="quote-order-form">
    <order-attachments
      v-bind="attachmentAttrs"
      v-model="attachments"
      allow-preview
      analyze
      @preview="$emit('preview', $event)"
    >
      <template v-slot:preview-prepend>
        <div v-for="attachment in attachmentsToCopy" :key="attachment.id" class="py-2">
          <attachment-preview
            allow-preview
            :attachment="attachment"
            with-delete
            @delete="removeAttachmentToCopy(attachment)"
            @preview="$emit('preview', $event)"
          >
            <template v-slot:action>
              <attachment-analyzer
                v-model:items="items"
                v-model:supplier-reference="supplierReference"
                :attachment="attachment"
                :company="orderCompany"
                :units="units"
                @analyzing="$emit('preview', $event)"
                @update:items="itemsUpdated"
              />
            </template>
          </attachment-preview>
        </div>
      </template>
      <template v-slot:action="{ attachment }">
        <attachment-analyzer
          v-model:items="items"
          v-model:supplier-reference="supplierReference"
          :attachment="attachment"
          :company="orderCompany"
          :units="units"
          @analyzing="$emit('preview', $event)"
          @update:items="itemsUpdated"
        />
      </template>
    </order-attachments>

    <order-info ref="orderInfo" :full-width="fullWidth" :jobsite-required="false" :order="order" />

    <qtm-content-block v-if="invoice && order.is_invoice_approval" collapsible title="Invoice info">
      <invoice-information ref="invoiceInformation" :invoice="invoice" :order="order" />
    </qtm-content-block>

    <order-supplier
      ref="orderSupplier"
      v-model:contact="contact"
      v-model:supplier="supplier"
      :country="order.jobsite?.country"
      :user="order.owner?.id"
    >
      <qtm-btn v-if="contact" class="mt-4" tertiary @click="editContact = true">
        <v-icon class="mr-2" size="x-large">
          mdi-playlist-edit
        </v-icon>
        Edit Contact
        <edit-contact-dialog v-model="editContact" v-model:contact="contact" email-required :vendor="supplier" />
      </qtm-btn>
    </order-supplier>

    <quote-order-info
      v-if="!order.is_invoice_approval"
      v-model:supplier-reference="supplierReference"
      v-model:valid-until="validUntil"
      :full-width="fullWidth"
      :order="order"
      :supplier-id="supplier?.id"
    />

    <order-delivery ref="orderDelivery" :order="order" :validate-delivery-required="false" />

    <order-cart
      ref="orderCart"
      v-model:delivery-charge="deliveryCharge"
      v-model:freight-cost-code="order.freight_cost_code"
      v-model:freight-tax="order.freight_tax"
      v-model:items="items"
      v-model:pickup-charge="pickupCharge"
      :cost-codes="costCodes"
      :delivery-required="order.delivery_required"
      :force-cost-code-select="forceCostCodeSelect"
      order-by="sort_order"
      :taxes="taxes"
      :units="units"
      :validate="validateCart"
      validate-units
      with-prices
    >
      <div class="mt-6 text-right">
        <order-total-price-estimate
          :delivery-charge="deliveryCharge"
          :delivery-required="order.delivery_required"
          :freight-tax="order.freight_tax"
          :pickup-charge="pickupCharge"
          :skus="items"
          :model-value="totalPrice"
        />
      </div>
    </order-cart>

    <qtm-content-block collapsible title="Comment">
      <qtm-input-label label="Comment">
        <qtm-textarea
          v-model="comment"
          auto-grow
          placeholder="Comments from the supplier"
          rows="1"
        />
      </qtm-input-label>
    </qtm-content-block>

    <quote-status v-model="responseReceived" title="Quote Status & Submit">
      <div class="text-right">
        <qtm-btn :loading="loading" secondary @click="preparePreviewPo">
          <v-icon location="left">
            mdi-text-box-search-outline
          </v-icon>
          Preview PO
        </qtm-btn>
        <qtm-btn class="ml-2" :loading="loading" @click="submit(false)">
          Submit Changes
        </qtm-btn>
      </div>
    </quote-status>

    <preview-po-dialog
      v-model="previewPO"
      close-btn-text="Ok"
      :po-data="previewPoData"
      refresh-on-open
    />
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import AttachmentAnalyzer from '@/components/attachments/attachment-analyzer.vue'
import AttachmentPreview from '@/components/attachments/attachment-preview.vue'
import InvoiceInformation from '@/components/invoices/invoice-information.vue'
import EditContactDialog from '@/components/vendors/edit-contact-dialog.vue'
import OrderAttachments from '@/components/orders/order-attachments.vue'
import OrderCart from '@/components/orders/order-cart.vue'
import OrderDelivery from '@/components/orders/order-delivery.vue'
import OrderInfo from '@/components/orders/order-info.vue'
import OrderSupplier from '@/components/orders/order-supplier.vue'
import OrderTotalPriceEstimate from '@/components/orders/order-total-price-estimate.vue'
import PreviewPoDialog from '@/components/purchase-orders/preview-po-dialog.vue'
import QuoteOrderInfo from '@/components/quotes/quote-order-info.vue'
import QuoteStatus from '@/components/quotes/quote-status.vue'
import useCostCodes from '@/composables/cost-codes'
import useTaxes from '@/composables/taxes'
import useUnits from '@/composables/units'
import { INLINE_IMAGE_CATEGORY } from '@/constants'

export default {
  name: 'quote-order-form',
  components: {
    AttachmentAnalyzer,
    AttachmentPreview,
    InvoiceInformation,
    EditContactDialog,
    OrderAttachments,
    OrderCart,
    OrderDelivery,
    OrderInfo,
    OrderSupplier,
    OrderTotalPriceEstimate,
    PreviewPoDialog,
    QuoteOrderInfo,
    QuoteStatus,
  },
  props: {
    fullWidth: {
      type: Boolean,
      default: false
    },
    order: {
      type: Object,
      required: true
    },
  },
  emits: ['preview'],
  setup(props) {
    const company = props.order.jobsite?.company || props.order.owner.company.id
    const { costCodes, fetchCostCodes } = useCostCodes(props.order.jobsite?.id)
    const { fetchTaxes, taxes } = useTaxes(company)
    const { fetchUnits, units } = useUnits(company)

    watch(() => props.order.jobsite, (newJobsite, oldJobsite) => {
      if (newJobsite) {
        if (newJobsite.company !== oldJobsite?.company) {
          fetchTaxes(newJobsite.company)
          fetchUnits(newJobsite.company)
        }

        fetchCostCodes(newJobsite.id)
      }
      else {
        costCodes.value = []
        taxes.value = []
        units.value = []
      }
    })

    watch(() => props.order.owner, (newOwner, oldOwner) => {
      if (props.order.jobsite) {
        return
      }

      if (newOwner) {
        if (!oldOwner || newOwner.company.id !== oldOwner.company.id) {
          fetchTaxes(newOwner.company.id)
          fetchUnits(newOwner.company.id)
        }
      }
      else {
        taxes.value = []
        units.value = []
      }
    })

    return {
      costCodes,
      taxes,
      units,
    }
  },
  data() {
    return {
      attachments: [],
      attachmentsToCopy: [],
      comment: this.order.comment,
      contact: null,
      deliveryCharge: null,
      editContact: false,
      forceCartValidation: false,
      invoice: {
        id: undefined,
        attachments: [],
        invoice_number: '',
        date_issued: null,
        date_due: null,
      },
      items: [],
      itemsSet: false,
      loading: false,
      pickupCharge: null,
      previewPO: false,
      previewPoData: undefined,
      responseReceived: '',
      supplier: null,
      supplierReference: '',
      totalPrice: 0,
      validUntil: null,
    }
  },
  computed: {
    attachmentAttrs() {
      const isInvoice = this.invoice && this.order.is_invoice_approval

      return {
        contentType: isInvoice ? 'rfqs.invoice' : 'rfqs.quote',
        objectId: isInvoice ? this.invoice.id : this.quoteId,
      }
    },
    forceCostCodeSelect() {
      return !!this.order.jobsite?.accounting_id
    },
    orderCompany() {
      return this.order.owner?.company
    },
    quoteId() {
      return this.order.quotes[0]?.id
    },
    validateCart() {
      return this.forceCartValidation || this.responseReceived === 'price given'
    },
  },
  watch: {
    'order.delivery_required': {
      handler() {
        if (!this.order.delivery_required) {
          this.deliveryCharge = null
          this.pickupCharge = null
        }
      }
    }
  },
  mounted() {
    this.setItems()
    this.itemsSet = true
  },
  methods: {
    async fetchEmailAttachments() {
      this.loading = true
      try {
        const emails = await this.$api.v1.notifications.email.listByRfq(this.order, { emailType: 'attachments' })

        this.attachmentsToCopy = emails.flatMap(email => (
          email.attachments.filter(attachment => attachment.category !== INLINE_IMAGE_CATEGORY)
        ))
      }
      catch (error) {
        this.$error.report(error)
      }
      this.loading = false
    },
    getRFQSKUField(quotesku, field) {
      if (quotesku.rfqsku) {
        return quotesku.rfqsku[field]
      }
      return undefined
    },
    async isValid({ strict = false } = {}) {
      if (strict) {
        this.forceCartValidation = true
        await this.$nextTick()
      }

      const components = [
        this.$refs.orderInfo,
        this.$refs.invoiceInformation,
        this.$refs.orderSupplier,
        this.$refs.orderCart,
        this.$refs.orderDelivery,
      ]
      const componentValidations = []
      let hasScrolled = false

      components.filter(c => c).forEach(c => {
        const isValid = c.isValid()

        componentValidations.push(isValid)
        if (!hasScrolled && !isValid) {
          c.$el.scrollIntoView({ behavior: 'smooth' })
          hasScrolled = true
        }
      })

      const valid = componentValidations.every(isValid => isValid)

      if (!valid) {
        this.$toast.error('Please correct the errors')
      }

      this.forceCartValidation = false

      return valid
    },
    async preparePreviewPo() {
      if (!await this.isValid({ strict: true })) {
        return
      }

      this.previewPoData = {
        change_order: {
          delivery_charge: this.deliveryCharge,
          freight_cost_code: this.order.freight_cost_code,
          pickup_charge: this.pickupCharge,
          poskus: this.items,
        },
        comments: this.comment,
        customer_pickup: this.order.customer_pickup,
        delivery_date: this.order.delivery_date?.unix() ?? null,
        delivery_time: this.order.delivery_time === '-' ? 'sp' : this.order.delivery_time,
        jobsite: this.order.jobsite?.id || null,
        location: this.order.location,
        owner: this.order.owner.id,
        site_contact: this.order.site_contact,
        salesperson_email: this.contact?.email,
        salesperson_first_name: this.contact?.first_name,
        salesperson_last_name: this.contact?.last_name,
        salesperson_phone_extension: this.contact?.phone_extension,
        salesperson_phone_number: this.contact?.phone_number,
        vendor: this.supplier?.id,
      }
      this.previewPO = true
    },
    removeAttachmentToCopy(attachment) {
      this.attachmentsToCopy = this.attachmentsToCopy.filter(a => a.id !== attachment.id)
    },
    setItems() {
      const quote = this.order.quotes[0]

      if (quote) {
        this.items = quote.skus.map(sku => ({
          ...sku,
          cost_code: this.getRFQSKUField(sku, 'cost_code'),
          has_rental_duration: this.getRFQSKUField(sku, 'has_rental_duration'),
          sku: this.getRFQSKUField(sku, 'sku'),
        }))

        this.attachments = quote.attachments
        this.comment = quote.comments
        this.contact = quote.salesperson
        this.deliveryCharge = quote.delivery_charge
        this.pickupCharge = quote.pickup_charge
        this.responseReceived = quote.response_received
        this.supplier = quote.vendor
        this.supplierReference = quote.supplier_reference
        this.totalPrice = quote.total_price
        this.validUntil = quote.valid_until
      }
      else {
        this.fetchEmailAttachments()
        this.items = this.order.skus
      }

      if (this.order.invoices.length) {
        this.invoice = cloneDeep(this.order.invoices[0])

        if (this.invoice && this.order.is_invoice_approval) {
          this.attachments = this.invoice.attachments
        }
      }
    },
    async submit(throwError = false) {
      this.$refs.orderCart.clear()
      await this.$nextTick()
      if (!await this.isValid()) {
        return
      }

      if (this.contact && typeof this.contact.id !== 'number') {
        this.contact = await this.$api.v1.vendors.addContact({
          ...this.contact,
          id: undefined,
          organization: this.supplier.id,
        })
      }

      const data = {
        comment: this.comment,
        contact: this.contact?.id ?? null,
        customer_pickup: this.order.customer_pickup,
        delivery_charge: this.deliveryCharge,
        delivery_date: this.order.delivery_date?.unix() ?? null,
        delivery_location: this.order.delivery_location,
        delivery_time: this.order.delivery_time === '-' ? undefined : this.order.delivery_time,
        freight_cost_code: this.order.freight_cost_code,
        freight_tax: this.order.freight_tax,
        items: this.items,
        jobsite: this.order.jobsite?.id,
        location: this.order.location,
        owner: this.order.owner?.id,
        pickup_charge: this.pickupCharge,
        quote_attachments: this.attachments.map(attachment => attachment.id),
        reference_name: this.order.reference_name,
        response_received: this.responseReceived,
        site_contact: this.order.site_contact?.id ?? null,
        submitted_by: this.order.owner?.id,
        supplier: this.supplier?.id ?? null,
        supplier_reference: this.supplierReference,
        valid_until: this.validUntil?.unix() ?? null,
      }

      this.loading = true
      try {
        const updatedOrder = await this.$api.v1.rfqs.updateDraft(this.order.id, data)
        let attachmentObject = updatedOrder.quotes[0]
        let attachmentContentType = 'quote'

        if (this.order.is_invoice_approval) {
          const invoiceData = {
            attachments: this.invoice.attachments.map(attachment => attachment.id),
            invoice_number: this.invoice.invoice_number,
            date_due: this.invoice.date_due?.unix() ?? null,
            date_issued: this.invoice.date_issued?.unix() ?? null,
          }

          if (this.invoice.id) {
            await this.$api.v1.invoices.patch(this.invoice.id, invoiceData)
          }
          else {
            const invoice = await this.$api.v1.invoices.create({ ...invoiceData, order: this.order.id })

            this.invoice.id = invoice.id
          }

          attachmentObject = this.invoice
          attachmentContentType = 'invoice'
        }

        if (this.attachmentsToCopy.length) {
          Promise.all(this.attachmentsToCopy.map(attachment => (
            this.$api.v1.attachments
              .copy(attachment.id, {
                content_type: attachmentContentType,
                object_id: attachmentObject.id,
              })
              .then(newAttachment => this.attachments.push(newAttachment))
          ))).catch(() => this.$toast.error('Failed to copy all email attachments'))
        }

        this.attachmentsToCopy = []

        this.$store.commit('admin/updateOrder', updatedOrder)
        this.$toast.success('Changes submitted')
      }
      catch (error) {
        if (throwError) {
          this.loading = false
          throw error
        }
        else {
          this.$error.report(error)
        }
      }
      this.loading = false
    },
    itemsUpdated() {
      this.$refs.orderCart.$el.scrollIntoView({ behavior: 'smooth' })
    },
  }
}
</script>

<style lang="scss" scoped>
.quote-order-form > * {
  margin-bottom: 1rem;
}
</style>
