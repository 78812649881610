import store from '~/store'
import { api } from '~/plugins/materials-api'

export default defineNuxtRouteMiddleware(async (to) => {
  if (to.meta.layout === 'logged-out-fullscreen') {
    return null
  }

  if (store.getters['auth/isAuthenticated']) {
    return null
  }

  const loadedAuthToken = localStorage.getItem('authToken')
  if (!loadedAuthToken) {
    return navigateTo({ name: 'login', query: { next: to.fullPath } })
  }

  store.commit('auth/setAuthToken', loadedAuthToken)

  try {
    // TODO(ch4733) move to v1 endpoint
    const user = await api.users.current.get()
    store.commit('auth/setUser', user)
  }
  catch (error) {
    return navigateTo({ name: 'login', query: { next: to.fullPath } })
  }

  return null
})
