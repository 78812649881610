import moment from 'moment'
import { UNIT_PRICE_DECIMAL_PLACES } from '@/constants'

function formatDate(date, formatStr) {
  if (date && date.format) {
    return date.format(formatStr)
  }
  if (date) {
    return moment.unix(date).format(formatStr)
  }
  return '—'
}

export const dateFormat = 'MMM D'
export const dateTimeFormat = `${dateFormat}, h:mm A`
export const dateYearFormat = 'MMM D, YYYY'
export const isoDateFormat = 'YYYY-MM-DD'
export const onlyTimeFormat = 'h:mm A'

export const conciseDate = (date) => {
  return formatDate(date, dateFormat)
}

export const currency = (value, decimalPlaces = 2) => {
  if (value == null) {
    return '—'
  }
  return new Intl.NumberFormat('en-CA', {
    style: 'currency',
    currency: 'CAD',
    maximumFractionDigits: decimalPlaces,
  }).format(value)
}

export const dateMonth = (date) => {
  return formatDate(date, dateFormat)
}

export const dateTime = (date) => {
  return formatDate(date, dateTimeFormat)
}

export const dateYear = (date) => {
  return formatDate(date, dateYearFormat)
}

export const decimal = (value, { maximumFractionDigits } = { maximumFractionDigits: UNIT_PRICE_DECIMAL_PLACES }) => {
  return new Intl.NumberFormat('en-CA', {
    style: 'decimal',
    maximumFractionDigits,
    minimumFractionDigits: 2,
    roundingMode: 'halfEven',
  }).format(value)
}

export const fromNow = (date) => {
  if (date && date.fromNow) {
    return date.fromNow()
  }
  if (date) {
    return moment.unix(date).fromNow()
  }
  return '—'
}

export const timeOrDateTime = (date) => {
  if (date) {
    const format = moment.unix(date).isSame(new Date(), 'day') ? onlyTimeFormat : dateTimeFormat
    return formatDate(date, format)
  }
  return '—'
}

export const isoDate = (date) => {
  return formatDate(date, isoDateFormat)
}

export const monthDay = (date) => {
  return formatDate(date, dateFormat)
}

export const objectDeliveryDate = (subject) => {
  if (subject && subject.delivery_date && subject.delivery_date.format) {
    if (subject.delivery_time === 'sp') {
      return subject.delivery_date.format(dateTimeFormat)
    }

    const format = `${dateFormat} [(${subject.delivery_time === 'am' ? 'Morning' : 'Afternoon'})]`

    return subject.delivery_date.format(format)
  }

  return '—'
}
