<template>
  <qtm-content-block collapsible title="Order Items">
    <template v-slot:title:append>
      <slot name="title:append" />
    </template>
    <template v-slot:top-right>
      <slot name="top-right" />
    </template>
    <div v-if="items.length" class="mb-4">
      <span class="font-weight-bold text-secondary">
        {{ items.length }} total
      </span>
      ({{ purchaseItems.length }} purchase<span v-if="purchaseItems.length > 1">s</span>,
      {{ rentalItems.length }} rental<span v-if="rentalItems.length > 1">s</span>)
    </div>

    <v-alert
      v-if="error"
      border="start"
      class="mb-6"
      icon="mdi-information-outline"
      type="error"
      variant="outlined"
    >
      <span v-if="hasItems">
        Please make sure you complete all the necessary information.
      </span>
      <span v-else>
        Please add at least 1 order item.
      </span>
    </v-alert>

    <slot name="items" :purchase-items="purchaseItems" :rental-items="rentalItems">
      <cart-items-table
        v-if="purchaseItems.length"
        ref="purchaseCart"
        class="mr-n4"
        :cost-codes="costCodes"
        data-test="purchase-items"
        :force-cost-code-select="forceCostCodeSelect"
        :item-number-field="itemNumberField"
        :items="purchaseItems"
        :no-drag-and-drop="noDragAndDrop"
        :require-cost-codes="requireCostCodes"
        :taxes="taxes"
        :units="units"
        :validate-units="validateUnits"
        :with-cost-codes="withCostCodes"
        :with-prices="withPrices"
        @cost-code-changed="updateCostCodes"
        @remove="removeItem"
        @sort:items="handleItemSort(purchaseItems, $event)"
        @tax-changed="updateDefaultTax"
      />
      <div v-if="canAddItems">
        <qtm-btn
          class="mb-6 ml-n3 mt-4 px-1"
          data-test="add-purchase-item-btn"
          tertiary
          @click="addPurchaseItem"
        >
          <v-icon size="large" location="left">
            mdi-plus
          </v-icon>
          Add Purchase Item
        </qtm-btn>
      </div>
      <div v-else-if="purchaseItems.length" class="mb-6" />

      <cart-items-table
        v-if="rentalItems.length"
        ref="rentalCart"
        class="mr-n4"
        :cost-codes="costCodes"
        data-test="rental-items"
        :force-cost-code-select="forceCostCodeSelect"
        :item-number-field="itemNumberField"
        :items="rentalItems"
        :no-drag-and-drop="noDragAndDrop"
        rental
        :require-cost-codes="requireCostCodes"
        :taxes="taxes"
        :units="units"
        :validate-units="validateUnits"
        :with-cost-codes="withCostCodes"
        :with-prices="withPrices"
        @cost-code-changed="updateCostCodes"
        @remove="removeItem"
        @sort:items="handleItemSort(rentalItems, $event)"
        @tax-changed="updateDefaultTax"
      />
      <div v-if="canAddItems">
        <qtm-btn class="ml-n3 mt-4 px-1" data-test="add-rental-item-btn" tertiary @click="addRentalItem">
          <v-icon size="large" location="left">
            mdi-plus
          </v-icon>
          Add Rental Item
        </qtm-btn>
      </div>
    </slot>

    <slot name="items-append" />

    <order-freight-charges
      v-if="deliveryRequired"
      ref="orderFreightCharges"
      class="mt-8"
      :cost-code="freightCostCode"
      :cost-code-required="freightCostCodeRequired"
      :cost-codes="costCodes"
      :delivery-charge="deliveryCharge"
      :force-cost-code-select="forceCostCodeSelect"
      :hide-charges="!withPrices"
      :pickup-charge="pickupCharge"
      :tax="freightTax"
      :taxes="taxes"
      :with-cost-code="withCostCodes"
      @update:cost-code="$emit('update:freight-cost-code', $event)"
      @update:delivery-charge="$emit('update:delivery-charge', $event)"
      @update:pickup-charge="$emit('update:pickup-charge', $event)"
      @update:tax="$emit('update:freight-tax', $event)"
    />

    <slot />
  </qtm-content-block>
</template>

<script>
import { mapGetters } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import useVuelidate from '@vuelidate/core'
import CartItemsTable from '@/components/orders/cart-items-table.vue'
import OrderFreightCharges from '@/components/orders/order-freight-charges.vue'

const itemDefaults = {
  comment: '',
  cost_code: '',
  day_rate: null,
  description: '',
  has_rental_duration: false,
  month_rate: null,
  quantity: 1,
  reference_identifier: undefined,
  rental_duration: null,
  rental_duration_unit: null,
  unit: 'Each',
  unit_price: null,
  week_rate: null,
}

export default {
  name: 'order-cart',
  components: { CartItemsTable, OrderFreightCharges },
  props: {
    costCodes: {
      type: Array,
      default: () => []
    },
    deliveryCharge: {
      type: [Number, String],
      default: undefined
    },
    deliveryRequired: {
      type: Boolean,
      default: false
    },
    forceCostCodeSelect: {
      type: Boolean,
      default: false
    },
    freightCostCode: {
      type: String,
      default: ''
    },
    freightCostCodeRequired: {
      type: Boolean,
      default: false
    },
    freightTax: {
      type: Number,
      default: undefined
    },
    itemDefaults: {
      type: Object,
      default: () => itemDefaults
    },
    itemNumberField: {
      type: String,
      default: 'index'
    },
    items: {
      type: Array,
      required: true
    },
    noDragAndDrop: {
      type: Boolean,
      default: false
    },
    orderBy: {
      type: String,
      default: undefined
    },
    pickupCharge: {
      type: [Number, String],
      default: undefined
    },
    requireCostCodes: {
      type: Boolean,
      default: false
    },
    restrictAdd: {
      type: Boolean,
      default: false
    },
    taxes: {
      type: Array,
      default: () => []
    },
    units: {
      type: Array,
      default: () => []
    },
    validate: {
      type: Boolean,
      default: true
    },
    validateUnits: {
      type: Boolean,
      default: true
    },
    withCostCodes: {
      type: Boolean,
      default: true
    },
    withPrices: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:delivery-charge',
    'update:freight-cost-code',
    'update:freight-tax',
    'update:items',
    'update:pickup-charge',
  ],
  setup() {
    const v$ = useVuelidate()

    return { v$ }
  },
  validations: {
    items() {
      return this.hasItems
    },
  },
  data() {
    return {
      costCode: undefined,
      defaultTax: undefined,
      error: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['canCreatePO']),
    canAddItems() {
      return !this.restrictAdd || this.canCreatePO
    },
    hasItems() {
      return this.purchaseItems.length || this.rentalItems.length
    },
    purchaseItems() {
      return this.sortedItems.filter(item => !item.rental_duration_unit)
    },
    rentalItems() {
      return this.sortedItems.filter(item => item.rental_duration_unit)
    },
    sortedItems() {
      const purchaseItems = this.items.filter(item => !item.rental_duration_unit)
      const rentalItems = this.items.filter(item => item.rental_duration_unit)

      if (this.orderBy) {
        purchaseItems.sort((a, b) => a[this.orderBy] - b[this.orderBy])
        rentalItems.sort((a, b) => a[this.orderBy] - b[this.orderBy])
      }

      const items = purchaseItems.concat(rentalItems)

      items.forEach((item, index) => {
        item.index = index + 1
      })

      return items
    },
  },
  methods: {
    addItem(rental = false) {
      const item = {
        ...itemDefaults,
        cost_code: this.costCode,
        has_rental_duration: rental,
        id: uuidv4(),
        rental_duration: rental ? 1 : null,
        rental_duration_unit: rental ? 'days' : null,
      }

      if (this.units.length) {
        item.unit = this.units.find(unit => unit.is_default)?.code
      }

      if (this.taxes.length === 1) {
        item.tax = this.taxes[0].id
      }
      else {
        item.tax = this.defaultTax
      }

      if (this.orderBy) {
        item[this.orderBy] = this.items.length
      }

      this.$emit('update:items', this.items.concat([item]))
    },
    addPurchaseItem() {
      this.addItem()
      this.$nextTick(() => {
        this.$refs.purchaseCart.focusLastItem()
      })
    },
    addRentalItem() {
      this.addItem(true)
      this.$nextTick(() => {
        this.$refs.rentalCart.focusLastItem()
      })
    },
    clear() {
      let i = this.items.length
      while (i > 0) {
        i -= 1
        const item = this.items[i]
        if (!(item.description || item.unit_price || item.day_rate || item.week_rate
          || item.month_rate || item.comment)) {
          this.items.splice(i, 1)
        }
      }
      this.$emit('update:items', this.items)
    },
    handleItemSort(filteredArray, event) {
      if (event.moved) {
        const movedItem = event.moved.element

        const movedToLastPosition = event.moved.newIndex >= filteredArray.length - 1

        const offset = movedToLastPosition ? -1 : 1
        const adjustment = movedToLastPosition ? 1 : 0

        const items = [...this.items]

        const oldIndex = items.findIndex(i => i.id === movedItem.id)
        items.splice(oldIndex, 1)

        const newIndex = items.findIndex(i => i.id === filteredArray[event.moved.newIndex + offset].id)
        items.splice(newIndex + adjustment, 0, movedItem)

        if (this.orderBy) {
          items.forEach((item, index) => {
            item[this.orderBy] = index
          })
        }

        this.$emit('update:items', items)
      }
    },
    isValid() {
      if (!this.validate) {
        return true
      }

      this.v$.$touch()

      const toValidate = [
        this.$refs.purchaseCart,
        this.$refs.rentalCart,
        this.$refs.orderFreightCharges,
      ]

      const valid = toValidate.filter(c => c).map(c => c.isValid()).concat([!this.v$.$invalid]).every(x => x)

      this.error = !valid

      return valid
    },
    removeItem(item) {
      this.$emit('update:items', this.items.filter(i => i.id !== item.id))
    },
    updateCostCodes(costCode) {
      this.costCode = costCode

      this.items.filter(item => !item.cost_code).forEach(item => {
        item.cost_code = costCode
      })
    },
    updateDefaultTax(tax) {
      this.defaultTax = tax

      this.items.filter(item => !item.tax).forEach(item => {
        item.tax = tax
      })
    }
  }
}
</script>
