<template>
  <vdate-picker
    v-model="localValue"
    v-bind="$attrs"
    color="interactive"
    :rules="rules"
    mode="time"
  />
</template>

<script>
import 'v-calendar/dist/style.css'
import moment from 'moment'
import { DatePicker } from 'v-calendar'

export default {
  name: 'time-picker',
  components: { 'vdate-picker': DatePicker },
  props: {
    minuteStep: {
      type: [Number, String],
      default: 15,
      validator(value) {
        return !Number.isNaN(value) && value > 0
      }
    },
    modelValue: {
      type: Object,
      default: undefined
    },
  },
  emits: ['update:model-value'],
  computed: {
    rules() {
      return {
        minutes: { interval: this.minuteStep }
      }
    },
    localValue: {
      get() {
        return this.modelValue?.toDate() || undefined
      },

      set(newDate) {
        if (newDate) {
          const date = moment(this.modelValue || undefined)
          date.hour(newDate.getHours())
          date.minute(newDate.getMinutes())

          this.$emit('update:model-value', date)
        }
      }
    },
  },
}
</script>

<style lang="scss">
</style>
