<template>
  <div>
    <table class="items-table">
      <tbody v-if="purchaseItems.length">
        <tr v-if="showItemType">
          <td class="py-4 qtm-h-3 text-secondary" colspan="5">
            ITEMS
          </td>
        </tr>
        <tr class="qtm-overline text-uppercase text-mid-grey">
          <th colspan="2">
            Description
          </th>
          <th>Qty.</th>
          <th>Unit Price</th>
          <th>Total</th>
        </tr>
        <cost-summary-item-row
          v-for="item in purchaseItems"
          :key="item.id"
          class="items-row"
          :cost-codes="costCodes"
          :hover-item="hoverItem"
          :item="item"
          :readonly="readonly"
          :with-received="withReceived"
          @hover-end="$emit('hover-end', item)"
          @hover-start="$emit('hover-start', item)"
          @update-all-cost-codes="updateAllCostCodes"
        />
      </tbody>
      <tbody v-if="rentalItems.length">
        <tr v-if="showItemType">
          <td class="py-4 qtm-h-3 text-secondary" colspan="5">
            RENTALS
          </td>
        </tr>
        <tr class="text-caption text-uppercase text-mid-grey">
          <th>Description</th>
          <th>Qty.</th>
          <th>Duration</th>
          <th>Rate</th>
          <th>Total</th>
        </tr>
        <cost-summary-item-row
          v-for="item in rentalItems"
          :key="item.id"
          class="items-row"
          :cost-codes="costCodes"
          :hover-item="hoverItem"
          :item="item"
          :readonly="readonly"
          rental
          :with-received="withReceived"
          @hover-end="$emit('hover-end', item)"
          @hover-start="$emit('hover-start', item)"
          @update-all-cost-codes="updateAllCostCodes"
        />
      </tbody>
    </table>
    <table class="summary-costs my-3">
      <tbody v-if="showSubtotal">
        <tr>
          <td colspan="2" />
          <td />
          <td class="text-mid-grey">
            Subtotal
          </td>
          <td>
            {{ currency(subtotal) }}
          </td>
        </tr>
      </tbody>
      <tbody v-if="deliveryPrice || pickupPrice">
        <tr>
          <td colspan="2" />
          <td :rowspan="pickupPrice?2:1">
            <cost-code-menu
              v-model="localFreightCostCode"
              :cost-codes="costCodes"
              :disabled="readonly"
              @all="updateAllCostCodes"
            />
          </td>
          <td class="text-mid-grey">
            Delivery fee
          </td>
          <td>
            {{ currency(deliveryPrice) }}
          </td>
        </tr>
        <tr v-if="pickupPrice">
          <td colspan="2" />
          <td class="text-mid-grey">
            Pickup fee
          </td>
          <td>
            {{ currency(pickupPrice) }}
          </td>
        </tr>
      </tbody>
      <tbody v-if="totalTax && taxLineItems.length">
        <tr v-for="item in taxLineItems" :key="item.description">
          <td colspan="2" />
          <td />
          <td class="text-mid-grey">
            {{ item.description }}
          </td>
          <td>
            {{ currency(item.total_price) }}
          </td>
        </tr>
      </tbody>
      <slot
        name="summary-costs-append"
        border-class="summary-costs-append"
        pad-span="2"
        :show-subtotal="showSubtotal"
        spacer-class="spacer"
      />
      <tbody class="total-body">
        <tr v-if="forceTotalSpacer || showSubtotal" class="spacer">
          <td colspan="5" />
        </tr>
        <tr class="font-weight-bold total-cost white-space-nowrap">
          <td colspan="2" />
          <td class="total-cell-spacer" />
          <slot name="total-cells">
            <td>
              Total <span v-if="!totalTax">(excl. tax)</span>
            </td>
            <td>
              {{ currency(total) }}
            </td>
          </slot>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { currency } from '~/models/filters'
import CostCodeMenu from '@/components/cost-codes/cost-code-menu.vue'
import CostSummaryItemRow from '@/components/orders/cost-summary-item-row.vue'

export default {
  name: 'cost-summary-table',
  components: { CostCodeMenu, CostSummaryItemRow },
  props: {
    costCodes: {
      type: Array,
      default: () => []
    },
    deliveryPrice: {
      type: [Number, String],
      default: undefined,
    },
    forceTotalSpacer: {
      type: Boolean,
      default: false,
    },
    freightCostCode: {
      type: String,
      default: undefined,
    },
    freightTax: {
      type: Number,
      default: undefined,
    },
    hoverItem: {
      type: Object,
      default: undefined,
    },
    items: {
      type: Array,
      default: () => [],
    },
    pickupPrice: {
      type: [Number, String],
      default: undefined,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    totalPrice: {
      type: [Number, String],
      default: undefined,
    },
    totalTax: {
      type: [Number, String],
      default: undefined,
    },
    withReceived: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['hover-end', 'hover-start', 'update:freight-cost-code'],
  data() {
    return {
      taxLineItems: [],
    }
  },
  computed: {
    localFreightCostCode: {
      get() {
        return this.freightCostCode
      },
      set(value) {
        this.$emit('update:freight-cost-code', value)
      }
    },
    purchaseItems() {
      return this.items.filter(item => !item.rental_duration_unit)
    },
    rentalItems() {
      return this.items.filter(item => item.rental_duration_unit)
    },
    showItemType() {
      return this.purchaseItems.length && this.rentalItems.length
    },
    showSubtotal() {
      return this.deliveryPrice || this.pickupPrice || this.totalTax
    },
    subtotal() {
      return (this.totalPrice || 0) - (this.deliveryPrice || 0) - (this.pickupPrice || 0)
    },
    total() {
      return Number(this.totalPrice || 0) + Number(this.totalTax || 0)
    },
  },
  watch: {
    totalTax() {
      this.fetchTaxAmounts()
    },
  },
  mounted() {
    this.fetchTaxAmounts()
  },
  methods: {
    currency(value) {
      return currency(value)
    },
    async fetchTaxAmounts() {
      const data = {
        delivery_charge: this.deliveryPrice || null,
        freight_tax: this.freightTax,
        pickup_charge: this.pickupPrice || null,
        poskus: this.items,
      }

      try {
        const result = await this.$api.v1.purchaseOrders.estimate(data)

        this.taxLineItems = result.tax_line_items
      }
      catch (error) {
        this.$error.report(error)
      }
    },
    updateAllCostCodes(code) {
      this.items.forEach(item => {
        item.cost_code = code
      })
      this.$emit('update:freight-cost-code', code)
    },
  }
}
</script>

<style lang="scss">
$cell-padding: 1rem;

.items-table,
.summary-costs {
  border-collapse: collapse;
  width: 100%;
}

.items-table tbody,
.items-table td {
  border: none !important;
}

.items-table td:not(:first-child),
.items-table th:not(:first-child),
.summary-costs td:last-child {
  text-align: right;
}

.items-table th {
  background: inherit;
  border: none;
  font-weight: normal !important;
  text-align: left;
}

.items-row td {
  padding-bottom: $cell-padding;
  padding-top: $cell-padding;
}

.items-row td:not(:first-child) {
  max-width: 90px;
}

.items-row:not(:last-child),
.items-table tbody:nth-last-child(1) {
  border-bottom: 1px solid rgb(var(--v-theme-light-grey)) !important;
}

.summary-costs td {
  padding-bottom: 2px;
  padding-top: 2px;
}

.spacer td {
  height: $cell-padding;
}

.total-body:not(:first-child) td:not(:first-child) {
  border-top: 4px solid rgb(var(--v-theme-light-grey)) !important;
  padding-top: $cell-padding;
}

.summary-costs td:last-child,
.summary-costs td:nth-last-child(2) {
  min-width: 6em;
}
.summary-costs td:nth-last-child(3) {
  vertical-align: middle;
}
.summary-costs td:first-child {
  width: 50%;
}
.items-table.summary-costs tbody:last-child {
  border-bottom: none !important;
}

.total-cell-spacer {
  min-width: 5rem;
}

.summary-costs-append tr:first-child td:not(:first-child) {
  border-top: 1px solid rgb(var(--v-theme-light-grey)) !important;
  padding-top: $cell-padding;
}
</style>
