<template>
  <v-list-item
    class="pl-0"
    :class="final ? 'final' : ''"
  >
    <v-list-item-title class="pa-0">
      <div>
        <v-icon :class="approval.icon_class">
          mdi-check
        </v-icon>
        <span
          class="item-title ml-1 qtm-h3"
          :class="final && approval.entry_type=='REQ' ? 'pending' : ''"
        >
          {{ approval.title }}
        </span>
      </div>
      <div>
        <div class="connector" />
        <div class="item-subtitle ml-1">
          {{ approval.tag }} {{ approval.action_user }}
          <span v-if="approval.entry_type!=='REQ'">
            on {{ dateCreated }}
          </span>
        </div>
      </div>
    </v-list-item-title>
  </v-list-item>
</template>

<script>
import { dateYear } from '~/models/filters'

export default {
  name: 'approval-activity-item',
  props: {
    approval: {
      type: Object,
      required: true,
    },
    final: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    dateCreated() {
      return dateYear(this.approval.date_created)
    },
  },
}
</script>

<style>
  .check-icon {
    color: white !important;
    background-color: rgb(var(--v-theme-secondary-lighten-1));
  }
  .unchecked.check-icon {
      color: rgb(var(--v-theme-light-grey)) !important;
      background-color: rgb(var(--v-theme-light-grey));
  }
  .connector {
    width: 0.15em;
    height: 1.4em;
    display: inline-block;
    margin: 0.5em 0.8em 0em 0.7em;
    background-color: var(--v-theme-secondary-lighten1)
  }
  .final .connector {
    background-color: white;
  }
  .item-subtitle {
    display: inline-block;
    vertical-align: top;
    color: rgb(var(--v-theme-mid-grey));
  }
  .final .item-subtitle {
    color: rgb(var(--v-theme-dark-grey));
  }
  .item-title {
    color: rgb(var(--v-theme-secondary-lighten-1));
  }
  .item-title.pending {
    color: rgb(var(--v-theme-secondary));
  }
</style>
