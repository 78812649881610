<template>
  <div class="edit-purchase-order">
    <div class="d-flex justify-end mb-4">
      <sync-status v-if="isIntegrated" :order="order" :po="localPo" />
    </div>

    <div v-if="localPo" class="edit-po-form">
      <purchase-order-info ref="purchaseOrderInfo" :po="localPo" :user="localUser" />

      <order-supplier
        ref="orderSupplier"
        v-model:contact="contact"
        v-model:internal-distribution="localPo.internal_distribution_only"
        v-model:supplier="vendor"
        allow-internal
        :user="order.owner.id"
      />

      <order-cart
        ref="orderCart"
        v-model:delivery-charge="localPo.latest_change_order.delivery_charge"
        v-model:freight-cost-code="localPo.latest_change_order.freight_cost_code"
        v-model:freight-tax="localPo.latest_change_order.freight_tax"
        v-model:items="localPo.poskus"
        v-model:pickup-charge="localPo.latest_change_order.pickup_charge"
        :cost-codes="costCodes"
        :delivery-required="localPo.delivery_required"
        :force-cost-code-select="isIntegrated"
        :taxes="taxes"
        :units="units"
        with-prices
      />

      <div class="text-right">
        <order-total-price-estimate
          :delivery-charge="localPo.latest_change_order.delivery_charge"
          :delivery-required="localPo.delivery_required"
          :freight-tax="localPo.latest_change_order.freight_tax"
          :pickup-charge="localPo.latest_change_order.pickup_charge"
          :skus="localPo.poskus"
          :model-value="localPo.total_price"
        />
      </div>

      <order-delivery ref="orderDelivery" :order="localPo" />

      <order-comment v-model="localPo.comments" />

      <v-divider class="my-5" />

      <div class="text-center">
        <qtm-btn :loading="loading" secondary @click="submitChanges()">
          Submit Changes
        </qtm-btn>
      </div>

      <div v-if="winningQuote">
        <qtm-input-label label="Winning Quote" />
        <quote-detail-sheet :order="order" :quote="winningQuote" width="100%" />
      </div>
    </div>
    <v-alert v-else max-width="45rem" type="error">
      This order does not have a valid purchase order. Please visit the admin.
    </v-alert>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep'
import isEqual from 'lodash.isequal'
import OrderCart from '@/components/orders/order-cart.vue'
import OrderComment from '@/components/orders/order-comment.vue'
import OrderDelivery from '@/components/orders/order-delivery.vue'
import OrderSupplier from '@/components/orders/order-supplier.vue'
import OrderTotalPriceEstimate from '@/components/orders/order-total-price-estimate.vue'
import PurchaseOrderInfo from '@/components/purchase-orders/purchase-order-info.vue'
import QuoteDetailSheet from '@/components/quotes/quote-detail-sheet.vue'
import SyncStatus from '@/components/purchase-orders/sync-status.vue'
import useCostCodes from '@/composables/cost-codes'
import useTaxes from '@/composables/taxes'
import useUnits from '@/composables/units'

export default {
  name: 'edit-purchase-order',
  components: {
    OrderCart,
    OrderComment,
    OrderDelivery,
    OrderSupplier,
    OrderTotalPriceEstimate,
    PurchaseOrderInfo,
    QuoteDetailSheet,
    SyncStatus,
  },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const { costCodes, fetchCostCodes } = useCostCodes(props.order.jobsite?.id)
    const { fetchTaxes, taxes } = useTaxes(props.order.jobsite?.company)
    const { fetchUnits, units } = useUnits(props.order.jobsite?.company)

    return {
      costCodes,
      fetchCostCodes,
      fetchTaxes,
      fetchUnits,
      taxes,
      units,
    }
  },
  data() {
    return {
      contact: null,
      loading: false,
      localPo: undefined,
      localUser: undefined,
      vendor: undefined,
    }
  },
  computed: {
    isIntegrated() {
      return !!this.localPo?.jobsite.accounting_id
    },
    winningQuote() {
      return this.order.quotes.filter(q => q.is_winning_quote)[0]
    },
  },
  watch: {
    'localPo.delivery_required': function (value) {
      const localChangeOrder = this.localPo.latest_change_order

      if (value) {
        const changeOrder = this.order.pos[0].latest_change_order

        localChangeOrder.delivery_charge = changeOrder.delivery_charge
        localChangeOrder.pickup_charge = changeOrder.pickup_charge
        localChangeOrder.freight_cost_code = changeOrder.freight_cost_code
      }
      else {
        localChangeOrder.delivery_charge = null
        localChangeOrder.pickup_charge = null
        localChangeOrder.freight_cost_code = ''
      }
    },
    'localPo.jobsite': function (newJobsite, oldJobsite) {
      if (newJobsite) {
        if (newJobsite.company !== oldJobsite?.company) {
          this.fetchTaxes(newJobsite.company)
          this.fetchUnits(newJobsite.company)
        }

        if (newJobsite.id !== oldJobsite?.id) {
          this.fetchCostCodes(newJobsite.id)
        }
      }
      else {
        this.costCodes = []
        this.taxes = []
        this.units = []
      }
    },
  },
  mounted() {
    this.clonePo()
  },
  methods: {
    async submitChanges(externalCaller = false) {
      if (!this.localPo) {
        return
      }

      if (!this.isValid()) {
        this.$toast.error('Please correct the errors and try again.')
        return
      }

      const updateData = this.getSubmitData()

      if (!this.changes(updateData)) {
        if (!externalCaller) {
          this.$toast.warning('No changes to submit')
        }
        return
      }

      this.loading = true
      try {
        await this.$api.v1.purchaseOrders.update(this.localPo.id, updateData)
        const updatedOrder = await this.$api.v1.rfqs.get(this.order.id)
        this.$store.commit('admin/updateOrder', updatedOrder)
        this.$toast.success('Purchase order updated')
        this.initialData = updateData
      }
      catch (error) {
        if (externalCaller) {
          throw error
        }
        this.$error.report(error)
      }
      this.loading = false
    },

    changes(submitData) {
      return !isEqual(this.initialData, submitData)
    },

    getSubmitData() {
      const submitData = {
        customer_pickup: this.localPo.customer_pickup,
        delivery_date: this.localPo.delivery_date ? this.localPo.delivery_date.unix() : null,
        delivery_location: this.localPo.delivery_location,
        delivery_time: this.localPo.delivery_time,
        internal_distribution_only: this.localPo.internal_distribution_only,
        location: this.localPo.location,
        po_number: this.localPo.po_number,
        comments: this.localPo.comments,
        site_contact: this.localPo.site_contact,
        supplier: this.vendor.id,
        supplier_contact: this.contact?.id || null,
        jobsite: this.localPo.jobsite.id,
        latest_change_order: {
          ...this.localPo.latest_change_order,
          accounting_message: undefined,
          accounting_success: undefined,
          poskus: this.localPo.poskus,
        },
      }

      return cloneDeep(submitData)
    },

    async beforeConfirmation(_action, abort) {
      if (!this.isValid()) {
        this.$toast.error('Please correct the errors and try again.')
        abort()
        return
      }
      await this.submitChanges(true)
    },

    isValid() {
      return [
        this.$refs.purchaseOrderInfo.isValid(),
        this.$refs.orderSupplier.isValid(),
        this.$refs.orderCart.isValid(),
        this.$refs.orderDelivery.isValid(),
      ].every(x => x)
    },

    clonePo() {
      if (this.order.pos.length) {
        this.localPo = cloneDeep(this.order.pos[0])
        this.vendor = {
          id: this.localPo.supplier,
          name: this.localPo.supplier_name,
          address: this.localPo.supplier_address,
          city: this.localPo.supplier_city,
          province: this.localPo.supplier_province,
          postal_code: this.localPo.supplier_postal_code,
        }

        if (this.localPo.supplier_contact) {
          this.contact = {
            id: this.localPo.supplier_contact,
            first_name: this.localPo.supplier_contact_first_name,
            last_name: this.localPo.supplier_contact_last_name,
            email: this.localPo.supplier_contact_email,
            phone_extension: this.localPo.supplier_contact_phone_extension,
            phone_number: this.localPo.supplier_contact_phone,
          }
        }

        this.localPo.comment = this.localPo.comments
        this.initialData = this.getSubmitData()
        this.localUser = { id: this.localPo.requisitioner }
      }
    },

    refresh() {
      this.clonePo()
    },
  }
}
</script>

<style lang="scss" scoped>
.edit-po-form > * {
  margin-bottom: 1rem;
}
</style>
