import { createApi, OrderState } from '@quotetome/materials-api'
import orderStatesExtension from '@/models/order-states-extension'
// eslint-disable-next-line import/no-cycle
import store from '~/store'
import { axiosInstance } from '~/plugins/axios'

OrderState.extendStates(orderStatesExtension)

// TODO: fix vuex namespaced typing
export const api = createApi(axiosInstance, () => (store.state as any).auth.authToken)

export default defineNuxtPlugin(() => {
  return {
    provide: { api }
  }
})
