<template>
  <qtm-content-block collapsible title="Supplier Information">
    <div class="font-weight-bold" v-text="vendor.name" />
    <div v-text="vendor.address" />
    <div>
      <qtm-address :city="vendor.city" :province="vendor.province" />
    </div>
    <qtm-input-label class="mt-3 contact-select" label="Contact" required>
      <div v-if="contact">
        <div>{{ contact.first_name }} {{ contact.last_name }}</div>
        <div>{{ contact.phone }}</div>
        <div>{{ contact.email }}</div>
      </div>
      <div v-else>
        <vendor-contact-select
          :error-messages="contactError"
          :vendor="localVendor"
          @update:model-value="setContact"
          @new-contact="setContact"
        />
      </div>
    </qtm-input-label>

    <div v-if="showChangeVendorButton" class="d-flex justify-end">
      <qtm-btn secondary size="small" @click="showDialog = true">
        <v-icon size="small">
          mdi-pencil
        </v-icon>
        Change Vendor
      </qtm-btn>
      <change-vendor-dialog v-model="showDialog" :order="order" :quote="quote" :user="order.owner.id" />
    </div>
  </qtm-content-block>
</template>

<script>
import { required } from '@vuelidate/validators'
import ChangeVendorDialog from '@/components/vendors/change-vendor-dialog.vue'
import VendorContactSelect from '@/components/vendors/vendor-contact-select.vue'
import useValidation from '@/composables/validation'

export default {
  name: 'quote-vendor-info',
  components: { ChangeVendorDialog, VendorContactSelect },
  validations: {
    contact: { required },
  },
  props: {
    contact: {
      type: Object,
      default: undefined
    },
    order: {
      type: Object,
      required: true,
    },
    quote: {
      type: Object,
      default: undefined
    },
    vendor: {
      type: Object,
      required: true
    },
  },
  emits: ['update:contact'],
  setup() {
    const { errors, isValid, v$ } = useValidation()

    return { errors, isValid, v$ }
  },
  data() {
    return {
      showDialog: false
    }
  },
  computed: {
    contactError() {
      return typeof this.errors.contact === 'string' ? this.errors.contact : undefined
    },
    localVendor() {
      return {
        ...this.vendor,
        contact: this.contact?.id,
      }
    },
    showChangeVendorButton() {
      return this.quote && this.order.state.can_change_quote_vendor
    },
  },
  methods: {
    setContact(contact) {
      if (contact.id && !this.vendor.contacts.some(c => c.id === contact.id)) {
        this.vendor.contacts.push(contact)
      }

      if (contact.id) {
        this.$emit('update:contact', contact)
      }
      else {
        this.$emit('update:contact', this.vendor.contacts.find(c => c.id === contact))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.contact-select {
  max-width: 500px;
}
</style>
