<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6">
        <div class="font-weight-bold">
          Additional Information
        </div>
        <div class="order-comment">
          {{ order.comment }}
        </div>
      </v-col>
      <v-col cols="6">
        <div class="font-weight-bold mb-3">
          Additional Information for the Customer
        </div>
        <qtm-textarea
          v-model="quote.comments"
          auto-grow
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'quote-comment',
  props: {
    quote: {
      type: Object,
      required: true
    },
    order: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.order-comment {
  margin: 0.5rem;
  border-left: 3px solid rgb(var(--v-theme-light-grey));
  padding-left: 0.25rem;
}
</style>
