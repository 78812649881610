import { defineNuxtPlugin } from '#app/nuxt'
import { LazyQtmAddress, LazyQtmAutocomplete, LazyQtmBadge, LazyQtmBtn, LazyQtmCheckbox, LazyQtmCombobox, LazyQtmContentBlock, LazyQtmDataTable, LazyQtmDialogCard, LazyQtmIconBtn, LazyQtmInputLabel, LazyQtmLink, LazyQtmMenuSelect, LazyQtmNumberField, LazyQtmPagination, LazyQtmPasswordField, LazyQtmPhoneNumber, LazyQtmRadioGroup, LazyQtmRadio, LazyQtmSelect, LazyQtmSkeleton, LazyQtmTextField, LazyQtmTextarea } from '#components'
const lazyGlobalComponents = [
  ["QtmAddress", LazyQtmAddress],
["QtmAutocomplete", LazyQtmAutocomplete],
["QtmBadge", LazyQtmBadge],
["QtmBtn", LazyQtmBtn],
["QtmCheckbox", LazyQtmCheckbox],
["QtmCombobox", LazyQtmCombobox],
["QtmContentBlock", LazyQtmContentBlock],
["QtmDataTable", LazyQtmDataTable],
["QtmDialogCard", LazyQtmDialogCard],
["QtmIconBtn", LazyQtmIconBtn],
["QtmInputLabel", LazyQtmInputLabel],
["QtmLink", LazyQtmLink],
["QtmMenuSelect", LazyQtmMenuSelect],
["QtmNumberField", LazyQtmNumberField],
["QtmPagination", LazyQtmPagination],
["QtmPasswordField", LazyQtmPasswordField],
["QtmPhoneNumber", LazyQtmPhoneNumber],
["QtmRadioGroup", LazyQtmRadioGroup],
["QtmRadio", LazyQtmRadio],
["QtmSelect", LazyQtmSelect],
["QtmSkeleton", LazyQtmSkeleton],
["QtmTextField", LazyQtmTextField],
["QtmTextarea", LazyQtmTextarea],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
