<template>
  <ClientOnly>
    <div v-if="editor" class="mb-1">
      <qtm-icon-btn
        icon="mdi-undo"
        tabindex="-1"
        :disabled="!editor.can().chain().focus().undo().run()"
        @click="editor.chain().focus().undo().run()"
      />
      <qtm-icon-btn
        icon="mdi-redo"
        tabindex="-1"
        :disabled="!editor.can().chain().focus().redo().run()"
        @click="editor.chain().focus().redo().run()"
      />
      <qtm-icon-btn
        icon="mdi-format-bold"
        tabindex="-1"
        :class="{ 'is-active': editor.isActive('bold') }"
        :color="editor.isActive('bold') ? 'background' : 'interactive' "
        :disabled="!editor.can().chain().focus().toggleBold().run()"
        @click="editor.chain().focus().toggleBold().run()"
      />
      <qtm-icon-btn
        icon="mdi-format-underline"
        tabindex="-1"
        :class="{ 'is-active': editor.isActive('underline') }"
        :color="editor.isActive('underline') ? 'background' : 'interactive' "
        :disabled="!editor.can().chain().focus().toggleUnderline().run()"
        @click="editor.chain().focus().toggleUnderline().run()"
      />
      <qtm-icon-btn
        icon="mdi-format-italic"
        tabindex="-1"
        :class="{ 'is-active': editor.isActive('italic') }"
        :color="editor.isActive('italic') ? 'background' : 'interactive' "
        :disabled="!editor.can().chain().focus().toggleItalic().run()"
        @click="editor.chain().focus().toggleItalic().run()"
      />
      <qtm-icon-btn
        icon="mdi-format-strikethrough"
        tabindex="-1"
        :class="{ 'is-active': editor.isActive('strike') }"
        :color="editor.isActive('strike') ? 'background' : 'interactive' "
        :disabled="!editor.can().chain().focus().toggleStrike().run()"
        @click="editor.chain().focus().toggleStrike().run()"
      />
      <qtm-icon-btn
        icon="mdi-format-list-bulleted"
        tabindex="-1"
        :class="{ 'is-active': editor.isActive('bulletList') }"
        :color="editor.isActive('bulletList') ? 'background' : 'interactive' "
        @click="editor.chain().focus().toggleBulletList().run()"
      />
      <qtm-icon-btn
        icon="mdi-format-list-numbered"
        tabindex="-1"
        :class="{ 'is-active': editor.isActive('orderedList') }"
        :color="editor.isActive('orderedList') ? 'background' : 'interactive' "
        @click="editor.chain().focus().toggleOrderedList().run()"
      />
    </div>
    <editor-content class="text-black" :editor="editor" />
  </ClientOnly>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-3'

import Bold from '@tiptap/extension-bold'
import BulletList from '@tiptap/extension-bullet-list'
import Document from '@tiptap/extension-document'
import History from '@tiptap/extension-history'
import Italic from '@tiptap/extension-italic'
import ListItem from '@tiptap/extension-list-item'
import OrderedList from '@tiptap/extension-ordered-list'
import Paragraph from '@tiptap/extension-paragraph'
import Placeholder from '@tiptap/extension-placeholder'
import Strike from '@tiptap/extension-strike'
import Text from '@tiptap/extension-text'
import Underline from '@tiptap/extension-underline'

export default {
  components: { EditorContent },
  props: {
    autofocus: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  emits: ['update:model-value'],
  data() {
    return {
      editor: null,
    }
  },

  mounted() {
    this.editor = new Editor({
      autofocus: this.autofocus,
      extensions: [
        Bold,
        BulletList,
        Document,
        History,
        Italic,
        ListItem,
        OrderedList,
        Paragraph,
        Placeholder.configure({ placeholder: this.placeholder }),
        Strike,
        Text,
        Underline,
      ],
      content: this.modelValue,
      onUpdate: ({ editor }) => {
        this.$emit('update:model-value', editor.getHTML())
      },
    })
  },

  beforeUnmount() {
    this.editor.destroy()
  },
}
</script>

<style lang="scss">
/* Basic editor styles */
.ProseMirror {
  background-color: white;
  padding: 10px;
  border: 1px solid rgb(var(--v-theme-mid-light-grey));
  min-height: 200px;
  outline-color: rgb(var(--v-theme-interactive));

  &:focus {
    outline-style: solid;
    outline-width: 2px;
  }

  > * + * {
    margin-top: 0.75em;
  }
}
.ProseMirror ul, .ProseMirror ol {
  padding-left: 20px;
}

/* Placeholder (at the top) */
.ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}
.is-active {
  background-color: rgb(var(--v-theme-interactive))
}
</style>
