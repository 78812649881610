<template>
  <div>
    <v-row dense>
      <v-col cols="6">
        <toggle-btn
          block
          :disabled="disabled"
          icon="mdi-weather-sunny"
          label="Morning"
          val="am"
          :model-value="deliveryTime"
          @update:model-value="updateDeliveryTime"
        />
      </v-col>
      <v-col cols="6">
        <toggle-btn
          block
          :disabled="disabled"
          icon="mdi-weather-sunset"
          label="Afternoon"
          val="pm"
          :model-value="deliveryTime"
          @update:model-value="updateDeliveryTime"
        />
      </v-col>
      <v-col cols="12">
        <toggle-btn
          block
          :disabled="disabled"
          icon="mdi-clock-outline"
          label="Specific Time"
          val="sp"
          :model-value="deliveryTime"
          @update:model-value="updateDeliveryTime"
        />
      </v-col>
    </v-row>
    <div v-if="deliveryTime === 'sp'" class="mt-1">
      <time-picker
        v-bind="$attrs"
        :model-value="modelValue"
        @update:model-value="$emit('update:model-value', $event)"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import TimePicker from '@/components/time-picker.vue'
import ToggleBtn from '@/components/toggle-btn.vue'

export default {
  name: 'order-delivery-time',
  components: { TimePicker, ToggleBtn },
  props: {
    deliveryTime: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: Object,
      default: undefined
    },
  },
  emits: ['update:delivery-time', 'update:model-value'],
  methods: {
    updateDeliveryTime(value) {
      this.$emit('update:delivery-time', value)

      if ((value === 'am' || value === 'pm') && this.value) {
        this.$emit('update:model-value', moment(this.modelValue).hour(12).minute(0))
      }
    }
  },
}
</script>
