<template>
  <qtm-select
    v-bind="$attrs"
    v-model="selectedLocation"
    hide-details
    item-value="value"
    :items="items"
    :loading="loading"
    return-object
  >
    <template v-slot:item="{ item, props }">
      <v-list-item v-bind="props" title="">
        <v-list-item-title class="qtm-label">
          {{ item.raw.label }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ item.raw.address }}
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          {{ item.raw.addressLine }}
        </v-list-item-subtitle>
      </v-list-item>
    </template>

    <template v-slot:selection="{ item }">
      <v-list-item>
        <v-list-item-title class="qtm-label">
          {{ item.raw.label }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ item.raw.address }}
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          {{ item.raw.addressLine }}
        </v-list-item-subtitle>
      </v-list-item>
    </template>
  </qtm-select>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'order-delivery-location',
  props: {
    jobsite: {
      type: Object,
      default: undefined
    },
    location: {
      type: Number,
      default: null
    },
    locationType: {
      type: String,
      default: 'jobsite'
    },
  },
  emits: ['address', 'update:location', 'update:location-type'],
  data() {
    return {
      loading: false,
      office: undefined,
    }
  },
  computed: {
    ...mapGetters('auth', ['userCompany']),
    items() {
      let items = []

      if (this.jobsite) {
        items.push({
          address: this.jobsite.address,
          addressLine: this.buildAddressLine(this.jobsite),
          label: 'Site',
          location: null,
          locationType: 'jobsite',
          value: 'site',
        })
      }

      if (this.office) {
        items.push({
          address: this.office.address,
          addressLine: this.buildAddressLine(this.office),
          label: 'Office',
          location: null,
          locationType: 'office',
          value: 'office',
        })
      }

      if (this.jobsite?.locations?.length) {
        items = items.concat(this.jobsite?.locations.map(location => ({
          address: location.address,
          addressLine: this.buildAddressLine(location),
          label: location.name,
          location: location.id,
          locationType: 'jobsite',
          value: `site-${location.id}`,
        })))
      }

      return items
    },
    selectedLocation: {
      get() {
        let location

        if (this.location) {
          location = this.items.find(item => item.location === this.location)
        }
        else if (this.locationType === 'jobsite') {
          location = this.items.find(item => item.value === 'site')
        }
        else if (this.locationType === 'office') {
          location = this.items.find(item => item.value === 'office')
        }

        if (location) {
          this.$emit('address', `${location.address}, ${location.addressLine}`)
        }

        return location
      },

      set(item) {
        this.$emit('update:location', item.location)
        this.$emit('update:location-type', item.locationType)
      }
    },
  },
  watch: {
    jobsite: {
      immediate: true,
      handler() {
        return this.setOffice()
      },
    },
  },
  methods: {
    buildAddressLine(location) {
      return [location.city, location.province, location.postal_code].join(', ')
    },
    async setOffice() {
      this.office = undefined

      if (this.jobsite) {
        if (this.jobsite.company === this.userCompany.id) {
          this.office = this.userCompany
          return
        }

        this.loading = true
        try {
          this.office = await this.$api.v1.companies.get(this.jobsite.company)
        }
        catch (error) {
          this.$error.report(error)
        }
        this.loading = false
      }
    }
  }
}
</script>
