<template>
  <v-sheet class="qtm-border text-secondary" :height="height" min-height="400px" :width="width">
    <v-container>
      <v-row class="pa-3">
        <div>
          <div class="font-weight-bold quote-title">
            {{ quote.vendor.name }}
          </div>
          <div v-if="quote.salesperson">
            {{ quote.salesperson.first_name }}
            {{ quote.salesperson.last_name }}
            <span v-if="quote.salesperson.email">
              ({{ quote.salesperson.email }})
            </span>
          </div>
          <div v-if="quote.supplier_reference">
            Supplier reference: {{ quote.supplier_reference }}
          </div>
          <v-row v-if="priceGiven" class="my-2">
            <quote-attachments-link class="pl-3" :quote="quote" />
            <quote-delivery-status class="pl-3" :quote="quote" :order="order" />
          </v-row>
        </div>
        <v-spacer />
        <div v-if="priceGiven" class="text-right">
          <div class="total-price" :class="totalPriceColor">
            {{ totalPrice }}
          </div>
          <div v-if="order.canAwardQuote">
            <qtm-btn class="mr-1" secondary @click="previewPO = true">
              <v-icon location="left">
                mdi-text-box-search-outline
              </v-icon>
              Preview PO
            </qtm-btn>
            <create-po-btn :quote="quote" :order="order" />
          </div>
          <div v-else-if="hasPO && order.requiresAdjustments && !quote.is_winning_quote">
            <qtm-btn secondary @click="showChangeAwardedQuoteConfirmDialog = true">
              Select this quote instead
            </qtm-btn>
          </div>
          <div v-else-if="hasPO && !order.requiresAdjustments">
            <quote-awarded-chip :quote="quote" />
          </div>
        </div>
      </v-row>
    </v-container>
    <items-table
      v-if="priceGiven"
      :comments="quote.comments"
      :delivery-charge="quote.delivery_charge"
      :items="quote.skus"
      :pickup-charge="quote.pickup_charge"
      :scope-of-work="quote.scope_of_work"
      :total-price="quote.total_price"
    />
    <quote-response v-else class="waiting-for-quote" :comments="quote.comments" :response="quote.response_received" />
    <preview-po-dialog :id="quote.id" v-model="previewPO" quote />
    <change-awarded-quote-confirm-dialog
      v-if="showChangeAwardedQuoteConfirmDialog"
      v-model="showChangeAwardedQuoteConfirmDialog"
      :quote="quote"
      :order="order"
      :loading="changeAwardedQuoteConfirmDialogLoading"
      @confirm="changeAwardedQuote"
    />
  </v-sheet>
</template>

<script>
import { currency } from '~/models/filters'
import CreatePoBtn from '@/components/quotes/create-po-btn.vue'
import ItemsTable from '@/components/orders/items-table.vue'
import PreviewPoDialog from '@/components/purchase-orders/preview-po-dialog.vue'
import ChangeAwardedQuoteConfirmDialog from '@/components/purchase-orders/change-awarded-quote-confirm-dialog.vue'
import QuoteAttachmentsLink from '@/components/quotes/quote-attachments-link.vue'
import QuoteAwardedChip from '@/components/quotes/quote-awarded-chip.vue'
import QuoteDeliveryStatus from '@/components/quotes/quote-delivery-status.vue'
import QuoteResponse from '@/components/quotes/quote-response.vue'

export default {
  name: 'quote-detail-sheet',
  components: {
    CreatePoBtn,
    ItemsTable,
    PreviewPoDialog,
    QuoteAttachmentsLink,
    QuoteAwardedChip,
    QuoteDeliveryStatus,
    QuoteResponse,
    ChangeAwardedQuoteConfirmDialog,
  },
  props: {
    quote: {
      type: Object,
      required: true
    },
    order: {
      type: Object,
      required: true
    },
    height: {
      type: String,
      default: '90%'
    },
    width: {
      type: String,
      default: '90%'
    },
  },
  data() {
    return {
      previewPO: false,
      showChangeAwardedQuoteConfirmDialog: false,
      changeAwardedQuoteConfirmDialogLoading: false,
    }
  },
  computed: {
    hasPO() {
      return !!this.order.pos.length
    },
    priceGiven() {
      return this.quote.response_received === 'price given'
    },
    totalPriceColor() {
      if (!this.hasPO || this.order.canAwardQuote || this.quote.is_winning_quote) {
        return 'text-secondary'
      }
      return 'text-mid-dark-grey'
    },
    totalPrice() {
      return currency(this.quote.total_price)
    },
  },
  methods: {
    async changeAwardedQuote() {
      try {
        this.changeAwardedQuoteConfirmDialogLoading = true
        const resp = await this.$api.v1.rfqs.awardQuote(this.order.id, this.quote.id)
        this.showChangeAwardedQuoteConfirmDialog = false
        this.changeAwardedQuoteConfirmDialogLoading = false
        this.$router.push({ name: 'purchase-orders-id-change-order', params: { id: resp.pos[0].id } })
      }
      catch (error) {
        this.$error.report(error)
        this.showChangeAwardedQuoteConfirmDialog = false
        this.changeAwardedQuoteConfirmDialogLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.quote-title {
  font-size: 1.1rem;
}

.total-price {
  font-size: 1.5rem;
  font-weight: bold;
}

.waiting-for-quote {
  min-height: 400px;
}
</style>
