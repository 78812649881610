<template>
  <qtm-autocomplete
    v-bind="$attrs"
    item-title="name"
    item-value="isoCode"
    :items="provinces"
    @update:model-value="$emit('update:model-value', $event)"
  />
</template>

<script setup lang="ts">
import { Country, State } from 'country-state-city'
import type { IState } from 'country-state-city'

export interface Props {
  country?: string
}

const props = withDefaults(defineProps<Props>(), {
  country: 'CA',
})
const emit = defineEmits(['update:model-value'])

const provinces = ref<IState[]>([])

const attrs = useAttrs()

watch(() => props.country, (val, old) => {
  if (val !== old) {
    provinces.value = []
    const country = Country.getAllCountries().find(c => c.isoCode === props.country)

    if (country) {
      provinces.value = State.getStatesOfCountry(country.isoCode)
    }
  }

  const modelValue = attrs['model-value'] || attrs.modelValue

  if (modelValue && !provinces.value.some(province => province.isoCode === modelValue)) {
    emit('update:model-value', '')
  }
}, { immediate: true })
</script>
