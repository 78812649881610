<template>
  <div>
    <div v-if="open" class="order-btn">
      <v-btn color="white" variant="text" class="text-uppercase" @click="close">
        {{ backLinkText }}
        <v-icon>
          mdi-chevron-right
        </v-icon>
      </v-btn>
    </div>
    <attachment-viewer-dialog
      v-model="open"
      v-model:attachment="attachment"
      :wide="!!viewPo"
      @update:model-value="close"
    >
      <purchase-order-card
        v-if="viewPo"
        class="mr-8"
        :invoices="invoices"
        :hover-item="hoverItem"
        :order="order"
        :po="po"
        @close="closePo"
        @hover-end="hoverEnd"
        @hover-start="hoverStart"
      />
      <invoice-card
        v-if="invoice"
        :can-approve="canApprove"
        :cost-codes="costCodes"
        :hover-item="hoverItem"
        :invoice="invoice"
        :order="order"
        :po="po"
        @approved="emitAndClose('approved', $event)"
        @cancelled="emitAndClose('cancelled', $event)"
        @close="close"
        @hover-end="hoverEnd"
        @hover-start="hoverStart"
        @po-click="openPo"
        @preview="previewAttachment"
      >
        <template v-slot:actions-prepend>
          <qtm-btn v-if="po && !order.requiresInvoiceApproval && !viewPo" class="mr-3" secondary @click="openPo">
            View PO
          </qtm-btn>
        </template>
      </invoice-card>
      <reconciliator-snackbar
        v-if="invoice && !invoice.date_cancelled && po"
        :can-approve="canApprove"
        :collapsed="!viewPo"
        :current-invoice="invoice"
        :invoices="invoices"
        :order="order"
        :po="po"
      />
    </attachment-viewer-dialog>
  </div>
</template>

<script>
import useCostCodes from '@/composables/cost-codes'
import AttachmentViewerDialog from '@/components/attachments/attachment-viewer-dialog.vue'
import InvoiceCard from '@/components/invoices/invoice-card.vue'
import PurchaseOrderCard from '@/components/purchase-orders/purchase-order-card.vue'
import ReconciliatorSnackbar from '@/components/invoices/reconciliator-snackbar.vue'

export default {
  name: 'invoice-dialog',
  components: {
    AttachmentViewerDialog,
    InvoiceCard,
    PurchaseOrderCard,
    ReconciliatorSnackbar,
  },
  props: {
    backLinkText: {
      type: String,
      default: 'View Order',
    },
    canApprove: {
      type: Boolean,
      default: false,
    },
    closeRoute: {
      type: Object,
      default: undefined
    },
    invoices: {
      type: Array,
      default: () => [],
    },
    order: {
      type: Object,
      required: true,
    },
    po: {
      type: Object,
      default: undefined,
    },
  },
  emits: ['approved'],
  setup(props) {
    const { costCodes } = useCostCodes(props.order.jobsite?.id)

    return { costCodes }
  },
  data() {
    return {
      attachment: undefined,
      hoverItem: undefined,
      viewPo: false,
    }
  },
  computed: {
    freightCostCodeRequired() {
      return !this.revision.freight_cost_code && (
        Number(this.revision.delivery_charge) || Number(this.revision.pickup_charge)
      )
    },
    invoice() {
      if (!this.$route.query.invoice) {
        return undefined
      }

      return this.invoices.find(invoice => invoice.id === Number(this.$route.query.invoice))
    },
    open: {
      get() {
        return !!this.invoice
      },
      set(value) {
        if (value) {
          this.$router.push({ query: { ...this.$route.query, invoice: value } })
        }
        else {
          this.$router.push(this.closeRoute || { query: { ...this.$route.query, invoice: undefined } })
        }
      },
    },
  },
  watch: {
    invoice() {
      this.attachment = undefined
    }
  },
  methods: {
    close() {
      this.open = false
      this.attachment = undefined
      this.hoverItem = undefined
      this.viewPo = false
    },
    closePo() {
      this.viewPo = false
      this.hoverItem = undefined
    },
    emitAndClose(event, data) {
      this.$emit(event, data)
      this.close()
    },
    hoverEnd(item) {
      if (this.hoverItem?.id === item.id) {
        this.hoverItem = undefined
      }
    },
    hoverStart(item) {
      if (this.viewPo) {
        this.hoverItem = item
      }
    },
    previewAttachment(attachment) {
      this.viewPo = false
      this.attachment = attachment
    },
    openPo() {
      this.attachment = undefined
      this.viewPo = true
    },
  }
}
</script>

<style lang="scss" scoped>
.order-btn {
  position: fixed;
  top: 0;
  right: 0;
  text-align: right;
  z-index: 10000;
}
</style>
