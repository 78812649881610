<template>
  <qtm-content-block v-bind="$attrs">
    <project-authority :project="order.jobsite" />
    <invoice-title :invoice="invoice" :order="order" />

    <div class="text-dark-grey qtm-body">
      <invoice-information-table
        :contact="quote.salesperson"
        :date-issued="invoice.date_issued"
        :date-due="invoice.date_due"
        :total-price="quote.total_price"
        :vendor-name="quote.vendor.name"
      />

      <div class="heavy-border mt-8" />

      <cost-summary-table
        :delivery-price="quote.delivery_charge"
        :freight-cost-code="order.freight_cost_code"
        :items="itemsWithCostCodes"
        :pickup-price="quote.pickup_charge"
        readonly
        :total-price="quote.total_price"
      />
    </div>
    <div class="text-dark-grey d-flex">
      <div>
        <attachment-preview-download
          v-for="a in invoice.attachments"
          :key="a.id"
          class="mr-3"
          :name="a.name"
          :src="a.url"
          @click="$emit('preview', a)"
        />
      </div>
      <div v-if="quote.comments" class="comments">
        <div class="font-weight-bold">
          Comments
        </div>
        <div v-text="quote.comments" />
      </div>
    </div>
  </qtm-content-block>
</template>

<script>
import AttachmentPreviewDownload from '@/components/attachments/attachment-preview-download.vue'
import CostSummaryTable from '@/components/orders/cost-summary-table.vue'
import InvoiceInformationTable from '@/components/orders/invoice-information-table.vue'
import InvoiceTitle from '@/components/invoices/invoice-title.vue'
import ProjectAuthority from '@/components/jobsites/project-authority.vue'

export default {
  name: 'invoice-review',
  components: {
    AttachmentPreviewDownload,
    CostSummaryTable,
    InvoiceInformationTable,
    InvoiceTitle,
    ProjectAuthority,
  },
  props: {
    order: {
      type: Object,
      required: true
    },
  },
  emits: ['preview'],
  computed: {
    invoice() {
      return this.order.invoices[0]
    },
    itemsWithCostCodes() {
      return this.quote.skus.map(item => {
        return {
          ...item,
          cost_code: item.rfqsku?.cost_code,
        }
      })
    },
    quote() {
      return this.order.quotes[0]
    }
  },
}
</script>

<style scoped>
.comments {
  min-width: 75%;
}

.heavy-border {
  border-bottom: 4px solid rgb(var(--v-theme-secondary));
}
</style>
