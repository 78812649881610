<template>
  <v-card>
    <div class="mb-1 text-right" :class="{ 'position-absolute': scrolled }">
      <qtm-icon-btn color="mid-light-grey" icon="mdi-close" @click="$emit('close')" />
    </div>
    <v-card-title
      class="d-flex align-center justify-space-between pb-0 pt-1 px-8"
      :class="{ 'pb-2': scrolled, 'qtm-border-bottom': scrolled }"
    >
      <div class="d-flex align-center">
        <span class="qtm-title-medium text-secondary" v-text="title" />
        <slot name="title-append" />
      </div>
      <div>
        <slot name="actions" />
      </div>
    </v-card-title>
    <v-card-text class="pb-12 pt-0 px-8" @scroll="onScroll">
      <v-row dense>
        <v-col cols="7">
          <slot name="subtitle">
            <div class="qtm-body-large text-mid-grey" v-text="subtitle" />
          </slot>
        </v-col>
      </v-row>
      <slot />
    </v-card-text>
    <div class="smooth-bottom" />
  </v-card>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'scroll-card',
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
  emits: ['close'],
  setup() {
    const scrolled = ref(false)
    const onScroll = (e) => {
      scrolled.value = e.target.scrollTop > 0
    }

    return { onScroll, scrolled }
  },
}
</script>

<style lang="scss" scoped>
.position-absolute {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
}

.smooth-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  background: linear-gradient(to top, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
}
</style>
