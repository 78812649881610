<template>
  <v-chip
    class="chip"
    :color="color"
    label
    size="small"
    :text="text"
    variant="flat"
  />
</template>

<script>
export default {
  name: 'quote-awarded-chip',
  props: {
    quote: {
      type: Object,
      required: true
    }
  },
  computed: {
    awarded() {
      return this.quote.is_winning_quote
    },
    color() {
      return this.awarded ? 'interactive' : 'iron-gray'
    },
    text() {
      return this.awarded ? 'PO ISSUED' : 'Declined'
    },
  }
}
</script>

<style scoped>
.chip {
  font-size: 1rem !important;
}
</style>
