<template>
  <tbody class="attachments-row">
    <tr>
      <td class="pt-1 qtm-label text-uppercase white-space-nowrap" :rowspan="labelRowSpan">
        <slot name="label">
          Invoice
        </slot>
      </td>
    </tr>
    <tr v-for="invoice in sortedInvoices" :key="invoice.id">
      <td class="py-1">
        <nuxt-link
          class="attachment-link text-decoration-none"
          :to="{ query: { ...$route.query, invoice: invoice.id } }"
        >
          Invoice #{{ invoice.invoice_number }} - {{ getTotalPrice(invoice) }}
        </nuxt-link>
      </td>
      <td class="py-1 text-no-wrap">
        {{ dateCreated(invoice) }}
      </td>
    </tr>
    <tr>
      <td>
        <slot />
      </td>
      <td />
    </tr>
    <tr v-if="bottomDivider">
      <td colspan="3">
        <v-divider class="my-1" />
      </td>
    </tr>
  </tbody>
</template>

<script>
import { dateYear, currency } from '~/models/filters'

export default {
  name: 'invoice-attachments-row',
  props: {
    invoices: {
      type: Array,
      default: () => []
    },
    order: {
      type: Object,
      required: true
    },
    po: {
      type: Object,
      required: true
    },
    bottomDivider: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    labelRowSpan() {
      return this.invoices.length + 2
    },
    sortedInvoices() {
      const invoices = this.invoices

      invoices.sort((a, b) => b.date_created - a.date_created)
      return invoices
    },
  },
  methods: {
    dateCreated(invoice) {
      return dateYear(invoice.date_created)
    },
    getTotalPrice(invoice) {
      if (invoice?.total_price) {
        return currency(invoice.total_price + (invoice.total_tax || 0))
      }
      return currency(this.po.total_price + (this.po.total_tax || 0))
    }
  }
}
</script>

<style lang="scss" scoped>
.attachment-link {
  font-weight: 500;
}

.attachments-row {
  vertical-align: top;
}
</style>
