import store from '~/store'

export default defineNuxtRouteMiddleware((to) => {
  if (
    to.meta.layout === 'logged-in-app'
    && store.getters['auth/inSetupMode']
    && !store.getters['auth/canManageCompany']
    && to.name !== 'setup-mode'
  ) {
    return navigateTo({ name: 'setup-mode' })
  }

  return null
})
