<template>
  <div
    v-if="show && unspecifiedDeliveryDate"
    class="text-secondary"
  >
    <v-icon
      color="primary"
      dense
      :title="`${deliveryOrPickup} date unspecified`"
    >
      mdi-alert-circle
    </v-icon>
    Unspecified {{ deliveryOrPickup }} Date
  </div>
  <div v-else-if="show" class="text-secondary">
    <v-icon
      v-if="quote.delivery_available && quote.deliver_in_time"
      color="interactive"
      dense
      :title="`${deliveryOrPickup} is available at the specified time`"
    >
      mdi-check-circle
    </v-icon>
    <v-icon
      v-else-if="quote.delivery_available"
      color="primary"
      dense
      :title="`${deliveryOrPickup} is available but not at the specified time`"
    >
      mdi-alert-circle
    </v-icon>
    <v-icon v-else color="error" dense :title="`${deliveryOrPickup} is not available`">
      mdi-close-circle
    </v-icon>
    <span v-if="deliveryDateObject">
      {{ deliveryDate }}
    </span>
    <span v-else>
      {{ deliveryOrPickup }} Unavailable
    </span>
  </div>
</template>

<script>
import { objectDeliveryDate } from '~/models/filters'

export default {
  name: 'quote-delivery-status',
  props: {
    quote: {
      type: Object,
      required: true
    },
    order: {
      type: Object,
      required: true
    }
  },
  computed: {
    deliveryDate() {
      return objectDeliveryDate(this.deliveryDateObject)
    },
    deliveryDateObject() {
      if (this.quote.delivery_available) {
        return this.quote.delivery_date ? this.quote : this.order
      }
      return undefined
    },
    deliveryOrPickup() {
      return this.order.customer_pickup ? 'Pick-up' : 'Delivery'
    },
    show() {
      return this.order.delivery_required || this.order.customer_pickup
    },
    unspecifiedDeliveryDate() {
      return this.deliveryDateObject && !this.deliveryDateObject.delivery_date
    },
  },
}
</script>
