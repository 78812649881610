<template>
  <qtm-btn
    v-bind="$attrs"
    data-test="create-po-btn"
    :loading="loading"
    @click.stop.prevent="makeAdjustments"
  >
    Create PO
  </qtm-btn>
</template>

<script>
export default {
  name: 'create-po-btn',
  props: {
    order: {
      type: Object,
      required: true,
    },
    quote: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    async makeAdjustments() {
      this.loading = true
      try {
        const order = await this.$api.v1.rfqs.transition(this.order, 'make_final_adjustments', {
          context: { quote: this.quote.id },
        })
        this.$router.push({ name: 'purchase-orders-id-change-order', params: { id: order.pos[0].id } })
      }
      catch (error) {
        this.$error.report(error)
      }
      this.loading = false
    }
  },
}
</script>
