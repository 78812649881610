<template>
  <div>
    <div
      v-for="bb in boundingBoxes"
      :key="`${bb.position.Top}-${bb.position.Left}-${bb.position.Width}-${bb.position.Height}`"
      class="bounding-box"
      :style="getBoundingBoxStyle(bb)"
    />
  </div>
</template>

<script setup lang="ts">
import { useStore } from 'vuex'

const store = useStore()
const attachmentBoundingBoxes = computed(() => store.state.admin.attachmentBoundingBoxes)
const boundingBoxes = computed(() => attachmentBoundingBoxes.value.filter((bb: any) => !!bb.position))

const getBoundingBoxStyle = (bb: {
  position: { Width: number, Height: number, Top: number, Left: number },
  confidence: number,
}) => {
  const { Width: width, Height: height, Top: top, Left: left } = bb.position
  return `width: ${width * 100}%;
    height: ${height * 100}%;
    top: ${top * 100}%;
    left: ${left * 100}%;`
}
</script>

<style lang="scss">
.bounding-box {
  position: absolute;
  background-color: #FBFF2E;
  opacity: 0.3;
  z-index: 5;
  padding: 4px;
}
</style>
