import store from '~/store'

const defaultOptions = {
  bottom: true
}

export const toast = {
  toast(message: string, options?: any) {
    const toastOptions = {
      ...defaultOptions,
      message,
      ...options
    }

    store.commit('toast', toastOptions)
  },
  success(message: string, options?: any) {
    const toastOptions = {
      ...defaultOptions,
      color: 'success',
      icon: 'mdi-check',
      message,
      ...options
    }

    store.commit('toast', toastOptions)
  },
  error(message: string, options?: any) {
    const toastOptions = {
      ...defaultOptions,
      color: 'error',
      icon: 'mdi-alert-circle-outline',
      message,
      ...options
    }

    store.commit('toast', toastOptions)
  },
  warning(message: string, options?: any) {
    const toastOptions = {
      ...defaultOptions,
      color: 'warn',
      icon: 'mdi-alert',
      message,
      ...options
    }

    store.commit('toast', toastOptions)
  }
}

export default defineNuxtPlugin(() => {
  return {
    provide: { toast }
  }
})
