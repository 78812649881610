<template>
  <v-row>
    <v-col class="pr-16" cols="12" md="6">
      <order-delivery-info :address="address" :order="po || order" />
    </v-col>

    <v-divider v-if="lgAndUp" class="my-3" vertical />

    <v-col v-if="supplier" cols="12" md="6" :class="{ 'pl-16': lgAndUp }">
      <div class="qtm-h3">
        <span class="text-mid-grey">
          Fulfilled by
        </span>
        {{ supplier.name }}
      </div>
      <div class="text-mid-grey">
        <qtm-address :address="supplier.address" :city="supplier.city" :province="supplier.province" />
      </div>
      <div v-if="contact">
        <div class="font-weight-bold mt-2">
          {{ contact.first_name }} {{ contact.last_name }}
        </div>
        <div>
          <qtm-phone-number :ext="contact.phone_extension" :phone="contact.phone_number" />
        </div>
        <div>
          <a
            class="text-decoration-none"
            :href="`mailto:${contact.email}`"
            v-text="contact.email"
          />
        </div>
      </div>
      <div v-if="supplierReference">
        Supplier reference: {{ supplierReference }}
      </div>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { useDisplay } from 'vuetify'
import type { Order, PurchaseOrder } from '@quotetome/materials-api'
import OrderDeliveryInfo from '@/components/orders/order-delivery-info.vue'

export interface Props {
  contact?: any
  order: Order
  po?: PurchaseOrder
  supplier?: any
  supplierReference?: string
}

const props = defineProps<Props>()

const { lgAndUp } = useDisplay()

const address = computed(() => {
  let fields = ['address', 'city', 'province', 'postal_code']
  let deliveryObj: any

  if (props.po) {
    fields = fields.map(field => `shipto_${field}`)
    deliveryObj = props.po
  }
  else if (props.order.delivery_location === 'jobsite') {
    if (props.order.location) {
      deliveryObj = props.order.jobsite?.locations.find(location => location.id === props.order.location)
    }
    else {
      deliveryObj = props.order.jobsite
    }
  }

  if (deliveryObj) {
    return fields.map(field => deliveryObj[field]).join(' ')
  }

  return ''
})
</script>
