<template>
  <component
    :is="Component"
    v-bind="attrs"
    class="qtm-data-table"
    :class="`bg-${backgroundColor}`"
    :headers="headers"
    :items-per-page="itemsPerPage"
    sort-asc-icon="mdi-menu-up"
    sort-desc-icon="mdi-menu-down"
    v-on="clickableRowOn"
  >
    <template v-slot:loading>
      <v-progress-circular color="secondary" indeterminate />
    </template>
    <template v-for="(_, name) in $slots" v-slot:[name]="slotData" :key="name">
      <slot v-bind="slotData" :key="name" :name="name" />
    </template>
    <template v-if="!showDefaultFooter" v-slot:bottom>
      <div />
    </template>
  </component>
</template>

<script setup lang="ts">
export interface Props {
  backgroundColor?: string
  clickableRow?: boolean
  headers?: any[]
  showDefaultFooter?: boolean
  itemsPerPage?: number | string
  serverSide?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  backgroundColor: 'white',
  clickableRow: false,
  headers: () => [],
  showDefaultFooter: false,
  itemsPerPage: 10000,
  serverSide: false,
})
const emit = defineEmits(['row-click'])

const Component = computed(() => (props.serverSide ? 'v-data-table-server' : 'v-data-table'))
const clickableRowAttrs = computed(() => {
  if (props.clickableRow) {
    return {
      expanded: [],
      expandOnClick: true,
      hover: true,
    }
  }

  return undefined
})
const clickableRowOn = computed(() => {
  if (props.clickableRow) {
    return {
      'update:expanded': (value: any) => emit('row-click', value[0]),
    }
  }
  return {}
})
const attrs = computed(() => ({
  ...useAttrs(),
  ...clickableRowAttrs.value
}))
</script>

<style lang="scss" scoped>
.qtm-data-table {
  overflow-wrap: anywhere;
  word-break: normal;
}
</style>

<style lang="scss">
.qtm-data-table th {
  color: rgb(var(--v-theme-mid-grey)) !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  white-space: nowrap !important;
}

.qtm-data-table td {
  font-size: 1rem !important;
  color: rgb(var(--v-theme-dark-grey)) !important;
}
</style>
