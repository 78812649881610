<template>
  <div>
    <h3 v-if="!noTitle" class="order-title headline">
      {{ order.reference_name }}
      <span class="subtitle-2 font-weight-light">({{ order.qtm_reference_number }})</span>
    </h3>

    <v-table class="order-info">
      <tbody>
        <tr>
          <th>Jobsite:</th>
          <td>{{ jobsiteDescription }}</td>
          <th>Date Needed:</th>
          <td>{{ order.delivery_date }}</td>
        </tr>
        <tr>
          <th>Date Submitted:</th>
          <td>{{ dateCreated }}</td>
          <th>Delivery Required:</th>
          <td>{{ order.delivery_required ? 'Yes' : 'No' }}</td>
        </tr>
        <tr>
          <th>Closes for Bids:</th>
          <td>{{ order.date_closes }}</td>
          <th>Order Type:</th>
          <td>{{ order.order_type }}</td>
        </tr>
      </tbody>
    </v-table>

    <div v-if="order.scope_of_work">
      <div class="subtitle-1 font-weight-bold mt-3">
        Requested Scope of Work
      </div>
      <div class="show-newlines" v-text="order.scope_of_work" />
    </div>

    <div v-if="order.hasPurchaseSkus" class="purchase-items">
      <div class="subtitle-1 font-weight-bold">
        Items for Purchase
      </div>
      <sku-table :order="order" />
    </div>

    <div v-if="order.hasRentalSkus" class="rental-items">
      <div class="subtitle-1 font-weight-bold">
        Items for Rental
      </div>
      <sku-table rental :order="order" />
    </div>

    <div v-if="order.attachments.length" class="attachments">
      <div class="subtitle-1 font-weight-bold">
        Attachments
      </div>
      <attachment-links-list :attachments="order.attachments" />
    </div>
  </div>
</template>

<script>
import { dateTime } from '~/models/filters'
import SkuTable from '@/components/skus/sku-table.vue'
import AttachmentLinksList from '@/components/attachment-links-list.vue'

export default {
  name: 'order-detail',
  components: { SkuTable, AttachmentLinksList },
  props: {
    order: {
      type: Object,
      required: true
    },
    noTitle: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    dateCreated() {
      return dateTime(this.order.date_created)
    },
    jobsiteDescription() {
      if (!this.order) {
        return ''
      }
      const jobsite = this.order.jobsite
      if (!this.order.jobsite) {
        return 'Not Specified'
      }

      if (jobsite.address && jobsite.city && jobsite.province) {
        if (jobsite.name) {
          return `${jobsite.name} at ${jobsite.address} in ${jobsite.city} , ${jobsite.province}`
        }
        return `Jobsite at ${jobsite.address} in ${jobsite.city} , ${jobsite.province}`
      }
      return jobsite
    }
  },
}
</script>

<style lang="scss" scoped>
.order-title {
  margin-bottom: 1rem;
}

.order-info {
  width: 100%;
}

.purchase-items, .rental-items, .attachments {
  margin-top: 1.5rem;
}

.sku-table {
  width: 100%;
}
</style>
