<template>
  <div>
    <div v-for="attachment in attachments" :key="attachment.id" class="d-flex text-break-word">
      <a
        :download="attachment.name"
        :href="attachment.url"
        target="_blank"
        :class="{'text-primary': hasToApplyColor(attachment.url)}"
      >
        {{ attachment.name }}
      </a>
      <slot name="attachment-append" :attachment="attachment" />
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  attachments: {
    id: string
    name: string
    url: string
  }[]
  colorPdf?: boolean
}>()

const hasToApplyColor = (url?: string) => {
  return props.colorPdf && url?.toLowerCase().split('.').pop() === 'pdf'
}
</script>
