<template>
  <div class="qtm-border wrapper">
    <v-icon class="paperclip" color="mid-light-grey">
      mdi-paperclip
    </v-icon>
    <v-hover v-slot="{ isHovering, props: hoverProps }" :disabled="!shouldDownload">
      <div v-bind="hoverProps" class="attachment-preview-download pa-1" :title="name" @click="emitClick">
        <v-img
          v-if="isImage"
          :alt="name"
          contain
          max-height="98%"
          max-width="98%"
          :src="src"
        />
        <div v-else>
          {{ name }}
          <v-icon class="file-icon" color="mid-light-grey">
            {{ icon }}
          </v-icon>
        </div>
        <v-overlay
          class="align-center justify-center"
          scrim="grey"
          contained
          opacity="0.8"
          :model-value="isHovering"
        >
          <a class="d-inline-block py-7 px-2 text-decoration-none" :href="src" target="_blank">
            <div class="text-center">
              <v-icon color="interactive">
                mdi-download
              </v-icon>
            </div>
            <div class="font-weight-bold text-interactive">
              Download
            </div>
          </a>
        </v-overlay>
      </div>
    </v-hover>
  </div>
</template>

<script setup lang="ts">
import useAttachments from '@/composables/attachments'

const props = defineProps<{
  downloadOnly?: boolean
  name: string
  src: string
}>()
const emit = defineEmits(['click'])

const iconsByExtension: { [key: string]: string } = {
  docx: 'mdi-file-word-box',
  pdf: 'mdi-file-pdf-box',
  xlsx: 'mdi-file-excel-box',
}
const icon = computed(() => iconsByExtension[props.name.split('.').pop() || ''] || 'mdi-file')

const { isImage, isPDF } = useAttachments(props.src)
const shouldDownload = computed(() => props.downloadOnly || (!isImage.value && !isPDF.value))

const emitClick = () => {
  if (!shouldDownload.value) {
    emit('click')
  }
}
</script>

<style lang="scss" scoped>
$height: 104px;
$width: 80px;

.attachment-preview-download {
  cursor: pointer;
  display: inline-block;
  font-size: 0.8rem;
  max-height: $height;
  max-width: $width;
  min-height: $height;
  min-width: $width;
  overflow-y: hidden;
}

.file-icon {
  position: absolute;
  bottom: 0;
  right: 0;
}

.paperclip {
  position: absolute;
  left: -10px;
  top: -10px;
  transform: rotate(225deg);
  z-index: 10;
}

.wrapper {
  display: inline-block;
  max-height: $height;
  max-width: $width;
  min-height: $height;
  min-width: $width;
  position: relative;
}
</style>
