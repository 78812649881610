<template>
  <order-form v-if="localOrder" ref="orderForm" :order="localOrder">
    <template v-slot:action-buttons>
      <qtm-btn :loading="loading" secondary @click="submitChanges()">
        Submit Changes
      </qtm-btn>
    </template>
  </order-form>
</template>

<script>
import cloneDeep from 'lodash.clonedeep'
import isEqual from 'lodash.isequal'
import OrderForm from '@/components/orders/order-form.vue'

export default {
  name: 'edit-order',
  components: { OrderForm },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      localOrder: undefined,
    }
  },
  mounted() {
    this.cloneOrder()
  },

  methods: {
    async submitChanges(externalCaller = false) {
      if (!this.$refs.orderForm.isValid()) {
        return
      }

      const orderData = this.localOrder.getSubmitData()

      if (!this.changes(orderData)) {
        if (!externalCaller) {
          this.$toast.warning('No changes to submit')
        }
        return
      }

      if (this.$store.getters['auth/isStaff'] && this.localOrder.owner) {
        orderData.owner = this.localOrder.owner.id
      }

      this.loading = true
      try {
        const updatedOrder = await this.$api.v1.rfqs.update(orderData, { strict: true })
        this.$store.commit('admin/updateOrder', updatedOrder)
        this.$toast.success('Order updated')
      }
      catch (error) {
        if (externalCaller) {
          throw error
        }
        this.$error.report(error)
      }
      this.loading = false
    },

    changes(submitData) {
      return !isEqual(this.order.getSubmitData(), submitData) || this.order.owner.id !== this.localOrder.owner.id
    },

    beforeTransition() {
      return this.submitChanges(true)
    },

    cloneOrder() {
      this.localOrder = cloneDeep(this.order)
    },

    refresh() {
      this.cloneOrder()
    },
  }
}
</script>
