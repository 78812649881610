<template>
  <v-row dense>
    <v-col cols="4">
      <qtm-input-label label="Invoice number" required>
        <qtm-text-field
          v-model="v$.invoice.invoice_number.$model"
          :error-messages="errors.invoice.invoice_number"
          hide-details="auto"
          @blur="checkForDuplicates"
        />
      </qtm-input-label>
    </v-col>
    <v-col cols="4">
      <qtm-input-label label="Issue date" required>
        <date-picker
          v-model="v$.invoice.date_issued.$model"
          clearable
          hide-details="auto"
          placeholder="Select date"
          :error-messages="errors.invoice.date_issued"
          close-on-click
        />
      </qtm-input-label>
    </v-col>
    <v-col cols="4">
      <qtm-input-label label="Due date">
        <date-picker
          v-model="invoice.date_due"
          clearable
          hide-details="auto"
          placeholder="Select date"
          close-on-click
        />
      </qtm-input-label>
    </v-col>
    <v-col cols="6">
      <order-reference-duplicates
        v-if="invoice.vendor"
        ref="duplicates"
        :order-id="order.id"
        :supplier-id="invoice.vendor.id"
      />
    </v-col>
  </v-row>
</template>

<script>
import { required } from '@vuelidate/validators'
import DatePicker from '@/components/date-picker.vue'
import OrderReferenceDuplicates from '@/components/orders/order-reference-duplicates.vue'
import useValidation from '@/composables/validation'

export default {
  name: 'invoice-information',
  components: { DatePicker, OrderReferenceDuplicates },
  props: {
    invoice: {
      type: Object,
      required: true
    },
    order: {
      type: Object,
      required: true
    },
  },
  setup() {
    const { errors, isValid, v$ } = useValidation()

    return { errors, isValid, v$ }
  },
  validations: {
    invoice: {
      invoice_number: { required },
      date_issued: { required },
    },
  },
  methods: {
    checkForDuplicates() {
      this.$refs.duplicates?.checkForDuplicates(this.invoice.invoice_number)
    },
  }
}
</script>
