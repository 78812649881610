<template>
  <qtm-icon-btn
    icon="mdi-brain"
    :loading="loading"
    title="Analyze"
    @click.prevent="analyze"
  />
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import parseItemAnalysis from '@/utils/parse-analysis'

export default {
  name: 'attachment-analyzer',
  props: {
    attachment: {
      type: Object,
      required: true
    },
    company: {
      type: Object,
      default: () => {}
    },
    items: {
      type: Array,
      default: () => []
    },
    supplierReference: {
      type: String,
      default: ''
    },
    units: {
      type: Array,
      required: true
    },
  },
  emits: ['analyzing', 'update:date-issued', 'update:supplier-reference', 'update:items'],
  data() {
    return {
      jobId: null,
      loading: false,
    }
  },
  computed: {
    parseTaxes() {
      return this.company?.country === 'US' && this.company.accounting_integration?.type === 'procore'
    },
    unit() {
      return this.units.find(unit => unit.is_default)?.code || 'Each'
    },
  },
  methods: {
    async analyze() {
      this.$emit('analyzing', this.attachment)

      this.loading = true
      try {
        const results = await this.$api.v1.attachments.analyze(this.attachment.id)

        if (results?.JobStatus === 'IN_PROGRESS') {
          this.$toast.warning('Analysis is still in progress. Please try again later.')
        }
        // If no JobStatus, it was not an async job
        else if (results && (!results.JobStatus || results.JobStatus === 'SUCCEEDED')) {
          this.parseResults(results)
        }
        else {
          this.$toast.error('Analysis failed')
        }
      }
      catch (error) {
        this.$error.report(error)
      }
      this.loading = false
    },
    parseResults(results) {
      const items = parseItemAnalysis(results, this.unit)

      results.ExpenseDocuments?.forEach(doc => {
        doc.SummaryFields?.forEach(field => {
          const quoteItem = {
            id: uuidv4(),
            boundingBox: {},
            confidence: {},
            cost_code: '',
            description: '',
            quantity: 1,
            rental_duration: null,
            rental_duration_unit: null,
            unit: this.unit,
            unit_price: '',
          }

          if (!field.ValueDetection?.Text || !field.Type.Text) {
            return
          }
          if (this.parseTaxes) {
            if ((field.Type?.Text === 'TAX')) {
              quoteItem.description = field.LabelDetection.Text
              quoteItem.confidence.description = field.LabelDetection?.Confidence?.toFixed(1)
              quoteItem.boundingBox.description = field.LabelDetection?.Geometry?.BoundingBox

              quoteItem.unit_price = field.ValueDetection.Text.replace(/[^0-9.]/g, '')
              quoteItem.confidence.price = field.ValueDetection?.Confidence?.toFixed(1)
              quoteItem.boundingBox.price = field.ValueDetection?.Geometry?.BoundingBox
            }

            if (quoteItem.description) {
              items.push(quoteItem)
            }
          }

          if (field.Type?.Text === 'INVOICE_RECEIPT_ID') {
            this.$emit('update:supplier-reference', field.ValueDetection.Text)
          }
          if (field.Type?.Text === 'INVOICE_RECEIPT_DATE') {
            const parsedDate = Date.parse(field.ValueDetection.Text)
            if (!Number.isNaN(parsedDate)) {
              const date = moment(new Date(parsedDate))
              this.$emit('update:date-issued', date)
            }
          }
        })
      })

      let max = Math.max(-1, ...this.items.map(i => i.sort_order))

      items.forEach(item => {
        max += 1
        item.sort_order = max
      })

      this.$emit('update:items', this.items.concat(items))
    },
  }
}
</script>
