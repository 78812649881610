// This list of Order States should match the OrderState instances in the backend database. Any changes there should be
// reflected here.
import { shallowRef } from 'vue'
import ReviewQuotesOrInvoice from '@/components/admin/actions/review-quotes-or-invoice.vue'
import EditPurchaseOrder from '@/components/admin/actions/edit-purchase-order.vue'
import EditOrder from '@/components/admin/actions/edit-order.vue'
import ViewPurchaseOrder from '@/components/admin/actions/view-purchase-order.vue'
import PrintablePOPreview from '@/components/purchase-orders/printable-po-preview.vue'
import RecipientSelection from '@/components/admin/recipient-selection.vue'
import ReviewVendors from '@/components/admin/actions/review-vendors.vue'
import EditOrderQuote from '@/components/admin/actions/edit-order-quote.vue'
import InviteVendorPreview from '@/components/admin/invite-vendor-preview.vue'
import AdminCancelOrderDialog from '@/components/admin/admin-cancel-order-dialog.vue'
/*
Structure of a State Action
---------------------------
{
  name: String -> Label that will appear on the action button
  event: String -> The event to trigger when this action is taken
  condition: func(order) -> If this function is present and returns a falsy value then the action will not be shown
  disable: func(order) -> If this function is present and returns a truthy value then the action will be
      present but disabled. The returned value is used as the action tooltip.
}
*/
const orderStatesExtension = {
  1: {
    dialog_component: (order) => (
      (order.isFromCallToVendor || order.is_invoice_approval) && order.quotes.length <= 1 ? EditOrderQuote : EditOrder
    ),
    actions: [
      {},
      {
        // This is conveniently being applied to all the cancel actions across all the states
        confirmation: (order) => ({
          title: 'Cancel This Order?',
          text: `Are you sure you want to cancel ${order.reference_name}?`,
          component: shallowRef(AdminCancelOrderDialog)
        })
      },
    ]
  },
  2: {
    dialog_component: (order) => (
      (order.isFromCallToVendor || order.is_invoice_approval) && order.quotes.length <= 1 ? EditOrderQuote : EditOrder
    ),
  },
  3: {
    dialog_component: shallowRef(ReviewVendors),
    actions: [
      {},
      {
        confirmation: () => ({
          title: 'Send *New* Vendor Invitation Emails?',
          text: 'Send invitation emails to vendors who have not yet been invited?',
          width: '51rem',
          component: shallowRef(InviteVendorPreview)
        })
      },
      {
        confirmation: () => ({
          title: 'Send *All* Vendor Invitation Emails?',
          text: 'Send invitation emails to *all* vendors, even if they have already been invited?',
          width: '51rem',
          component: shallowRef(InviteVendorPreview)
        })
      },
    ]
  },
  4: {
    dialog_component: shallowRef(ReviewQuotesOrInvoice),
    actions: [
      {
        confirmation: () => ({
          title: 'Notify customer?',
          component: shallowRef(RecipientSelection)
        }),
      },
    ]
  },
  6: {
    dialog_component: shallowRef(ReviewQuotesOrInvoice),
  },
  7: {
    dialog_component: shallowRef(EditPurchaseOrder),
    actions: [
      {
        confirmation: () => ({
          title: 'Send PO email?',
          width: '60rem',
          component: shallowRef(PrintablePOPreview)
        })
      },
    ]
  },
  8: {
    dialog_component: shallowRef(ViewPurchaseOrder),
  },
  9: {
    dialog_component: shallowRef(ViewPurchaseOrder),
  },
  11: {
    dialog_component: shallowRef(ReviewQuotesOrInvoice),
    actions: [
      {},
      {},
      {
        confirmation: () => ({
          title: 'Notify customer?',
          component: shallowRef(RecipientSelection)
        }),
      },
    ]
  },
  12: {
    dialog_component: shallowRef(ViewPurchaseOrder)
  },
  13: {
    dialog_component: shallowRef(ViewPurchaseOrder)
  },
  14: {
    dialog_component: shallowRef(ReviewQuotesOrInvoice)
  },
}

export default orderStatesExtension
