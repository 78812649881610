<template>
  <qtm-content-block v-bind="$attrs" collapsible title="Order Summary">
    <div v-if="scopeOfWork" class="mb-3">
      <div class="qtm-h4 text-secondary">
        Requested Scope of Work
      </div>
      <div class="show-newlines" v-text="scopeOfWork" />
    </div>
  </qtm-content-block>
</template>

<script>
export default {
  name: 'order-summary',
  components: {},
  props: {
    scopeOfWork: {
      type: String,
      default: null
    },
  },
}
</script>
