<template>
  <div class="pb-5 qtm-content-block" :class="{ 'qtm-border': !flat }" :style="style">
    <div
      class="d-flex justify-space-between align-start pr-2"
      :class="!expanded || (!title && !$slots.title) ? 'mb-0' : 'mb-4'"
    >
      <div class="d-flex align-center pt-3">
        <slot name="title" :padding="padding">
          <div v-if="title" class="qtm-h1 text-secondary text-uppercase" :class="`pl-${padding}`" v-text="title" />
        </slot>
        <slot name="title:append" />
        <qtm-icon-btn
          v-if="to"
          :data-test="dataTest"
          icon="mid-pencil"
          size="small"
          :to="to"
        />
      </div>
      <div class="d-flex align-center pt-2">
        <slot v-if="expanded" name="top-right" />
        <qtm-icon-btn
          v-if="collapsible"
          class="ml-2"
          density="compact"
          :icon="collapseIcon"
          size="x-large"
          tabindex="-1"
          @click="expanded = !expanded"
        />
      </div>
    </div>
    <v-expand-transition>
      <div v-show="expanded" :class="contentClass">
        <slot />
      </div>
    </v-expand-transition>
  </div>
</template>

<script setup lang="ts">
export interface Props {
  collapsed?: boolean
  collapsible?: boolean
  dataTest?: string
  divided?: boolean
  flat?: boolean
  padding?: number | string
  maxHeight?: number | string
  title?: string
  to?: object
}
const props = withDefaults(defineProps<Props>(), {
  collapsed: false,
  collapsible: false,
  dataTest: undefined,
  divided: false,
  flat: false,
  maxHeight: undefined,
  padding: 4,
  title: '',
  to: undefined
})
const expanded = ref(!props.collapsed)

const contentClass = computed(() => {
  const cls = props.divided ? 'divided' : ''

  return `${cls} px-${props.padding}`
})
const collapseIcon = computed(() => (expanded.value ? 'mdi-chevron-up' : 'mdi-chevron-down'))
const style = computed(() => (props.maxHeight ? `max-height: ${props.maxHeight}px; overflow-y: scroll;` : undefined))
</script>

<style lang="scss" scoped>
.qtm-content-block {
  background-color: white;
  overflow-wrap: anywhere;
  word-break: normal;
}

.divided > :not(:first-child)::before {
  background: rgb(var(--v-theme-light-grey));
  content: "";
  display: block;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  padding-top: 1px;
}
</style>
