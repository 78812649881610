<template>
  <div class="quotes-carousel">
    <v-window v-model="quoteIndex" class="py-3" show-arrows>
      <template v-slot:prev="{ props }">
        <qtm-icon-btn
          v-bind="props"
          icon="mdi-chevron-left"
          size="large"
        />
      </template>
      <template v-slot:next="{ props }">
        <qtm-icon-btn
          v-bind="props"
          icon="mdi-chevron-right"
          size="large"
        />
      </template>
      <v-window-item v-for="quote in quotes" :key="quote.id">
        <quote-detail-sheet class="mx-auto" :quote="quote" :order="order" />
      </v-window-item>
    </v-window>
    <v-item-group v-model="quoteIndex" class="mt-5 text-center" mandatory>
      <v-item v-for="quote in quotes" :key="quote.id" v-slot="{ isSelected, toggle}">
        <qtm-icon-btn
          color="secondary"
          :icon="isSelected ? 'mdi-circle' : 'mdi-circle-outline'"
          size="small"
          @click="toggle"
        />
      </v-item>
    </v-item-group>
  </div>
</template>

<script>
import QuoteDetailSheet from '@/components/quotes/quote-detail-sheet.vue'

export default {
  name: 'quotes-carousel',
  components: { QuoteDetailSheet },
  props: {
    quotes: {
      type: Array,
      default: () => []
    },
    order: {
      type: Object,
      required: true
    }
  },
  data() {
    const quoteId = this.$route.query.quote

    return {
      quoteIndex: quoteId ? this.quoteIndexById(Number(quoteId), this.order.quotes) : 0
    }
  },
  methods: {
    quoteIndexById(quoteId, quotes) {
      const index = quotes.findIndex(quote => quote.id === quoteId)

      return index > 0 ? index : 0
    },
    viewQuote(quote) {
      this.quoteIndex = this.quoteIndexById(quote.id, this.quotes)
    }
  }
}
</script>

<style lang="scss">
.v-window__prev,
.v-window__next {
  background: transparent;
}
</style>

<style lang="scss" scoped>
.quotes-carousel {
  min-height: 500px;
}
</style>
