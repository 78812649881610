<template>
  <div class="order-form">
    <order-info ref="order_info" :order="order" />
    <order-scope-of-work v-model="order.scope_of_work" />
    <order-delivery ref="order_delivery" :order="order" />
    <order-cart
      ref="order_cart"
      v-model:freight-cost-code="order.freight_cost_code"
      v-model:freight-tax="order.freight_tax"
      v-model:items="order.skus"
      :cost-codes="costCodes"
      :delivery-required="order.delivery_required"
      :force-cost-code-select="forceCostCodeSelect"
      :taxes="taxes"
      :units="units"
    />
    <order-attachments
      ref="order_attachments"
      v-model="order.attachments"
      content-type="rfqs.order"
      :object-id="order.id"
    />
    <order-comment v-model="order.comment" :placeholder="commentPlaceholder" />

    <v-divider class="my-5" />

    <div class="text-center">
      <slot name="action-buttons" />
    </div>
  </div>
</template>

<script>
import { watch } from 'vue'
import OrderAttachments from '@/components/orders/order-attachments.vue'
import OrderCart from '@/components/orders/order-cart.vue'
import OrderComment from '@/components/orders/order-comment.vue'
import OrderDelivery from '@/components/orders/order-delivery.vue'
import OrderInfo from '@/components/orders/order-info.vue'
import OrderScopeOfWork from '@/components/orders/order-scope-of-work.vue'
import useCostCodes from '@/composables/cost-codes'
import useTaxes from '@/composables/taxes'
import useUnits from '@/composables/units'

export default {
  name: 'order-form',
  components: {
    OrderAttachments,
    OrderCart,
    OrderComment,
    OrderDelivery,
    OrderInfo,
    OrderScopeOfWork,
  },
  props: {
    order: {
      type: Object,
      required: true,
    }
  },
  setup(props) {
    const { costCodes, fetchCostCodes } = useCostCodes(props.order.jobsite?.id)
    const { fetchTaxes, taxes } = useTaxes(props.order.jobsite?.company)
    const { fetchUnits, units } = useUnits(props.order.jobsite?.company)

    watch(() => props.order.jobsite, (newJobsite, oldJobsite) => {
      if (newJobsite) {
        if (newJobsite.company !== oldJobsite?.company) {
          fetchTaxes(newJobsite.company)
          fetchUnits(newJobsite.company)
        }

        fetchCostCodes(newJobsite.id)
      }
      else {
        costCodes.value = []
        taxes.value = []
        units.value = []
      }
    })

    return { costCodes, taxes, units }
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    commentPlaceholder() {
      return 'Additional comments relating to this request that you would like to share with relevant suppliers'
    },
    forceCostCodeSelect() {
      return !!this.order.jobsite?.accounting_id
    },
  },
  methods: {
    isValid() {
      // list components in vertical order, from top to bottom
      // so the user scrolls up to the highest component with errors
      const components = [
        this.$refs.order_info,
        this.$refs.order_cart,
        this.$refs.order_delivery,
        this.$refs.order_attachments,
      ]
      const componentValidations = []
      let hasScrolled = false

      components.forEach(c => {
        const isValid = c.isValid()

        componentValidations.push(isValid)
        if (!hasScrolled && !isValid) {
          c.$el.scrollIntoView({ behavior: 'smooth' })
          hasScrolled = true
        }
      })

      return componentValidations.every(x => x)
    },
  }
}
</script>

<style lang="scss" scoped>
.order-form > * {
  margin-bottom: 1rem;
}
</style>
