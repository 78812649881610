<template>
  <tr class="cart-items-row">
    <td class="number-cell px-2 py-4" :class="{ 'text-no-wrap': numberWrap }">
      <div v-if="!noDragAndDrop" class="handle">
        <v-icon size="sm">
          mdi-dots-vertical
        </v-icon>
        <v-icon size="sm" class="ml-n3">
          mdi-dots-vertical
        </v-icon>
      </div>

      <span class="pt-1">{{ item[itemNumberField] }}</span>
    </td>
    <editable-cell
      ref="descriptionCell"
      v-model.trim="v$.item.description.$model"
      auto-grow
      :autofocus="!item.description"
      class="description-cell"
      component="qtm-textarea"
      data-test="description-input"
      density="compact"
      :disabled="item.fromBom"
      :error="!!errors.item.description"
      hide-details
      :maxlength="skuNameLength"
      no-resize
      placeholder="Item Description"
      rows="1"
      @focus="drawBoundingBox(item, 'description')"
      @blur="removeBoundingBox(item, 'description')"
    >
      <template v-if="item.fromBom" v-slot:prepend>
        <v-icon class="mr-n2" color="mid-grey" icon="mdi-link" />
      </template>
    </editable-cell>

    <editable-cell
      v-model="v$.item.quantity.$model"
      class="quantity-cell"
      component="qtm-number-field"
      data-test="quantity-input"
      density="compact"
      :error="!!errors.item.quantity"
      hide-details
      :maxlength="quantityLength"
      @focus="drawBoundingBox(item, 'quantity')"
      @blur="removeBoundingBox(item, 'quantity')"
    />

    <template v-if="rental">
      <editable-cell
        v-model.trim="v$.item.rental_duration.$model"
        class="rental-duration"
        component="qtm-number-field"
        data-test="rental-duration-input"
        density="compact"
        :error="!!errors.item.rental_duration"
        hide-details
        :maxlength="durationLength"
      />

      <editable-cell
        v-model="item.rental_duration_unit"
        class="rental-unit"
        component="qtm-select"
        density="compact"
        hide-details
        item-value="value"
        :items="rentalDurationChoices"
      />
    </template>

    <editable-cell
      v-if="!rental || includeUnitsColumnInRentals"
      v-model.trim="v$.item.unit.$model"
      class="unit-cell"
      :component="useTextInputForUnits ? 'qtm-text-field' : 'qtm-select'"
      density="compact"
      :error="!!errors.item.unit"
      hide-details
      item-title="description"
      item-value="code"
      :items="units"
      :maxlength="unitLength"
    />

    <template v-if="withPrices">
      <template v-if="rental">
        <editable-cell
          v-for="(unit, i) in rentalUnits"
          :key="i"
          v-model.trim="v$.item[`${unit}_rate`].$model"
          class="rate-cell"
          component="qtm-number-field"
          :data-test="`${unit}-rate-input`"
          density="compact"
          :error="!!errors.item[`${unit}_rate`]"
          hide-details
          :maxlength="priceLength"
        />
        <td class="bg-background rate-cell px-2 py-4">
          <item-price-estimate :item="item" />
          <v-tooltip activator="parent" location="top" offset="4px">
            Total is calculated based on the cheapest rate
          </v-tooltip>
        </td>
      </template>
      <template v-else>
        <editable-cell
          v-model.trim="v$.item.unit_price.$model"
          class="unit-rate-cell"
          component="qtm-number-field"
          data-test="unit-price-input"
          density="compact"
          :error="!!errors.item.unit_price"
          hide-details
          :maxlength="priceLength"
          @focus="drawBoundingBox(item, 'price')"
          @blur="removeBoundingBox(item, 'price')"
        />
        <editable-cell
          v-model="v$.item.unit_price.$model"
          class="rate-cell"
          :component="TotalPriceHelper"
          :quantity="item.quantity"
          @focus="drawBoundingBox(item, 'price')"
          @blur="removeBoundingBox(item, 'price')"
        />
      </template>
    </template>

    <editable-cell
      v-if="withCostCode"
      v-model.trim="v$.item.cost_code.$model"
      class="cost-code-cell"
      :component="CostCodeSelect"
      :cost-codes="costCodes"
      density="compact"
      :error="!!errors.item.cost_code"
      :force-select="forceCostCodeSelect"
      @blur="$emit('cost-code-changed', item.cost_code)"
    >
      <template v-slot:selection="{ item: costCodeItem }">
        <div v-if="costCodeItem" v-text="costCodeItem.value" />
      </template>
    </editable-cell>

    <editable-cell
      v-if="showTaxes"
      v-model="item.tax"
      class="tax-cell"
      :component="TaxSelect"
      density="compact"
      hide-details
      :taxes="taxes"
      @update:model-value="$emit('tax-changed', $event)"
    >
      <template v-slot:selection="{ item: taxItem }">
        <div v-if="taxItem" v-text="taxItem.raw.label" />
      </template>
    </editable-cell>

    <editable-cell
      v-model.trim="item.comment"
      auto-grow
      class="comment-cell"
      component="qtm-textarea"
      density="compact"
      hide-details
      :maxlength="skuNameLength"
      placeholder="Comment"
      rows="1"
    />

    <td class="px-2 py-4 text-no-wrap">
      <delete-btn tabindex="-1" @click="$emit('remove', item)" />
      <v-tooltip location="top">
        <template v-slot:activator="{ props: tooltipProps }">
          <qtm-icon-btn
            v-bind="tooltipProps"
            :disabled="!!item.fromBom"
            :icon="`mdi-format-vertical-align-${ rental ? 'top' : 'bottom' }`"
            tabindex="-1"
            @click="transform"
          />
        </template>
        Move to {{ rental ? 'purchase': 'rental' }} items
      </v-tooltip>
    </td>
  </tr>
</template>

<script>
import {
  decimal, integer, minValue, required, requiredIf
} from '@vuelidate/validators'
import {
  DURATION_MAX_LENGTH,
  PRICE_MAX_LENGTH,
  QUANTITY_MAX_LENGTH,
  SKU_NAME_LENGTH,
  SKU_UNIT_LENGTH,
  UNIT_PRICE_DECIMAL_PLACES,
} from '@/constants'
import { decimalPlaces } from '@/validators'
import CostCodeSelect from '@/components/cost-codes/cost-code-select.vue'
import DeleteBtn from '@/components/delete-btn.vue'
import EditableCell from '@/components/editable-cell.vue'
import ItemPriceEstimate from '@/components/items/item-price-estimate.vue'
import TaxSelect from '@/components/orders/tax-select.vue'
import TotalPriceHelper from '@/components/skus/total-price-helper.vue'
import useValidation from '@/composables/validation'

export default {
  name: 'cart-items-row',
  components: {
    DeleteBtn,
    EditableCell,
    ItemPriceEstimate,
  },
  validations() {
    return {
      item: {
        cost_code: {
          required: requiredIf(function () {
            return this.requireCostCodes
          }),
          validChoice: (costCode) => {
            return !costCode || !this.forceCostCodeSelect || this.costCodes.some((code) => code.cost_code === costCode)
          },
        },
        day_rate: {
          decimalPlaces: decimalPlaces(2),
          minValue: minValue(0),
          required: requiredIf(function () {
            return this.rental && this.withPrices && !this.item.week_rate && !this.item.month_rate
          }),
        },
        description: {
          required,
        },
        month_rate: {
          decimalPlaces: decimalPlaces(2),
          minValue: minValue(0),
          required: requiredIf(function () {
            return this.rental && this.withPrices && !this.item.day_rate && !this.item.week_rate
          }),
        },
        quantity: {
          decimal,
          decimalPlaces: decimalPlaces(2),
          required,
        },
        rental_duration: {
          integer,
          minValue: minValue(0),
          required: requiredIf(function () {
            return this.rental
          }),
        },
        unit: {
          required: requiredIf(function () {
            return !this.rental
          }),
          validChoice: (item) => {
            return this.units.length === 0 || this.units.some(unit => unit.code === item)
          },
        },
        unit_price: {
          decimal,
          decimalPlaces: decimalPlaces(UNIT_PRICE_DECIMAL_PLACES),
          minValue: minValue(0),
          required: requiredIf(function () {
            return !this.rental && this.withPrices
          }),
        },
        week_rate: {
          decimalPlaces: decimalPlaces(2),
          minValue: minValue(0),
          required: requiredIf(function () {
            return this.rental && this.withPrices && !this.item.day_rate && !this.item.month_rate
          }),
        },
      },
    }
  },
  props: {
    costCodes: {
      type: Array,
      default: () => []
    },
    durationLength: {
      type: Number,
      default: DURATION_MAX_LENGTH,
    },
    forceCostCodeSelect: {
      type: Boolean,
      default: false
    },
    includeUnitsColumnInRentals: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      required: true
    },
    itemNumberField: {
      type: String,
      default: 'index'
    },
    noDragAndDrop: {
      type: Boolean,
      default: false
    },
    priceLength: {
      type: Number,
      default: PRICE_MAX_LENGTH,
    },
    quantityLength: {
      type: Number,
      default: QUANTITY_MAX_LENGTH,
    },
    rental: {
      type: Boolean,
      default: false
    },
    requireCostCodes: {
      type: Boolean,
      default: false
    },
    skuNameLength: {
      type: Number,
      default: SKU_NAME_LENGTH,
    },
    taxes: {
      type: Array,
      default: () => []
    },
    units: {
      type: Array,
      default: () => []
    },
    validateUnits: {
      type: Boolean,
      default: false
    },
    withCostCode: {
      type: Boolean,
      default: false
    },
    withPrices: {
      type: Boolean,
      default: false
    },
    unitLength: {
      type: Number,
      default: SKU_UNIT_LENGTH,
    },
    unitPriceDecimalPlaces: {
      type: Number,
      default: UNIT_PRICE_DECIMAL_PLACES,
    },
  },
  emits: ['cost-code-changed', 'remove', 'tax-changed'],
  setup() {
    const { errors, isValid: checkIsValid, v$ } = useValidation()

    const isValid = () => {
      const valid = checkIsValid()

      if (!valid) {
        document.querySelector('.editable-cell.error')?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        })
      }

      return valid
    }

    return {
      CostCodeSelect,
      errors,
      isValid,
      TaxSelect,
      TotalPriceHelper,
      v$,
    }
  },
  computed: {
    numberWrap() {
      return Number(this.item[this.itemNumberField] ?? 0) < 100
    },
    rentalDurationChoices() {
      return [
        { text: 'Days', value: 'days' },
        { text: 'Weeks', value: 'weeks' },
        { text: '28-Day Months', value: 'months' },
      ]
    },
    rentalUnits() {
      return ['day', 'week', 'month']
    },
    showUnitInRentals() {
      if (!this.rental) { return false }

      if (!this.item.unit) { return true }

      if (!this.useTextInputForUnits) {
        const currentUnitExistsInUnitsList = this.units.find(u => u.code === this.item.unit)
        if (!currentUnitExistsInUnitsList) { return true }
      }

      return false
    },
    showTaxes() {
      return !!this.taxes.length
    },
    useTextInputForUnits() {
      return !this.units || !this.units.length
    },
  },
  watch: {
    units(newUnitsArray) {
      if (!newUnitsArray || !newUnitsArray.length || !this.item.unit) { return }

      // Convert description to code if applicable
      const unitByCode = newUnitsArray.find(u => u.code === this.item.unit)
      if (!unitByCode) {
        const unitByDescription = newUnitsArray.find(u => u.description === this.item.unit)
        if (unitByDescription) {
          this.item.unit = unitByDescription.code
        }
      }
    }
  },
  methods: {
    drawBoundingBox(item, field) {
      this.executeBoundingBoxOperation(item, field, 'addAttachmentBoundingBox')
    },
    focusDescription() {
      this.$refs.descriptionCell.$el.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'end',
      })
    },
    removeBoundingBox(item, field) {
      this.executeBoundingBoxOperation(item, field, 'removeAttachmentBoundingBox')
    },
    executeBoundingBoxOperation(item, field, operation) {
      if (item && item.boundingBox && item.confidence) {
        const position = item.boundingBox[field]
        const confidence = item.confidence[field]
        if (position && confidence) {
          this.$store.commit(`admin/${operation}`, { position, confidence })
        }
      }
    },
    transform() {
      let unitPrice = null

      if (this.rental) {
        unitPrice = this.item.unit_price || this.item.month_rate
      }
      else if (this.item.month_rate) {
        unitPrice = this.item.month_rate
      }
      else if (this.item.unit_price) {
        unitPrice = Number.parseFloat(this.item.unit_price).toFixed(2)
      }

      if (this.rental) {
        this.item.rental_duration_unit = null
        this.item.unit_price = unitPrice
        this.item.day_rate = null
        this.item.week_rate = null
        this.item.month_rate = null
        this.item.rental_duration = null
      }
      else {
        this.item.rental_duration_unit = 'days'
        this.item.month_rate = unitPrice
        this.item.rental_duration = this.item.rental_duration ? this.item.rental_duration : 1
        this.item.unit_price = null
        this.item.rental_price = { price: unitPrice }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
td {
  padding: 0.25rem;
}

tr.cart-items-row > td {
  background-color: white;
}

.number-cell {
  min-width: 50px;
  max-width: 50px;
}

.description-cell {
  min-width: 290px;
  max-width: 290px;
}

.quantity-cell {
  min-width: 100px;
  max-width: 100px;
}

.rate-cell {
  min-width: 100px;
  max-width: 100px;
}

.unit-rate-cell {
  min-width: 120px;
  max-width: 120px;
}

.rental-duration {
  min-width: 55px;
  max-width: 55px;
}

.rental-unit {
  min-width: 120px;
  max-width: 120px;
}

.cost-code-cell {
  min-width: 184px;
  max-width: 184px;
}

.tax-cell {
  min-width: 140px;
  max-width: 140px;
}

.comment-cell {
  min-width: 259px;
  max-width: 259px;
}

.unit-cell {
  min-width: 127px;
  max-width: 127px;
}

.handle {
  color: rgb(var(--v-theme-grey));
  cursor: grab;
  display: inline-block;
  visibility: hidden;
}

.handle i::before {
  margin-top: -3px;
}

.handle:active {
  cursor: grabbing;
  color: rgb(var(--v-theme-interactive));
}

tr:hover .handle {
  visibility: visible;
}

tr.cart-items-row.ghost > td {
  background-color: rgb(var(--v-theme-light-grey)) !important;
  opacity: 0.5;
}

.no-handle .handle {
  visibility: hidden !important;
}
</style>
