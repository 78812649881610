<template>
  <v-container fluid>
    <div class="font-weight-bold">
      Delivery Information
    </div>

    <div class="pl-5">
      <v-row v-if="order.delivery_required || order.customer_pickup">
        <v-col cols="3">
          {{ deliveryOrPickup }} has been requested on:
        </v-col>
        <v-col>
          {{ orderDeliveryDate }}
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col>
          Delivery Not Required
        </v-col>
      </v-row>

      <v-divider />

      <v-row align="end">
        <v-col cols="3">
          {{ deliveryOrPickup }} included:
        </v-col>
        <v-col cols="2">
          <v-switch v-model="quote.delivery_available" color="interactive" dense hide-details inset />
        </v-col>
        <template v-if="quote.delivery_available">
          <v-col cols="3">
            Make delivery free?
          </v-col>
          <v-col cols="2">
            <v-switch v-model="freeDelivery" color="interactive" dense hide-details inset />
          </v-col>
        </template>
      </v-row>

      <template v-if="quote.delivery_available">
        <v-divider />
        <v-row align="end">
          <v-col cols="3">
            {{ deliveryOrPickup }} available at this time:
          </v-col>
          <v-col cols="2">
            <v-switch
              v-model="quote.deliver_in_time"
              color="interactive"
              density="compact"
              :disabled="!order.delivery_date"
              hide-details
              inset
            />
          </v-col>
          <template v-if="!quote.deliver_in_time">
            <v-col cols="3">
              When is the first available date?
            </v-col>
            <v-col>
              <date-picker v-model="v$.deliveryDate.$model" density="compact" hide-details />
            </v-col>
          </template>
        </v-row>
      </template>
    </div>
  </v-container>
</template>

<script>
import { requiredIf } from '@vuelidate/validators'
import { objectDeliveryDate } from '~/models/filters'
import DatePicker from '@/components/date-picker.vue'
import useValidation from '@/composables/validation'

export default {
  name: 'quote-delivery',
  components: { DatePicker },
  props: {
    quote: {
      type: Object,
      required: true
    },
    order: {
      type: Object,
      required: true
    }
  },
  setup() {
    const { errors, isValid, v$ } = useValidation()

    return { errors, isValid, v$ }
  },
  validations: {
    deliveryDate: {
      required: requiredIf(function () {
        return this.quote.delivery_available && !this.quote.deliver_in_time
      })
    }
  },
  data() {
    return {
      freeDelivery: false,
    }
  },
  computed: {
    deliveryDate: {
      get() {
        return this.quote.delivery_date
      },
      set(value) {
        this.quote.delivery_date = value
      }
    },
    deliveryOrPickup() {
      return this.order.customer_pickup ? 'Pick up' : 'Delivery'
    },
    orderDeliveryDate() {
      return objectDeliveryDate(this.order.delivery_date)
    },
  },
  watch: {
    'quote.delivery_available': {
      handler() {
        if (this.quote.delivery_available) {
          this.quote.deliver_in_time = true
        }
        else {
          this.quote.deliver_in_time = false
          this.quote.delivery_date = null
          this.quote.delivery_charge = null
          this.quote.pickup_charge = null
        }
      }
    },

    'quote.deliver_in_time': {
      handler() {
        if (this.quote.deliver_in_time) {
          this.quote.delivery_date = null
        }
      }
    },

    'quote.delivery_charge': {
      handler() {
        if (this.quote.delivery_charge) {
          this.freeDelivery = false
        }
      }
    },

    'quote.pickup_charge': {
      handler() {
        if (this.quote.pickup_charge) {
          this.freeDelivery = false
        }
      }
    },

    freeDelivery() {
      if (this.freeDelivery) {
        this.quote.delivery_charge = null
        this.quote.pickup_charge = null
      }
    }
  }
}
</script>
