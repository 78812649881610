import { createStore } from 'vuex'

import accounting from './accounting'
// eslint-disable-next-line import/no-cycle
import admin from './admin'
import auth from './auth'
import orders from './orders'
import state from './state'
import mutations from './mutations'

export default createStore({
  modules: {
    accounting,
    admin,
    auth,
    orders,
  },
  state,
  mutations,

  // enable strict mode (adds overhead!)
  // for dev mode only
  // strict: process.env.DEV,
  strict: false,
})
