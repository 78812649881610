<template>
  <qtm-content-block collapsible title="Order Info">
    <v-row no-gutters>
      <v-col v-bind="cols">
        <qtm-input-label v-if="isStaff" label="Order Owner" required>
          <qtm-autocomplete
            v-model="v$.order.owner.$model"
            class="mb-4"
            clearable
            :error-messages="ownerErrors"
            hide-details
            :item-title="userText"
            :items="users"
            :loading="loading"
            placeholder="Select an owner for this order"
            return-object
          />
        </qtm-input-label>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col v-bind="cols">
        <qtm-input-label label="Order Name" required>
          <qtm-text-field
            v-model="v$.order.reference_name.$model"
            class="mb-4"
            data-test="reference-name-input"
            :error-messages="errors.order.reference_name"
            hide-details="auto"
            maxlength="48"
            placeholder="Reference name for this order"
          />
        </qtm-input-label>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col v-bind="cols">
        <qtm-input-label label="Project" :required="jobsiteRequired">
          <jobsite-select
            v-model="v$.order.jobsite.$model"
            :disabled="order.jobsite && lockJobsite"
            :error-messages="jobsiteError"
            :user="projectUser"
          />
        </qtm-input-label>
      </v-col>
    </v-row>
  </qtm-content-block>
</template>

<script>
import { required, requiredIf, maxLength } from '@vuelidate/validators'
import { mapGetters, mapState } from 'vuex'
import JobsiteSelect from '@/components/jobsites/jobsite-select.vue'
import useValidation from '@/composables/validation'

export default {
  name: 'order-info',
  components: { JobsiteSelect },
  props: {
    fullWidth: {
      type: Boolean,
      default: false,
    },
    jobsiteRequired: {
      type: Boolean,
      default: true,
    },
    lockJobsite: {
      type: Boolean,
      default: false,
    },
    order: {
      type: Object,
      required: true,
    },
    readonlyOwner: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { errors, isValid, v$ } = useValidation()

    return { errors, isValid, v$ }
  },
  validations: {
    order: {
      jobsite: {
        required: requiredIf(function () {
          return this.jobsiteRequired
        })
      },
      owner: {
        required: requiredIf(function () {
          return this.$store.getters['auth/isStaff']
        })
      },
      reference_name: {
        required,
        maxLength: maxLength(48),
      },
    }
  },
  data() {
    return {
      users: [],
      loading: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['isStaff']),
    ...mapState('auth', ['user']),
    cols() {
      if (this.fullWidth) {
        return { cols: 12 }
      }

      return { cols: 12, sm: 8, lg: 6 }
    },
    jobsiteError() {
      return typeof this.errors.order.jobsite === 'string' ? this.errors.order.jobsite : undefined
    },
    ownerErrors() {
      if (this.v$.order.owner.$dirty) {
        if (!this.v$.order.owner.required) {
          return 'Owner is required'
        }
      }
      return undefined
    },
    projectUser() {
      return this.isStaff ? this.order.owner : this.user
    },
  },
  mounted() {
    this.fetchUsers()
  },
  methods: {
    async fetchUsers() {
      if (!this.isStaff || this.readonlyOwner) {
        return
      }

      this.loading = true
      try {
        this.users = await this.$api.v1.users.list()
        this.users.sort(
          (a, b) => {
            if (a.company && b.company) {
              return a.company.name.localeCompare(b.company.name)
              || a.first_name.localeCompare(b.first_name)
              || a.last_name.localeCompare(b.last_name)
            }

            return a.first_name.localeCompare(b.first_name)
              || a.last_name.localeCompare(b.last_name)
          }
        )
      }
      catch (error) {
        this.$error.report(error)
      }
      this.loading = false
    },
    userText(user) {
      if (user.company) {
        return `${user.company.name} - ${user.first_name} ${user.last_name}`
      }
      return `${user.first_name} ${user.last_name}`
    },
  }
}
</script>
