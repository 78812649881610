export default () => ({
  activeEmail: undefined,
  activeOrder: undefined,
  attachmentBoundingBoxes: [],
  bouncedEmails: [],
  emailsFetched: false,
  fetchingOrders: false,
  globalEmailViewer: {
    show: false,
    email: null
  },
  qmails: { data: [], count: 0 },
  qmailsQuery: { offset: 0, emailType: 'inbound' },
  orderFetchCount: 0,
  orders: [],
  ordersFetched: false,
  unassociatedEmails: [],
  unreadEmails: [],
  unreadGeneralInboxEmails: [],
})
