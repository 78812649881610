<template>
  <div>
    <div
      v-if="iconOnly"
      class="file-dropper icon-only"
      :class="innerClass"
      @dragleave="dragLeave"
      @dragover="dragOver"
      @drop="drop"
    >
      <qtm-icon-btn v-if="!expanded" color="interactive" icon="mdi-paperclip" @click="selectFile" />
      <div v-else class="d-flex justify-center align-center w-100 h-100 qtm-body text-interactive">
        Drop your files here to upload them.
      </div>
    </div>
    <div v-else :class="`${!noBorder ? 'file-dropper-border' : ''}`">
      <div
        class="align-center d-flex file-dropper justify-center pa-2"
        :class="innerClass"
        @dragleave="dragLeave"
        @dragover="dragOver"
        @drop="drop"
      >
        <div class="text-mid-grey text-center">
          <transition mode="out-in" name="fade">
            <div v-if="loading">
              <v-progress-circular color="secondary" :indeterminate="!progress" :model-value="progress" />
              <div>
                <slot name="progress.append" />
              </div>
            </div>
            <div v-else>
              <slot name="label" :select-file="selectFile">
                <label class="file-dropper-text" @click="selectFile">
                  <span class="font-weight-bold">
                    <span class="text-interactive">{{ fileInputLinkText }}</span>
                    {{ fileDropText }}
                  </span>
                  <span class="file-type-details">{{ fileTypeDetailText }}</span>
                </label>
              </slot>
              <div>
                <slot />
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <input
      v-show="false"
      ref="fileInput"
      :multiple="multiple"
      :accept="accept"
      type="file"
      @change="fileSelected"
    >
    <div v-if="!iconOnly && errorMessages" class="text-error" v-text="errorMessages" />
  </div>
</template>

<script>
export default {
  name: 'file-dropper',
  props: {
    accept: {
      type: String,
      default: '*'
    },
    color: {
      type: String,
      default: 'transparent'
    },
    errorMessages: {
      type: String,
      default: undefined
    },
    noBorder: {
      type: Boolean,
      default: false
    },
    noMinHeight: {
      type: Boolean,
      default: false
    },
    iconOnly: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    progress: {
      type: Number,
      default: 0
    },
    modelValue: {
      type: [File, Array],
      default: undefined
    },
    fileDropText: {
      type: String,
      default: 'or drag and drop'
    },
    fileInputLinkText: {
      type: String,
      default: 'Upload a file'
    },
    fileTypeDetailText: {
      type: String,
      default: undefined
    },
    multiple: {
      type: Boolean,
      default: false
    },
    expanded: {
      type: Boolean,
      default: false
    }
  },
  emits: ['change', 'update:model-value'],
  data() {
    return {
      highlight: false,
    }
  },
  computed: {
    innerClass() {
      const innerClasses = []
      if (this.expanded) {
        innerClasses.push('expanded')
        return innerClasses
      }

      if (this.noMinHeight) {
        innerClasses.push('no-min-height')
      }
      if (!this.highlight) {
        innerClasses.push(`bg-${this.color}`)
      }
      return innerClasses
    },
  },
  methods: {
    dragLeave() {
      this.highlight = false
    },
    dragOver(event) {
      event.preventDefault()
      this.highlight = true
    },
    drop(event) {
      event.preventDefault()
      const files = event.dataTransfer.files
      const objectToEmit = this.multiple ? Array.from(files) : files[0]
      this.$emit('update:model-value', objectToEmit)
      this.$emit('change', objectToEmit)
      this.highlight = false
    },
    fileSelected() {
      const files = this.$refs.fileInput.files
      const objectToEmit = this.multiple ? Array.from(files) : files[0]
      this.$emit('update:model-value', objectToEmit)
      this.$emit('change', objectToEmit)
      this.$refs.fileInput.value = null
    },
    selectFile() {
      this.$refs.fileInput.click()
    }
  }
}
</script>

<style lang="scss" scoped>
.file-dropper {
  min-height: 85px;
  transition: background 0.25s;
  text-align: center;
}
.file-dropper.no-min-height {
  min-height: 0 !important;
}
.file-dropper.icon-only {
  min-height: 32px;
  text-align: initial
}
.file-dropper.expanded {
  background-color: rgba(255, 255, 255, 0.85);;
  border: 3px dashed rgb(var(--v-theme-secondary));
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}

.file-dropper-border {
  border: 2px dashed rgb(var(--v-theme-light-grey));
}

.file-dropper-text {
  cursor: pointer;
}

.file-type-details {
  display: block
}
</style>
